import { type StatusChangeResult } from '../../dtos';
import { generateFailureReasonList, type SnackbarGenerator } from './utils';
import { Box } from '@mui/material';

export const generateVeranstaltungListingSuccessSnackbar: SnackbarGenerator<StatusChangeResult[]> = (veranstaltungen) => ({
  snackbarNode: (
    <Box>
      <p>Status erfolgreich bei folgenden Veranstaltungen geändert:</p>
      <ul>
        {veranstaltungen.map((veranstaltung) => (
          <li key={veranstaltung.akaVeranstaltungId}>
            {veranstaltung.akaVeranstaltungId}
            <ul>
              {veranstaltung.successMessages.map((msg) => (
                <li key={`${veranstaltung.akaVeranstaltungId}-${msg}`}>{msg}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Box>
  ),
  options: { variant: 'success' },
});

export const generateVeranstaltungListingErrorSnackbar: SnackbarGenerator<StatusChangeResult[]> = (veranstaltungen) => ({
  snackbarNode: (
    <Box>
      <p>Die Status konnten für folgende Veranstaltungen nicht geändert werden: </p>
      <ul>
        {veranstaltungen.map((veranstaltung) => (
          <li key={veranstaltung.akaVeranstaltungId}>
            {veranstaltung.akaVeranstaltungId}
            {generateFailureReasonList(veranstaltung)}
          </li>
        ))}
      </ul>
    </Box>
  ),
  options: { variant: 'error' },
});

export { backendErrorMessage as veranstaltungListingBackendErrorMessage } from './utils';
