import { type GetPlanungsinformationOutput } from '../../../../dtos';
import { content } from '../../../../utils';
import { Link } from 'react-router';

type MusskopplungProps = {
  readonly musskopplungReihenfolgeAkaProduktIds: GetPlanungsinformationOutput['musskopplungReihenfolgeAkaProduktIds'];
};

export const Musskopplung: React.FC<MusskopplungProps> = ({ musskopplungReihenfolgeAkaProduktIds }: MusskopplungProps) => {
  if (musskopplungReihenfolgeAkaProduktIds.length === 0) {
    return content.noInfoAvailable;
  }

  return musskopplungReihenfolgeAkaProduktIds.map((akaProduktId) => (
    <div key={akaProduktId}>
      <Link to={`/thema/${akaProduktId}`}>{akaProduktId}</Link>
      <br />
    </div>
  ));
};
