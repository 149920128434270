import { cservWorkflowStatus, type GetPlanungsinformationOutput } from '../../../dtos';
import { formatDateRange } from '../../../utils';
import { content } from './TerminPlanungsstatusCard.content';

export const printPlanungszeitraum = (planungsinformation: GetPlanungsinformationOutput): string => {
  const { planungsbeginn, planungsende } = planungsinformation;

  if (planungsbeginn === null || planungsende === null) {
    return content.noInfoAvailable;
  } else {
    return formatDateRange(planungsbeginn, planungsende);
  }
};

export const printWorkflowStatus = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.cservWorkflowStatus === null) {
    print += content.noInfoAvailable;
    return print;
  }

  let cservWorkflowLabel: null | string = null;

  switch (planungsinformation.cservWorkflowStatus) {
    case cservWorkflowStatus.Produktmanagement:
      cservWorkflowLabel = content.workflowStatusLabels.produktmanagement;
      break;
    case cservWorkflowStatus.VAOrga:
      cservWorkflowLabel = content.workflowStatusLabels.vaOrga;
      break;
    case cservWorkflowStatus.PlanungAbgeschlossen:
      cservWorkflowLabel = content.workflowStatusLabels.planungAbgeschlossen;
      break;
    case cservWorkflowStatus.PlanungInaktiv:
      cservWorkflowLabel = content.workflowStatusLabels.planungInaktiv;
      break;
    default:
      break;
  }

  if (cservWorkflowLabel === null) {
    print += content.noInfoAvailable;
  } else {
    print += cservWorkflowLabel;
  }

  return print;
};

export const printTermineImPlanungsintervall = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  const { termineImPlanungsintervallIst, termineImPlanungsintervallSoll } = planungsinformation;

  if (termineImPlanungsintervallIst === null) {
    print += content.noInfoAvailable;
  } else {
    print += termineImPlanungsintervallIst;
  }

  print += ' / ';

  if (termineImPlanungsintervallSoll === null) {
    print += content.noInfoAvailable;
  } else {
    print += termineImPlanungsintervallSoll;
  }

  return print;
};
