import { type Geschaeftsbereich } from '../../../../dtos';
import colorPalette from '../../../../theme/colorPalette';
import { BackendConfigContext, formatTimeRangeForDate } from '../../../../utils';
import { type CalendarEvent, isExperteEvent } from '../helperClasses';
import { EventButton } from './EventButton';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

type ExternalSAPEventProps = {
  readonly calendarEvent: CalendarEvent;
};

const getGeschaeftsbereich = (calendarEvent: CalendarEvent): Geschaeftsbereich | null => {
  // only experteblockungen have a geschaeftsbereich, even when they do not point to a Veranstaltung in our system
  if (!isExperteEvent(calendarEvent)) {
    return null;
  }

  return calendarEvent.blockungData.geschaeftsbereich;
};

export const ExternalSAPEvent: React.FC<ExternalSAPEventProps> = ({ calendarEvent }: ExternalSAPEventProps) => {
  const [hiveUrl, setHiveUrl] = useState<null | string>(null);
  const backendConfig = useContext(BackendConfigContext);

  useEffect(() => {
    if (!isExperteEvent(calendarEvent)) {
      return;
    }

    const experteSapId = calendarEvent.ressourceSapId;
    setHiveUrl(`${backendConfig.HiveAppUrl}/experten/${experteSapId}/auslastung`);
  }, [calendarEvent, backendConfig.HiveAppUrl]);

  const gotoHive = (): void => {
    if (hiveUrl === null) {
      return;
    }

    window.open(hiveUrl, '_blank', 'noopener');
  };

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant="caption">AKA-Veranstaltung-ID: {calendarEvent.blockungData.quelleTerminId}</Typography>
          <Typography variant="caption">Geschäftsbereich: {getGeschaeftsbereich(calendarEvent) ?? '-'}</Typography>
          <Typography variant="caption">Ablaufzeiten:</Typography>
          {calendarEvent.blockungData.zeitraeume.map(({ start, end }) => (
            <Typography key={Number(start)} variant="caption" paddingLeft={1}>
              {formatTimeRangeForDate(start, end)}
            </Typography>
          ))}
          <Typography variant="caption">Achtung: Eintrag wurde in SAP gepflegt</Typography>
        </Stack>
      }
    >
      <Box>
        <EventButton eventColor={colorPalette.warning.main} sx={{ cursor: 'default' }} onClick={gotoHive}>
          <Stack>
            <Typography variant="body2" textAlign="center" textOverflow="ellipsis" overflow="hidden">
              {calendarEvent.blockungData.quelleTerminId}
            </Typography>

            <Typography variant="caption" fontSize=".5rem">
              {getGeschaeftsbereich(calendarEvent) ?? (
                <div>
                  <br />
                </div>
              )}
            </Typography>
          </Stack>
        </EventButton>
      </Box>
    </Tooltip>
  );
};
