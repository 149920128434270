import { VivaPageLoading } from '../../components/general';
import { loginRequest } from '../../utils';
import { InteractionType } from '@azure/msal-browser';
import { type MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { type ReactNode } from 'react';

const ErrorWrapper: React.FC<MsalAuthenticationResult> = (result: MsalAuthenticationResult) => {
  console.log('Authentifizierungsfehler', { result });
  throw new Error('Authentifizierungsfehler', { cause: result.error });
};

const Loading: React.FC = () => <VivaPageLoading msg="Sie werden eingeloggt ..." />;

type WithAuthProps = {
  children: ReactNode | ReactNode[];
};

export const WithAuth: React.FC<WithAuthProps> = ({ children }: { readonly children: ReactNode | ReactNode[] }) => (
  <MsalAuthenticationTemplate authenticationRequest={loginRequest} errorComponent={ErrorWrapper} interactionType={InteractionType.Redirect} loadingComponent={Loading}>
    {children}
  </MsalAuthenticationTemplate>
);
