import { type Terminart, terminart as terminartEnum } from '../../../../dtos';

export function printTerminartTooltip(terminart: Terminart): string {
  switch (terminart) {
    case terminartEnum.STANDARDTERMIN:
      return 'Standardtermine sind Termine, die innerhalb der regulären halbjährigen Planungsphase angelegt werden.';
    case terminartEnum.ZUSATZTERMIN:
      return 'Zusatztermine sind Termine, die außerhalb der regulären Planungsphase, meist kurzfristig, zusätzlich angelegt werden.';
    case terminartEnum.ERSATZTERMIN:
      return 'Ersatztermine sind Termine, die als Ersatz für einen bisher geplanten Termin erstellt werden, weil der geplante Termin z. B. durch Krankheit des Trainers kurzfristig abgesagt werden musste';
    case null:
      return '-';
    default: {
      const exhaustiveCheck: never = terminart;
      throw new Error(`[printTerminartTooltip] Nicht implementierte Terminart: ${exhaustiveCheck}`);
    }
  }
}

export function printTerminanfrageTooltip(terminart: Terminart): string {
  if (terminart === terminartEnum.STANDARDTERMIN) {
    return 'Das Flag wird aus dem Thema vererbt und kann bei Standardterminen nicht geändert werden.';
  } else {
    return 'Terminanfragen werden bei Zusatz- und Ersatzterminen manuell durchgeführt und das Flag kann nicht verändert werden.';
  }
}
