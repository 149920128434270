import { type Clusterzuordnung } from '../../../dtos';
import { clusterzuordnungLabels } from '../../../utils';
import { printCluster } from './TerminExpertepoolCard.utils';
import { Box, Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';

const printClusterzuordnungTooltip = (clusterzuordnung: Clusterzuordnung | null): string => {
  if (clusterzuordnung === null) {
    return 'Trainer:in wurde in ContentServ nicht eindeutig einem Cluster (A,B,C) zugeordnet.';
  }

  return clusterzuordnungLabels[clusterzuordnung];
};

type ExpertePoolCellProps = {
  readonly clusterzuordnung: Clusterzuordnung | null;
};

export const ExpertePoolCell: FC<ExpertePoolCellProps> = (props: ExpertePoolCellProps) => (
  <Box sx={{ maxWidth: '1rem' }}>
    <Tooltip title={printClusterzuordnungTooltip(props.clusterzuordnung)} placement="right">
      <Typography variant="body2" height="100%" alignContent="center">
        {printCluster(props.clusterzuordnung)}
      </Typography>
    </Tooltip>
  </Box>
);
