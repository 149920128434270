import { type Ablauf, type AkaVeranstaltungId, type Standort } from '../../../../../dtos';
import { type CalendarEvent, CalendarStandortEvent, isGruppenRaumEvent, isStandardRaumEvent, isStandortEvent } from '../CalendarEvent';
import { calendarResourceTypes } from '../calendarResourceTypes';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { addDays, endOfDay, startOfDay } from 'date-fns';

/**
 * Represents one Standort and one row in the calendar.
 */
export class CalendarStandortResource extends AbstractCalendarResource {
  public readonly type = calendarResourceTypes.STANDORT;

  public readonly id: `s-${string}`;

  public readonly name: string;

  public readonly standort: Standort;

  public constructor(standort: Standort) {
    super();
    this.standort = standort;
    this.id = `s-${standort.standortSapId}`;
    this.name = `${standort.kuerzel} / ${standort.name}`;
  }

  public convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarStandortEvent[] {
    const events: CalendarStandortEvent[] = [];

    for (const blockung of this.standort.standortBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const raumgroesse = blockung.veranstaltung.thema.planungsinformation?.raumgroesse;

      const newEvent = new CalendarStandortEvent(start, end, this, {
        ortKuerzel: this.standort.kuerzel,
        quelleTerminId: blockung.veranstaltung.akaVeranstaltungId,
        veranstaltung: blockung.veranstaltung,
        zeitraeume: blockung.zeitraeume,
        gruppenraumNotwendig: blockung.veranstaltung.thema.planungsinformation?.gruppenraumNotwendig ?? null,
        raumgroesse: typeof raumgroesse === 'undefined' ? null : raumgroesse,
      });

      newEvent.isOldSelection = blockung.veranstaltung.akaVeranstaltungId === akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date, ablaufTageCount: number): CalendarStandortEvent {
    const zeitraeume: Ablauf = this.createZeitraeumeByDateAndDayCount(date, ablaufTageCount);
    const start = startOfDay(date);
    const end = endOfDay(addDays(date, ablaufTageCount - 1));

    const event = new CalendarStandortEvent(start, end, this, {
      // unimportant values
      ortKuerzel: null,
      quelleTerminId: '',
      veranstaltung: null,
      gruppenraumNotwendig: [],
      raumgroesse: 0,

      // important values
      zeitraeume,
    });

    return event;
  }

  public getRessourceSapId(): number {
    return this.standort.standortSapId;
  }

  public isEventCompatibleWithResource(otherEvent: CalendarEvent): boolean {
    return !isStandortEvent(otherEvent) && !isStandardRaumEvent(otherEvent) && !isGruppenRaumEvent(otherEvent);
  }
}
