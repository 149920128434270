import { type Zeitraum } from '../../dtos';
import { content } from '../content';

const dateFormatter = new Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeZone: 'Europe/Berlin' });
const timeFormatter = new Intl.DateTimeFormat('de-DE', { timeStyle: 'short', timeZone: 'Europe/Berlin' });
const timeFormatterMedium = new Intl.DateTimeFormat('de-DE', { timeStyle: 'medium', timeZone: 'Europe/Berlin' });

/*
 * Singles
 */

// 22.08.2024
export const formatDate = (date?: Date | null) => (date ? dateFormatter.format(date) : '-');

// 09:00
const formatTime = (date: Date) => timeFormatter.format(date);

// 09:00:00
const formatTimeWithSeconds = (date: Date) => timeFormatterMedium.format(date);

// 22.08.2024, 09:00:00
export const formatTimestamp = (date: Date) => `${formatDate(date)}, ${formatTimeWithSeconds(date)}`;

/*
 * Ranges
 */

// 22.08.2024, 09:00 - 17:00
export const formatTimeRangeForDate = (start: Date, end: Date) => `${formatDate(start)}, ${formatTime(start)} - ${formatTime(end)}`;

// 22.08.2024 - 31.03.2026
export const formatDateRange = (start: Date, end: Date) => {
  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);

  if (formattedStart === formattedEnd) {
    return formattedStart;
  }

  return `${formattedStart} – ${formattedEnd}`;
};

// 22.08.2024 - 31.03.2026
// can be used to format a multi range, usually of an ablauf
export const formatDateMultiRange = (multirange: Zeitraum[]) => {
  if (multirange.length === 0) return content.noInfoAvailable;

  const start = multirange.map((ablaufTag) => ablaufTag.start).reduce((previous, current) => (current < previous ? current : previous));
  const end = multirange.map((ablaufTag) => ablaufTag.end).reduce((previous, current) => (current > previous ? current : previous));

  return formatDateRange(start, end);
};
