import { clusterzuordnung, type PlanungsinformationFromThemaRouter, type RessourcenBlockungen, type RessourcenBlockungType, ressourcenBlockungTypes } from '../../../dtos';
import { getOverallBoundsOfIntervals, printClusterzuordnungLabel } from '../../../utils';
import {
  type CalendarEvent,
  type CalendarResource,
  type CalendarResourceType,
  calendarResourceTypes,
  createCalendarColors,
  isGruppenraumResource,
  isStandardRaumResource,
  isStandortResource,
} from '../ResourceCalendar';
import { content } from './CalendarResourceOverview.content';
import {
  type CollapseInfo,
  type ErsatztrainerResourceGroup,
  type ExperteResourceGroup,
  type GruppenraumResourceGroup,
  type HaupttrainerResourceGroup,
  isErsatztrainerResource,
  isHaupttrainerResource,
  isNebentrainerResource,
  isTrainerOhneZuordnungResource,
  type NebentrainerResourceGroup,
  type StandardRaumResourceGroup,
  type StandortResourceGroup,
  type TrainerOhneZuordnungResourceGroup,
} from './CalendarResourceOverview.utils.types';
import { type MbscCalendarColor, type MbscResource } from '@mobiscroll/react';
import { eachDayOfInterval, endOfDay, set, startOfDay } from 'date-fns';

const getGroupDefaultValues = (
  collapseInfo: CollapseInfo,
): {
  expertenGroup: ExperteResourceGroup;
  raeumeGroup: StandardRaumResourceGroup;
  gruppenraeumeGroup: GruppenraumResourceGroup;
  standorteGroup: StandortResourceGroup;
} => {
  const expertenGroup: ExperteResourceGroup = {
    id: calendarResourceTypes.EXPERTE,
    name: content.calenderResourceGroupLabels.EXPERTE,
    collapsed: collapseInfo.experte,
    children: [],
  };

  const standardRaeumeGroup: StandardRaumResourceGroup = {
    id: calendarResourceTypes.STANDARDRAUM,
    name: content.calenderResourceGroupLabels.STANDARDRAUM,
    children: [],
    collapsed: collapseInfo.standardraum,
  };

  const gruppenraeumeGroup: GruppenraumResourceGroup = {
    id: calendarResourceTypes.GRUPPENRAUM,
    name: content.calenderResourceGroupLabels.GRUPPENRAUM,
    children: [],
    collapsed: collapseInfo.gruppenraum,
  };

  const standorteGroup: StandortResourceGroup = {
    id: calendarResourceTypes.STANDORT,
    name: content.calenderResourceGroupLabels.STANDORT,
    children: [],
    collapsed: collapseInfo.standort,
  };

  return {
    expertenGroup,
    raeumeGroup: standardRaeumeGroup,
    gruppenraeumeGroup,
    standorteGroup,
  };
};

const getExpertenSubGroupDefaults = (
  planungsinformation: PlanungsinformationFromThemaRouter,
): {
  haupttrainerGroup: HaupttrainerResourceGroup;
  nebentrainerGroup: NebentrainerResourceGroup;
  ersatztrainerGroup: ErsatztrainerResourceGroup;
  trainerOhneZuordnungGroup: TrainerOhneZuordnungResourceGroup;
} => {
  const clusterAnteile = planungsinformation.clusteranteil?.split('/') ?? [];
  const clusteranteilA = clusterAnteile[0];
  const clusteranteilB = clusterAnteile[1];

  const haupttrainerGroup: HaupttrainerResourceGroup = {
    id: clusterzuordnung.HAUPTTRAINER,
    name: `${printClusterzuordnungLabel(clusterzuordnung.HAUPTTRAINER)} - Cluster ${clusteranteilA}%`,
    children: [],
  };

  const nebentrainerGroup: NebentrainerResourceGroup = {
    id: clusterzuordnung.NEBENTRAINER,
    name: `${printClusterzuordnungLabel(clusterzuordnung.NEBENTRAINER)} - Cluster ${clusteranteilB}%`,
    children: [],
  };

  const ersatztrainerGroup: ErsatztrainerResourceGroup = {
    id: clusterzuordnung.ERSATZTRAINER,
    name: `${printClusterzuordnungLabel(clusterzuordnung.ERSATZTRAINER)} - Cluster C`,
    children: [],
  };

  const trainerOhneZuordnungGroup: TrainerOhneZuordnungResourceGroup = {
    id: 'keineZuordnung',
    name: `${printClusterzuordnungLabel(null)}`,
    children: [],
  };

  return {
    haupttrainerGroup,
    nebentrainerGroup,
    ersatztrainerGroup,
    trainerOhneZuordnungGroup,
  };
};

export const groupCalendarResources = (
  resources: CalendarResource[],
  collapseInfo: CollapseInfo,
  planungsinformation: PlanungsinformationFromThemaRouter,
  isOnlineVeranstaltung: boolean,
  needsGruppenraum: boolean,
): MbscResource[] => {
  const { expertenGroup, raeumeGroup, gruppenraeumeGroup, standorteGroup } = getGroupDefaultValues(collapseInfo);
  const { haupttrainerGroup, nebentrainerGroup, ersatztrainerGroup, trainerOhneZuordnungGroup } = getExpertenSubGroupDefaults(planungsinformation);

  for (const resource of resources) {
    if (isHaupttrainerResource(resource)) {
      haupttrainerGroup.children.push(resource);
    }

    if (isNebentrainerResource(resource)) {
      nebentrainerGroup.children.push(resource);
    }

    if (isErsatztrainerResource(resource)) {
      ersatztrainerGroup.children.push(resource);
    }

    if (isTrainerOhneZuordnungResource(resource)) {
      trainerOhneZuordnungGroup.children.push(resource);
    }

    if (isStandortResource(resource)) {
      standorteGroup.children.push(resource);
    }

    if (isStandardRaumResource(resource)) {
      raeumeGroup.children.push(resource);
    }

    if (isGruppenraumResource(resource)) {
      gruppenraeumeGroup.children.push(resource);
    }
  }

  if (trainerOhneZuordnungGroup.children.length > 0) expertenGroup.children.push(trainerOhneZuordnungGroup);
  if (haupttrainerGroup.children.length > 0) expertenGroup.children.push(haupttrainerGroup);
  if (nebentrainerGroup.children.length > 0) expertenGroup.children.push(nebentrainerGroup);
  if (ersatztrainerGroup.children.length > 0) expertenGroup.children.push(ersatztrainerGroup);

  if (isOnlineVeranstaltung) {
    return [expertenGroup];
  } else if (needsGruppenraum) {
    return [raeumeGroup, gruppenraeumeGroup, standorteGroup, expertenGroup];
  } else {
    return [raeumeGroup, standorteGroup, expertenGroup];
  }
};

const createEventColor = (date: Date) => ({
  start: startOfDay(date),
  end: endOfDay(date),
  background: '#C8D9FA',
});

export const createCalendarColorsWithSelectionBounds = (resources: CalendarResource[], events: CalendarEvent[], minimumSelectionBoundsWidth: number): MbscCalendarColor[] => {
  const colors = createCalendarColors(resources, events);
  const currentSelection = events.filter((event) => event.isCurrentSelection);

  if (currentSelection.length) {
    const bounds = getOverallBoundsOfIntervals(currentSelection, minimumSelectionBoundsWidth);
    for (const date of eachDayOfInterval(bounds)) {
      colors.push(createEventColor(date));
    }
  }

  return colors;
};

const convertToResourceBlockungType = (type: CalendarResourceType): RessourcenBlockungType =>
  type === calendarResourceTypes.GRUPPENRAUM || type === calendarResourceTypes.STANDARDRAUM ? ressourcenBlockungTypes.RAUM : type;

export const convertCurrentlySelectedCalendarEventsToBlockungen = (events: CalendarEvent[]): RessourcenBlockungen => {
  const blockungen: RessourcenBlockungen = [];

  for (const event of events) {
    if (!event.isCurrentSelection) {
      continue;
    }

    for (const buchungsTag of eachDayOfInterval(event)) {
      blockungen.push({
        ressourceSapId: event.ressourceSapId,
        type: convertToResourceBlockungType(event.type),
        buchungsTag,
      });
    }
  }

  return blockungen;
};

export const convertTimeStringToDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 });
};
