import { endColumn, expertsColumn, regionNameColumn, startColumn, statusColumn, ThemaCard, TrpcLoadingInfo, veranstaltungSapIdColumn } from '../../../components/general';
import { type ThemaFromSearchRouter } from '../../../dtos';
import { trpc } from '../../../trpc';
import { type DataGridColDef, getIsOnlineVeranstaltung, getOrtKuerzel } from '../../../utils';
import { Box, Button, Stack } from '@mui/material';
import { Link } from 'react-router';

type ThemaSearchProps = {
  readonly searchTerm: string;
};

const columns: DataGridColDef<ThemaFromSearchRouter['veranstaltungen'][number]> = [
  startColumn(),
  endColumn(),
  veranstaltungSapIdColumn(),
  statusColumn(),
  regionNameColumn(),
  {
    field: 'hotelRaum',
    flex: 1,
    headerName: 'Hotel/Raum',
    valueGetter: (_value, row): string =>
      getIsOnlineVeranstaltung(row) ? 'Zoom' : `${getOrtKuerzel(row)} - ${row.raumBlockungen.map((raumBlockung) => raumBlockung.raum.kuerzel).join(', ')}`,
  },
  expertsColumn(),
];

export const ThemaSearch: React.FC<ThemaSearchProps> = ({ searchTerm }: ThemaSearchProps) => {
  const themen = trpc.search.searchThema.useQuery({ searchTerm }, { enabled: searchTerm.length > 0 });

  return (
    <Box sx={{ marginLeft: 4, paddingRight: 4 }}>
      <Stack direction="column" spacing={2}>
        <TrpcLoadingInfo trpcQuery={themen} entity="Themen">
          {themen.data?.map((thema) => (
            <ThemaCard key={thema.produktSapId} thema={thema} columns={columns} getRowId={(veranstaltung) => veranstaltung.akaVeranstaltungId}>
              <Link to={`/thema/${thema.akaProduktId}`}>
                <Button variant="contained">Zum Thema</Button>
              </Link>
            </ThemaCard>
          ))}
        </TrpcLoadingInfo>
      </Stack>
    </Box>
  );
};
