import { type Ablauf, type Raum, type Zeitraum } from '../../../../../dtos';
import { CalendarRaumEvent } from '../CalendarEvent';
import { type CalendarRaumResourceType } from '../calendarResourceTypes';
import { AbstractCalendarResource } from './AbstractCalendarResource';

export abstract class AbstractRaumResource extends AbstractCalendarResource {
  public abstract readonly type: CalendarRaumResourceType;

  public abstract readonly name: string;

  public readonly raum: Raum;

  public constructor(raum: Raum) {
    super();
    this.raum = raum;
  }

  public getRessourceSapId(): number {
    return this.raum.raumSapId;
  }

  public createNewCalendarEvent(date: Date, ablaufTageCount: number): CalendarRaumEvent {
    const zeitraeume: Ablauf = this.createZeitraeumeByDateAndDayCount(date, ablaufTageCount);
    const { start, end } = this.computeNewCalendarEventBounds(date, ablaufTageCount);

    return new CalendarRaumEvent(start, end, this, {
      // unimportant values
      ortKuerzel: null,
      quelleTerminId: '',
      veranstaltung: null,

      // important values
      zeitraeume,
    });
  }

  protected abstract computeNewCalendarEventBounds(date: Date, ablaufTageCount: number): Zeitraum;
}
