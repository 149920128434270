import { type ExperteBlockung } from '../../../dtos';
import { content } from './TerminExperteCard.content';

export const printEmail = (experteBlockung: ExperteBlockung): string => {
  if (experteBlockung.experte.email === null) {
    return content.noInfoAvailable;
  }

  return experteBlockung.experte.email;
};
