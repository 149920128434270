import { VivaPageLoading } from '../../components/general';
import { trpc } from '../../trpc';
import { ConnectToVpnWarning } from './ConnectVpnWarning';
import { type ReactNode, useEffect, useState } from 'react';

export const BackendReachable: React.FC<{ readonly children: ReactNode }> = ({ children }: { readonly children: ReactNode }) => {
  const [hasBeenIdleForLongTime, setHasBeenIdleForLongTime] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setHasBeenIdleForLongTime(true);
    }, 5_000);
  }, []);

  /*
   * This ping serves two purposes:
   * - start backendLambda on app open to speed up first search results
   * - determine if the backend is reachable at all
   *   - this way, we can offer the user some troubleshooting ideas for the most common failures, like not being logged into VPN
   */
  const ping = trpc.ping.useQuery(undefined, { retry: false });

  if (ping.isError) {
    if (ping.error.data?.code === 'UNAUTHORIZED') {
      return <ConnectToVpnWarning message="Prüfe bitte mit der Haufe-IT, ob dein Microsoft-Account dazu berechtigt ist, auf VIVA zuzugreifen." />;
    } else {
      return <ConnectToVpnWarning message="Ein Backend-Fehler ist aufgetreten. Versuche, neu zu laden, oder wende dich ans Team VAMOS." />;
    }
  }

  if (ping.isLoading) {
    if (hasBeenIdleForLongTime) {
      return <ConnectToVpnWarning message="Um VIVA zu nutzen musst du mit dem Haufe VPN verbunden sein!" />;
    } else {
      return <VivaPageLoading msg="Wird geladen ..." />;
    }
  }

  return <>{children}</>;
};
