import { addDays, eachDayOfInterval, endOfDay, type Interval, max, min, startOfDay } from 'date-fns';

export function getOverallBoundsOfIntervals(intervals: readonly Interval[], minimumWidthInDays: number = 1): Interval {
  if (!intervals.length) {
    throw new Error('intervals array is empty');
  }

  const days = getAllDaysOfIntervals(intervals);
  const start = min(days);
  const end = endOfDay(max([...days, addDays(start, minimumWidthInDays - 1)]));

  return { start, end };
}

export function getAllDaysOfIntervals(intervals: readonly Interval[]): Date[] {
  const timestamps = intervals.flatMap((item) => eachDayOfInterval(item).map((day) => startOfDay(day).getTime()));
  return timestamps.filter((value, index, array) => array.indexOf(value) === index).map((timestamp) => new Date(timestamp));
}
