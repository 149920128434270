import colorPalette from '../../../../theme/colorPalette';
import { formatTimeRangeForDate } from '../../../../utils';
import { type CalendarEvent, type CalendarStandortEvent, isStandortEvent } from '../helperClasses';
import { VeranstaltungEventButton } from './VeranstaltungEventButton';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';

export type InternalCalendarEvent = CalendarEvent & {
  blockungData: CalendarEvent['blockungData'] & {
    veranstaltung: NonNullable<CalendarEvent['blockungData']['veranstaltung']>;
  };
};

type VivaEventProps = {
  readonly calendarEvent: InternalCalendarEvent;
};

const printGruppenraum = (calendarEvent: CalendarStandortEvent): string => {
  if ((calendarEvent.blockungData.gruppenraumNotwendig?.length ?? 0) > 0) {
    return 'Ja';
  }

  return 'Nein';
};

const printRaumgroesse = (calendarEvent: CalendarStandortEvent): string => {
  if (calendarEvent.blockungData.raumgroesse === null) {
    return '-';
  }

  return calendarEvent.blockungData.raumgroesse.toString();
};

export const VivaEvent: React.FC<VivaEventProps> = ({ calendarEvent }: VivaEventProps) => (
  <Tooltip
    title={
      <Stack>
        <Typography variant="caption">{calendarEvent.blockungData.veranstaltung.titel}</Typography>
        <Typography variant="caption">AKA-Veranstaltung-ID: {calendarEvent.blockungData.veranstaltung.akaVeranstaltungId}</Typography>
        <Typography variant="caption">SapId: {calendarEvent.blockungData.veranstaltung.veranstaltungSapId ?? '-'}</Typography>
        <Typography variant="caption">Geschäftsbereich: {calendarEvent.blockungData.veranstaltung.geschaeftsbereich}</Typography>
        <Typography variant="caption">Ablaufzeiten:</Typography>
        {calendarEvent.blockungData.zeitraeume.map(({ start, end }) => (
          <Typography key={Number(start)} variant="caption" paddingLeft={1}>
            {formatTimeRangeForDate(start, end)}
          </Typography>
        ))}
        {isStandortEvent(calendarEvent) && (
          <>
            <Divider />
            <Typography variant="caption">Gruppenraum: {printGruppenraum(calendarEvent)}</Typography>
            <Typography variant="caption">Hotelqualifikation: siehe genereller Kommentar</Typography>
            <Typography variant="caption">Raumgröße: {printRaumgroesse(calendarEvent)}</Typography>
          </>
        )}
      </Stack>
    }
  >
    <Box>
      <VeranstaltungEventButton eventColor={colorPalette.colors.crimson} akaVeranstaltungId={calendarEvent.blockungData.veranstaltung.akaVeranstaltungId}>
        <Stack>
          <Typography variant="body2" textAlign="left">
            {calendarEvent.blockungData.veranstaltung.thema.buchungsnummer}
          </Typography>
          <Typography variant="caption" fontSize=".5rem" textAlign="center">
            {calendarEvent.blockungData.ortKuerzel ?? (
              <div>
                <br />
              </div>
            )}
          </Typography>
        </Stack>
      </VeranstaltungEventButton>
    </Box>
  </Tooltip>
);
