import { content as terminContent } from '../../../utils';

export const content = {
  ...terminContent,
  draggableId: 'zeitlicherablauf',
  heading: 'Zeitlicher Ablauf (aus ContentServ)',
  fields: {
    ablauf: 'Ablauf:',
    zusatzinfo: 'Zusatzinfo bei Abweichung zeitlicher Ablauf:',
  },
} as const;
