import {
  type CalendarEvent,
  CalendarEventHelper,
  type CalendarResource,
  type CalendarResourceType,
  calendarResourceTypes,
  createCalendarColors,
  createCalendarResources,
  type FilterParameters,
  ResourceCalendar,
  ResourceCalendarFilters,
  ResourceCalendarLegend,
} from '../../components/general';
import { type Zeitraum } from '../../dtos';
import { trpc } from '../../trpc';
import { sessionStorageApi, sessionStorageKeys } from '../../utils';
import { groupRaumResources, type RaumCollapseInfo } from './RaumResourceCalendar.utils';
import { Grid2, Stack } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';

export const RaumResourceCalendar: React.FC = () => {
  const initDate = new Date();

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [timeRange, setTimeRange] = useState<Zeitraum>({
    start: startOfMonth(initDate),
    end: endOfMonth(initDate),
  });
  const [filterParams, setFilterParams] = useState<FilterParameters>(
    sessionStorageApi.get(sessionStorageKeys.RAUMRESOURCEOVERVIEW_FILTER) ?? {
      region: [],
      standort: [],
      orte: [],
      raumgroesse: null,
      ortHatGruppenraum: null,
    },
  );
  const [collapseInfos, setCollapseInfos] = useState<RaumCollapseInfo>({ standardraum: false, gruppenraum: false });

  const raeume = trpc.planung.resources.getRaeume.useQuery(
    {
      regionSapIds: filterParams.region,
      standortSapIds: filterParams.standort,
      ortSapIds: filterParams.orte,
      raumgroesse: filterParams.raumgroesse,
      ortHatGruppenraum: filterParams.ortHatGruppenraum,
      ...timeRange,
    },
    {
      retry: false,
      refetchInterval: 60_000,
      refetchIntervalInBackground: true,
    },
  );

  useEffect(() => {
    if (raeume.data) {
      const newResources = createCalendarResources([], raeume.data, [], timeRange.start);
      setResources(newResources);
      const calendarEventHelper = new CalendarEventHelper();
      const initEvents = calendarEventHelper.initEvents(newResources, null);

      setEvents(initEvents);
    }
  }, [raeume.data, timeRange.start]);

  const handleExpansionChange = (resourceType: CalendarResourceType, collapsed: boolean): void => {
    setCollapseInfos((prev): RaumCollapseInfo => {
      switch (resourceType) {
        case calendarResourceTypes.STANDARDRAUM:
          return { ...prev, standardraum: collapsed };
        case calendarResourceTypes.GRUPPENRAUM:
          return { ...prev, gruppenraum: collapsed };
        default:
          return prev;
      }
    });
  };

  const onCalendarTimeRangeChanged = (newTimeRange: Zeitraum): void => {
    setTimeRange(newTimeRange);
  };

  return (
    <Grid2 container padding={2} spacing={2}>
      <Grid2 size={{ xs: 12 }}>
        <ResourceCalendarFilters
          filterParameters={filterParams}
          interval={timeRange}
          onFilterChange={(filter) => {
            sessionStorageApi.set(sessionStorageKeys.RAUMRESOURCEOVERVIEW_FILTER, filter);
            setFilterParams(filter);
          }}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <ResourceCalendarLegend hideActionLegend />
        </Stack>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <ResourceCalendar
          resources={groupRaumResources(resources, collapseInfos)}
          events={events}
          colors={createCalendarColors(resources)}
          isFullscreen={false}
          year={timeRange.start.getFullYear()}
          toggleExpansion={handleExpansionChange}
          initialDate={initDate}
          timeRangeChangeHandler={onCalendarTimeRangeChanged}
        />
      </Grid2>
    </Grid2>
  );
};
