import { type Zeitraum } from '../../../dtos';
import { trpc } from '../../../trpc';
import { ContentLoading } from '../ContentLoading';
import { getUniqueOrteFromRaeume, hatGruppenraumValues, parseRaumgroesse } from './ResourceCalendarFilters.utils';
import { Autocomplete, Stack, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useRef, useState } from 'react';

export type FilterParameters = {
  region: number[];
  standort: number[];
  orte: number[];
  raumgroesse: number | null;
  ortHatGruppenraum: boolean | null;
};

type ResourceCalendarFiltersProps = {
  readonly onFilterChange: (filter: FilterParameters) => void;
  readonly filterParameters: FilterParameters;
  readonly interval: Zeitraum;
};

export const ResourceCalendarFilters: React.FC<ResourceCalendarFiltersProps> = (props: ResourceCalendarFiltersProps) => {
  const { onFilterChange, filterParameters, interval } = props;

  const [raumgroesse, setRaumgroesse] = useState<number | null>(filterParameters.raumgroesse);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const availableRegions = trpc.planung.resources.getAvailableRegionen.useQuery();
  const availableStandorte = trpc.planung.resources.getAvailableStandorteByRegion.useQuery({ regionSapIds: [] });
  const availableOrte = trpc.planung.resources.getRaeume.useQuery({
    regionSapIds: [],
    standortSapIds: [],
    ortSapIds: [],
    raumgroesse: null,
    ortHatGruppenraum: null,
    start: interval.start,
    end: interval.end,
  });

  const updateFilterValue = <K extends keyof FilterParameters>(key: K, value: FilterParameters[K]): void => {
    onFilterChange({
      ...filterParameters,
      [key]: value,
    });
  };

  const handleRaumgroesseChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearTimeout(timeoutRef.current);

    const number = parseRaumgroesse(event.target.value);
    setRaumgroesse(number);

    timeoutRef.current = setTimeout(() => {
      updateFilterValue('raumgroesse', number);
    }, 400);
  };

  if (availableRegions.isLoading || availableStandorte.isLoading || availableOrte.isLoading) {
    return <ContentLoading />;
  }

  const uniqueOrte = getUniqueOrteFromRaeume(availableOrte.data ?? []);

  return (
    <Stack direction="row" display="flex" spacing={2} justifyContent="space-evenly">
      <FormControl fullWidth>
        <Autocomplete
          id="region-input"
          size="small"
          multiple
          defaultValue={availableRegions.data?.filter((region) => filterParameters.region.includes(region.regionSapId))}
          onChange={(_, newValues) => {
            updateFilterValue(
              'region',
              newValues.map((ort) => ort.regionSapId),
            );
          }}
          options={availableRegions.data ?? []}
          getOptionLabel={(opt) => opt.name}
          renderInput={(params) => <TextField {...params} label="Region" />}
        />
      </FormControl>

      <FormControl fullWidth>
        <Autocomplete
          id="standort-input"
          size="small"
          multiple
          defaultValue={availableStandorte.data?.filter((standort) => filterParameters.standort.includes(standort.standortSapId))}
          onChange={(_, newValues) => {
            updateFilterValue(
              'standort',
              newValues.map((ort) => ort.standortSapId),
            );
          }}
          options={availableStandorte.data ?? []}
          getOptionLabel={(opt) => opt.name}
          renderInput={(params) => <TextField {...params} label="Standort" />}
        />
      </FormControl>

      <FormControl fullWidth>
        <Autocomplete
          id="ort-input"
          size="small"
          multiple
          defaultValue={uniqueOrte.filter((ort) => filterParameters.orte.includes(ort.ortSapId))}
          onChange={(_, newValues) => {
            updateFilterValue(
              'orte',
              newValues.map((ort) => ort.ortSapId),
            );
          }}
          options={uniqueOrte}
          getOptionLabel={(opt) => opt.ort.kuerzel}
          renderInput={(params) => <TextField {...params} label="Ort (Hotel)" />}
        />
      </FormControl>

      <FormControl fullWidth>
        <Autocomplete
          id="hatGruppenraum-input"
          size="small"
          defaultValue={hatGruppenraumValues.find((item) => item.value === filterParameters.ortHatGruppenraum)}
          onChange={(_, newValue) => {
            updateFilterValue('ortHatGruppenraum', newValue?.value ?? null);
          }}
          options={hatGruppenraumValues}
          getOptionLabel={(opt) => opt.label}
          renderInput={(params) => <TextField {...params} label="Gruppenraum am Ort" />}
        />
      </FormControl>

      <FormControl sx={{ minWidth: '9rem' }}>
        <TextField id="raumgroesse-input" label="Mindestraumgröße" size="small" variant="outlined" value={raumgroesse ?? ''} onChange={handleRaumgroesseChange} />
      </FormControl>
    </Stack>
  );
};
