import { type ExpertePlanung } from '../../../dtos';
import { BackendConfigContext } from '../../../utils';
import { printName } from './TerminExpertepoolCard.utils';
import { useContext } from 'react';
import { Link } from 'react-router';

type ExperteNameCellProps = {
  readonly expertePlanung: ExpertePlanung;
};

export const ExperteNameCell: React.FC<ExperteNameCellProps> = ({ expertePlanung }: ExperteNameCellProps) => {
  const backendConfig = useContext(BackendConfigContext);

  return (
    <Link target="_blank" rel="noopener" to={`${backendConfig.HiveAppUrl}/experten/${expertePlanung.experteSapId}`}>
      {printName(expertePlanung)}
    </Link>
  );
};
