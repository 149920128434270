import { type Clusterzuordnung, clusterzuordnung } from '../dtos';

export const clusterzuordnungLabels = {
  haupttrainer: 'Haupttrainer',
  nebentrainer: 'Nebentrainer',
  ersatztrainer: 'Ersatztrainer',
} as const satisfies Record<NonNullable<Clusterzuordnung>, string>;

export const printClusterzuordnungLabel = (givenClusterzuordnung: Clusterzuordnung | null): string => {
  let clusterLabel: string;

  switch (givenClusterzuordnung) {
    case clusterzuordnung.HAUPTTRAINER:
      clusterLabel = clusterzuordnungLabels.haupttrainer;
      break;
    case clusterzuordnung.NEBENTRAINER:
      clusterLabel = clusterzuordnungLabels.nebentrainer;
      break;
    case clusterzuordnung.ERSATZTRAINER:
      clusterLabel = clusterzuordnungLabels.ersatztrainer;
      break;
    case null:
      clusterLabel = 'Trainer ohne Clusterzuordnung';
      break;
    default: {
      const exhaustiveCheck: never = givenClusterzuordnung;
      throw new Error(`Could not find label for clusterzuordnung ${exhaustiveCheck}`);
    }
  }

  return clusterLabel;
};
