import { type ThemaFromThemaRouter } from '../../../dtos';
import { printCservFormat } from '../../../utils';
import { content } from './ThemaHeader.content';
import { Grid2, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router';

type ThemaHeaderProps = {
  readonly thema: ThemaFromThemaRouter;
};

export const ThemaHeader: React.FC<ThemaHeaderProps> = (props: ThemaHeaderProps) => {
  const { thema } = props;

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid2 container display="flex" justifyContent="space-between">
        <Grid2 size={{ xs: 8 }}>
          <Stack>
            <Typography color="primary" variant="body2">
              Veranstaltungs<b>THEMA</b>
            </Typography>
            <Typography variant="h4">{thema.titel}</Typography>
            <Typography>
              {thema.themenblock} | {thema.themenbereich}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 display="flex" flexDirection="column">
          <Typography>
            {content.format} {printCservFormat(thema)}
          </Typography>
          <Typography>
            {content.buchungsnummer} {thema.buchungsnummer}
          </Typography>
          {thema.verknuepfungBlopUndPraesenzseminar && (
            <Typography>
              {content.verknuepfungBlop} <Link to={`/thema/${thema.verknuepfungBlopUndPraesenzseminar}`}>{thema.verknuepfungBlopUndPraesenzseminar}</Link>
            </Typography>
          )}
        </Grid2>
      </Grid2>
    </Paper>
  );
};
