import { type AkaVeranstaltungId, experteBlockungQuelle, type ExperteFromResourcesRouter, type Experteplanung, geschaeftsbereiche } from '../../../../../dtos';
import { getExperteName } from '../../../../../utils';
import { CalendarExperteEvent } from '../CalendarEvent';
import { calendarResourceTypes } from '../calendarResourceTypes';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { endOfDay, isWithinInterval, startOfDay, subDays } from 'date-fns';

type ExperteBlockungWithEventZeitraum = Omit<ExperteFromResourcesRouter['experteBlockungen'][number], 'zeitraeume'> & {
  blockungId: number;
  eventZeitraum: {
    start: Date;
    end: Date;
  };
};

/**
 * Represents one Experte, and one row in the calendar.
 */
export class CalendarExperteResource extends AbstractCalendarResource {
  public readonly type = calendarResourceTypes.EXPERTE;

  public readonly id: `e-${string}`;

  public readonly name: string;

  public readonly experte: ExperteFromResourcesRouter;

  public constructor(experte: ExperteFromResourcesRouter, jahr: number, expertePool: Experteplanung[]) {
    super();
    this.experte = experte;
    this.id = `e-${experte.experteSapId}`;
    this.name = this.#calculateExperteRowName(jahr, expertePool);
  }

  #calculateExperteRowName(jahr: number, expertePool: Experteplanung[]): string {
    let label = getExperteName(this.experte);

    const auslastungImJahr = this.experte.experteAuslastungen.find((experteAuslastung) => experteAuslastung.jahr === jahr);
    if (auslastungImJahr) {
      label += ' / ' + Math.round(auslastungImJahr.summeTage) + '/' + Math.round(auslastungImJahr.schwelleRot);
    }

    const expertePlanungsinformation = expertePool.find((expertePlanung) => expertePlanung.experteSapId === this.experte.experteSapId);

    if (!expertePlanungsinformation) {
      return label;
    }

    if (expertePlanungsinformation.einsatzort) {
      label += ' / ' + expertePlanungsinformation.einsatzort;
    }

    if (expertePlanungsinformation.terminverteilung) {
      label += ' / ' + expertePlanungsinformation.terminverteilung + '%';
    }

    if (expertePlanungsinformation.ausschlusszeiten) {
      label += ' / Ausschlusszeiten: ' + expertePlanungsinformation.ausschlusszeiten;
    }

    return label;
  }

  #getBlockungenWithConsecutiveDays(): ExperteBlockungWithEventZeitraum[] {
    const experteBlockungenWithEventZeitraum: ExperteBlockungWithEventZeitraum[] = [];

    for (let blockungsIndex = 0; blockungsIndex < this.experte.experteBlockungen.length; blockungsIndex++) {
      const blockung = this.experte.experteBlockungen[blockungsIndex];
      const sortedBlockungsTage = blockung.zeitraeume.sort((tag1, tag2) => tag1.start.getTime() - tag2.start.getTime());

      for (const blockungsTag of sortedBlockungsTage) {
        const preExistingExperteBlockungenWithEventZeitraum = experteBlockungenWithEventZeitraum.filter(
          (experteBlockungWithEventZeitraum) => experteBlockungWithEventZeitraum.blockungId === blockungsIndex,
        );

        if (preExistingExperteBlockungenWithEventZeitraum.length !== 0) {
          const previousDay = subDays(blockungsTag.start, 1);
          const preExistingExperteBlockungWithEventZeitraum = preExistingExperteBlockungenWithEventZeitraum.find((preExistingExperteBlockung) =>
            isWithinInterval(previousDay, preExistingExperteBlockung.eventZeitraum),
          );

          if (typeof preExistingExperteBlockungWithEventZeitraum !== 'undefined') {
            preExistingExperteBlockungWithEventZeitraum.eventZeitraum.end = endOfDay(blockungsTag.end);
            continue;
          }
        }

        experteBlockungenWithEventZeitraum.push({
          ...blockung,
          blockungId: blockungsIndex,
          eventZeitraum: {
            start: startOfDay(blockungsTag.start),
            end: endOfDay(blockungsTag.end),
          },
        });
      }
    }

    return experteBlockungenWithEventZeitraum;
  }

  public convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarExperteEvent[] {
    const events: CalendarExperteEvent[] = [];

    const experteBlockungenWithEventZeitraum = this.#getBlockungenWithConsecutiveDays();

    for (const blockung of experteBlockungenWithEventZeitraum) {
      const newEvent = new CalendarExperteEvent(blockung.eventZeitraum.start, blockung.eventZeitraum.end, this, {
        ortKuerzel: blockung.ortKuerzel,
        quelleTerminId: blockung.quelleTerminId,
        veranstaltung: blockung.veranstaltung,
        zeitraeume: [blockung.eventZeitraum],
        geschaeftsbereich: blockung.geschaeftsbereich,
        quelle: blockung.quelle,
      });
      newEvent.isOldSelection = blockung.quelleTerminId === akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date): CalendarExperteEvent {
    // with experten, at first click, only one day is selectable -> this is why this implementation of createEvent behaves differently
    const start = startOfDay(date);
    const end = endOfDay(date);
    const event = new CalendarExperteEvent(start, end, this, {
      // unimportant values
      ortKuerzel: null,
      quelleTerminId: '',
      veranstaltung: null,
      geschaeftsbereich: geschaeftsbereiche.KFF,
      quelle: experteBlockungQuelle.VAMOS,

      // important values
      zeitraeume: [{ start, end }],
    });
    return event;
  }

  public getRessourceSapId(): number {
    return this.experte.experteSapId;
  }

  public isEventCompatibleWithResource(): boolean {
    return true;
  }
}
