import { type Keyify, sapStatus as sapStatusOptions, type SapStatusArray, vivaStatus as vivaStatusOptions, type VivaStatusArray } from '../../../dtos';
import { determineStatusText } from '../../../utils';
import { content } from './StatusFilter.content';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { type SyntheticEvent, useCallback } from 'react';

type StatusFilterProps = {
  readonly handleVivaStatusChange: (status: VivaStatusArray) => void;
  readonly handleSapStatusChange: (sapStatus: SapStatusArray) => void;
};

const extendedSapStatusOptions = {
  ...sapStatusOptions,
  KEINSTATUS: 'keinStatus',
} as const satisfies Record<Keyify<SapStatusArray[number]>, SapStatusArray[number]>;

export const defaultVivaStatus = [vivaStatusOptions.INPLANUNG];
export const defaultSapStatus = [extendedSapStatusOptions.KEINSTATUS];

export const StatusFilter: React.FC<StatusFilterProps> = (props: StatusFilterProps) => {
  const { handleVivaStatusChange, handleSapStatusChange } = props;

  const onVivaStatusChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newValues: VivaStatusArray): void => {
      handleVivaStatusChange(newValues);
    },
    [handleVivaStatusChange],
  );

  const onSapStatusChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newValues: SapStatusArray): void => {
      handleSapStatusChange(newValues);
    },
    [handleSapStatusChange],
  );

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          defaultValue={defaultVivaStatus}
          onChange={onVivaStatusChange}
          options={Object.values(vivaStatusOptions)}
          getOptionLabel={(opt) => determineStatusText(null, opt)}
          renderInput={(params) => <TextField {...params} label={content.vivaStatus} />}
        />
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          defaultValue={defaultSapStatus}
          onChange={onSapStatusChange}
          options={Object.values(extendedSapStatusOptions)}
          getOptionLabel={(opt) => (opt === extendedSapStatusOptions.KEINSTATUS ? content.keinStatus : determineStatusText(opt, null))}
          renderInput={(params) => <TextField {...params} label={content.sapStatus} />}
        />
      </FormControl>
    </>
  );
};
