export const content = {
  columnLabels: {
    terminanfrageStatus: 'Terminanfrage-Status',
    terminanfrageAnfragedatum: 'Terminanfrage-Anfragedatum',
    terminart: 'Terminart',
    fehler: 'Fehlerstatus',
  },
  buttons: {
    abgeschlossen: 'auf "Planung abgeschlossen" setzen',
    freigegeben: 'auf "Planung freigegeben" setzen',
  },
  keineVeranstaltungenAuswahl: 'Keine Veranstaltungen ausgewählt-',
  keineVeranstaltungenAenderung: 'Keine Veranstaltungen geändert.',
} as const;
