import { calendarResourceTypes } from '../calendarResourceTypes';
import { type CalendarExperteEvent } from './CalendarExperteEvent';
import { type CalendarRaumEvent } from './CalendarRaumEvent';
import { type CalendarStandortEvent } from './CalendarStandortEvent';

export type CalendarEvent = CalendarExperteEvent | CalendarRaumEvent | CalendarStandortEvent;
export const isExperteEvent = (event: CalendarEvent): event is CalendarExperteEvent => event.type === calendarResourceTypes.EXPERTE;
export const isStandardRaumEvent = (event: CalendarEvent): event is CalendarRaumEvent => event.type === calendarResourceTypes.STANDARDRAUM;
export const isGruppenRaumEvent = (event: CalendarEvent): event is CalendarRaumEvent => event.type === calendarResourceTypes.GRUPPENRAUM;
export const isStandortEvent = (event: CalendarEvent): event is CalendarStandortEvent => event.type === calendarResourceTypes.STANDORT;
