import {
  akaVeranstaltungIdColumn,
  buchungsnummerColumn,
  endColumn,
  LinkWithCopyButton,
  startColumn,
  statusColumn,
  StatusTerminanfrageHive,
  titelColumn,
} from '../../components/general';
import { getLabelAndTooltip } from '../../components/general/StatusTerminanfrageHive/StatusTerminanfrageHive.utils';
import { type GetAutomatisierteTerminanfragenByThemaOutput, type Zeitraum } from '../../dtos';
import { type DataGridColDef, formatDate, formatDateMultiRange, getExperteName, printWithNoInfo } from '../../utils';
import { content } from './AutomatisierteTerminanfragenTable.content';
import { Box } from '@mui/material';
import { DataGridPro, gridClasses, type GridRowParams } from '@mui/x-data-grid-pro';
import { type ReactElement, useCallback, useEffect, useState } from 'react';

type AutomatisierteTerminanfragenTableProps = {
  readonly data: GetAutomatisierteTerminanfragenByThemaOutput;
  readonly isLoading?: boolean;
  readonly hiveUrl: string;
  readonly filterReset?: boolean;
};

const getAblaufRangeAsString = (ablauf: Zeitraum[]): string => formatDateMultiRange(ablauf);

export const AutomatisierteTerminanfragenTable: React.FC<AutomatisierteTerminanfragenTableProps> = ({
  data,
  isLoading,
  hiveUrl,
  filterReset,
}: AutomatisierteTerminanfragenTableProps) => {
  const [muiTableKey, setMuiTableKey] = useState<number>(1);

  const columnsOuter: DataGridColDef<GetAutomatisierteTerminanfragenByThemaOutput[number]> = [
    buchungsnummerColumn(),
    titelColumn(),
    { field: 'akaProduktId', headerName: content.columnLabelsOuter.akaProduktId, flex: 0.5 },
    {
      field: 'verknuepfungBlopUndPraesenzseminar',
      headerName: content.columnLabelsOuter.verknuepfungBlopUndPraesenzseminar,
      flex: 0.5,
      valueGetter: (_value, row) => printWithNoInfo(row.verknuepfungBlopUndPraesenzseminar),
    },
  ];

  const columnsInner: () => DataGridColDef<GetAutomatisierteTerminanfragenByThemaOutput[number]['veranstaltungen'][number]> = useCallback(
    () => [
      akaVeranstaltungIdColumn(),
      startColumn(),
      endColumn(),
      statusColumn(),
      {
        field: 'trainer',
        headerName: content.columnLabelsInner.trainer,
        flex: 1,
        valueGetter: (_, row) => getExperteName(row.experteBlockung.experte),
        renderCell: (params): ReactElement => (
          <LinkWithCopyButton text={params.value} to={`${hiveUrl}/experten/${params.row.experteBlockung.experteSapId}`} tooltip={content.tooltips.zuHiveWechseln} />
        ),
      },
      {
        field: 'blockungsablauf',
        headerName: content.columnLabelsInner.blockungsablauf,
        flex: 1,
        valueGetter: (_value, row): string => getAblaufRangeAsString(row.experteBlockung.zeitraeume),
      },
      {
        field: 'terminanfrageStatus',
        headerName: content.columnLabelsInner.terminanfrageStatus,
        flex: 0.75,
        valueGetter: (_, row): string | null => getLabelAndTooltip(row.experteBlockung.hiveTerminanfrageStatus, row.experteBlockung.hiveTerminanfrageRejectedReason).label,
        renderCell: (params): ReactElement => <StatusTerminanfrageHive experteBlockung={params.row.experteBlockung} />,
      },
      {
        field: 'terminanfrageAnfrageDatum',
        headerName: content.columnLabelsInner.terminanfrageAnfrageDatum,
        flex: 1,
        valueGetter: (_value, row): string => formatDate(row.experteBlockung.hiveExperteAnfragedatum),
      },
    ],
    [hiveUrl],
  );

  const getDetailPanelContent = useCallback(
    (params: GridRowParams<GetAutomatisierteTerminanfragenByThemaOutput[number]>): ReactElement => (
      <Box margin={4}>
        <DataGridPro columns={columnsInner()} rows={params.row.veranstaltungen} hideFooter getRowId={(veranstaltung) => veranstaltung.id} />
      </Box>
    ),
    [columnsInner],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const handleFilterReset = useCallback(() => {
    setMuiTableKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    if (filterReset) {
      handleFilterReset();
    }
  }, [filterReset, handleFilterReset]);

  return (
    <DataGridPro
      key={muiTableKey}
      columns={columnsOuter}
      rows={data}
      autoPageSize
      disableVirtualization
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      sx={{
        height: '100%',
        width: '100%',
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
      }}
      hideFooter
      loading={isLoading}
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      disableRowSelectionOnClick
      getRowId={(row) => row.akaProduktId}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
    />
  );
};
