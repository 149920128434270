import { akaVeranstaltungIdColumn, EasyBreadcrumbs, type EasyBreadcrumbsItem, titelColumn, veranstaltungSapIdColumn } from '../../components/general';
import { type FachlicherFehler } from '../../dtos';
import { trpc } from '../../trpc';
import { type DataGridColDef, printWithNoInfo } from '../../utils';
import { formatDate, formatTimestamp } from '../../utils/dateFormat/dateFormat';
import { Alert, Box } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

const columns: DataGridColDef<FachlicherFehler> = [
  { field: 'createdAt', type: 'dateTime', headerName: 'Fehler-Zeitstempel', renderCell: (params) => formatTimestamp(params.value), flex: 0.5 },
  {
    field: 'beginnDesFehlerhaftenDatensatzes',
    type: 'date',
    headerName: 'Datum',
    flex: 0.6,
    renderCell: (params) => formatDate(params.value),
    description: 'Die Spalte enthält in der Regel den Veranstaltungsbeginn. Bei Experten- und Raumblockungsfehlern wird hier das Datum der Blockung angezeigt.',
  },
  veranstaltungSapIdColumn(),
  akaVeranstaltungIdColumn(),
  { field: 'quelle', headerName: 'Quelle', flex: 0.5 },
  titelColumn(),
  { field: 'geschaeftsbereich', headerName: 'Geschäftsbereich', flex: 0.5 },
  { field: 'code', headerName: 'Fehler-Typ', flex: 0.6, valueGetter: (_value, row) => printWithNoInfo(row.code) },
  { field: 'message', headerName: 'Fehler-Beschreibung', flex: 1, valueGetter: (_value, row) => printWithNoInfo(row.message) },
];

export const FehlerPage: React.FC = () => {
  const fehlerResult = trpc.fehler.getFachlicheFehler.useQuery();

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/fehler',
      label: 'Fehler',
    },
  ];

  if (fehlerResult.error) {
    throw new Error('Fehler beim Laden.', { cause: fehlerResult.error });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 2,
        paddingY: 2,
        boxSizing: 'border-box',
      }}
    >
      <EasyBreadcrumbs items={breadcrumbs} />
      <Box sx={{ flexGrow: 1, minHeight: 0 }}>
        <DataGridPro
          columns={columns}
          rows={fehlerResult.data ?? []}
          getRowId={(fehler) => fehler.id}
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          sx={{
            height: '100%',
            width: '100%',
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
          disableRowSelectionOnClick
          loading={fehlerResult.isLoading}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Box>
      <Alert severity="info">
        Die Fehlertabelle enthält nur Fehler von Veranstaltungen, die noch relevant sind. Fehler werden folglich nur bis zu einer Woche nach Veranstaltungsbeginn angezeigt.
      </Alert>
    </Box>
  );
};
