import { EasyBreadcrumbs, type EasyBreadcrumbsItem } from '../../components/general';
import { type GetAutomatisierteTerminanfragenByThemaInput, type GetAutomatisierteTerminanfragenByThemaOutput } from '../../dtos';
import { trpc } from '../../trpc';
import { BackendConfigContext } from '../../utils';
import { AutomatisierteTerminanfragenFilter } from './AutomatisierteTerminanfragenFilter';
import { AutomatisierteTerminanfragenTable } from './AutomatisierteTerminanfragenTable';
import { Alert, Box } from '@mui/material';
import { useContext, useState } from 'react';

export const TerminanfragenAutomatisiertPage: React.FC = () => {
  const backendConfig = useContext(BackendConfigContext);

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/terminanfragen/automatisiert',
      label: 'Automatisierte Terminanfragen - Statusübersicht',
    },
  ];

  const [terminanfrageFilterConfiguration, setTerminanfrageFilterConfiguration] = useState<GetAutomatisierteTerminanfragenByThemaInput | undefined>();

  const themenForTermincheckQuery = trpc.reporting.getAutomatisierteTerminanfragenByThema.useQuery<GetAutomatisierteTerminanfragenByThemaOutput>(terminanfrageFilterConfiguration);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 2,
        paddingY: 2,
        boxSizing: 'border-box',
      }}
    >
      <EasyBreadcrumbs items={breadcrumbs} />

      <AutomatisierteTerminanfragenFilter isLoading={themenForTermincheckQuery.isLoading} setTerminanfrageFilterConfiguration={setTerminanfrageFilterConfiguration} />
      <Box sx={{ flexGrow: 1, minHeight: 0 }}>
        <AutomatisierteTerminanfragenTable
          data={themenForTermincheckQuery.data ?? []}
          isLoading={themenForTermincheckQuery.isLoading}
          hiveUrl={backendConfig.HiveAppUrl}
          filterReset={terminanfrageFilterConfiguration === undefined}
        />
      </Box>
      <Alert severity="info">
        Die Tabelle enthält nur Produkte mit Veranstaltungen, die noch relevant sind. Das heißt, dass der Veranstaltungsbeginn in der Zukunft liegen muss.
      </Alert>
    </Box>
  );
};
