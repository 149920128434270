import { EasyBreadcrumbs, type EasyBreadcrumbsItem } from '../../components/general';
import { RaumResourceCalendar } from './RaumResourceCalendar';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

export const RaumRessourceOverviewPage: React.FC = () => {
  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/raumressourcenuebersicht',
      label: 'Raum-Ressourcen-Übersicht',
    },
  ];

  return (
    <Box sx={{ marginTop: 2 }}>
      <EasyBreadcrumbs items={breadcrumbs} />
      <Card>
        <CardContent>
          <Typography variant="h5">Raum-Ressourcen-Übersicht</Typography>
          <Divider />
          <RaumResourceCalendar />
        </CardContent>
      </Card>
    </Box>
  );
};
