import { content } from './TerminPlanungsinformationenCard.content';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { type ReactNode } from 'react';

type RaumgroesseWithTooltipProps = { readonly value: ReactNode };
export const RaumgroesseWithTooltip: React.FC<RaumgroesseWithTooltipProps> = (props: RaumgroesseWithTooltipProps) => {
  const { value } = props;

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant="caption">{content.raumgroesseTooltip}</Typography>
        </Stack>
      }
    >
      <Box data-testid="raumgroesseNoInfoAvailable">{value}</Box>
    </Tooltip>
  );
};
