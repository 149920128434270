import { type BackendConfigJson, backendConfigJsonSchema, localhostDefaultConfig } from './utils';
import { type AuthenticationResult, type BrowserAuthOptions, type EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';

export const getBackendConfig = async (): Promise<BackendConfigJson> => {
  if (window.location.hostname === 'localhost') {
    return localhostDefaultConfig;
  }

  const config = await fetch('/backend-config.json')
    .then(async (res) => await res.json())
    .catch((error) => {
      throw error;
    });

  const parsedConfig = backendConfigJsonSchema.safeParse(config);
  if (!parsedConfig.success) {
    throw parsedConfig.error;
  }

  return parsedConfig.data;
};

const msalConfig = (config: BackendConfigJson) => {
  const auth: BrowserAuthOptions = {
    clientId: config.AzureAdClientId,
    authority: `https://login.microsoftonline.com/${config.AzureAdTenantId}`,
    redirectUri: config.AzureAdRedirectUri,
    navigateToLoginRequestUrl: true,
  };

  return {
    auth,
  };
};

export class AzureApplication extends PublicClientApplication {
  public constructor(config: BackendConfigJson) {
    super(msalConfig(config));

    const accounts = this.getAllAccounts();
    if (accounts.length > 0) {
      this.setActiveAccount(accounts[0]);
    }

    this.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        this.setActiveAccount(account);
      }
    });
  }
}
