import { type GekaufterZeitraumTag, type Zeitraum } from '../../../dtos';
import colorPalette from '../../../theme/colorPalette';
import {
  type CalendarEvent,
  type CalendarExperteResource,
  type CalendarGruppenraumResource,
  type CalendarResource,
  type CalendarStandardRaumResource,
  type CalendarStandortResource,
  isExperteResource,
  isGruppenraumResource,
  isStandardRaumEvent,
  isStandardRaumResource,
  isStandortResource,
} from './helperClasses';
import { type MbscCalendarColor } from '@mobiscroll/react';
import { endOfDay, startOfDay } from 'date-fns';

function getSelectedRaumOrtKuerzel(events: CalendarEvent[]): string | undefined {
  const selectedRaumEvent = events.find((event) => event.isCurrentSelection && isStandardRaumEvent(event));

  if (selectedRaumEvent && isStandardRaumEvent(selectedRaumEvent)) {
    return selectedRaumEvent.ortKuerzel;
  } else {
    return undefined;
  }
}

type CalendarColor = MbscCalendarColor & Zeitraum;

const CALENDAR_RESOURCE_BACKGROUND_COLOR = colorPalette.colors.lightgreen;

const createRaumColor = (raumResource: CalendarStandardRaumResource, availability: GekaufterZeitraumTag): CalendarColor => ({
  start: startOfDay(availability.start),
  // we remove a minute from the end date to avoid highlighting the next day (e.g. start: 2024-08-17T00:00:00, end: 2024-08-18T00:00:00)
  end: endOfDay(new Date(availability.end.valueOf() - 60_000)),
  resource: raumResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createGruppenraumColor = (gruppenraumResource: CalendarGruppenraumResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: gruppenraumResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createStandortColor = (standortResource: CalendarStandortResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: standortResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createExperteColor = (experteResource: CalendarExperteResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: experteResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

export const createCalendarColors = (resources: CalendarResource[], events: CalendarEvent[] = []): MbscCalendarColor[] => {
  const colors: MbscCalendarColor[] = [];
  const selectedRaumOrtKuerzel = getSelectedRaumOrtKuerzel(events);

  for (const resource of resources) {
    if (isStandardRaumResource(resource)) {
      for (const availability of resource.raum.gekaufterZeitraum) {
        colors.push(createRaumColor(resource, availability));
      }
    } else if (isGruppenraumResource(resource) && resource.raum.ort.kuerzel === selectedRaumOrtKuerzel) {
      colors.push(createGruppenraumColor(resource));
    } else if (isStandortResource(resource)) {
      colors.push(createStandortColor(resource));
    } else if (isExperteResource(resource)) {
      colors.push(createExperteColor(resource));
    }
  }

  return colors;
};
