import {
  clusterzuordnung,
  cservWorkflowStatus,
  type RessourcenBlockungen,
  terminart,
  type ThemaFromThemaRouter,
  type Veranstaltung,
  type VeranstaltungCustomProperties,
  type Zeitraum,
} from '../../../dtos';
import { trpc } from '../../../trpc';
import { getIsOnlineThema } from '../../../utils';
import {
  type CalendarEvent,
  CalendarEventHelper,
  type CalendarResource,
  type CalendarResourceType,
  calendarResourceTypes,
  createCalendarResources,
  type FilterParameters,
  ResourceCalendar,
  ResourceCalendarFilters,
  ResourceCalendarLegend,
} from '../ResourceCalendar';
import { content } from './CalendarResourceOverview.content';
import {
  convertCurrentlySelectedCalendarEventsToBlockungen,
  convertTimeStringToDate,
  createCalendarColorsWithSelectionBounds,
  groupCalendarResources,
} from './CalendarResourceOverview.utils';
import { type CollapseInfo } from './CalendarResourceOverview.utils.types';
import { FullScreen, VeranstaltungPropertiesChanger } from './helperComponents';
import { ActionButtons } from './helperComponents/ActionButtons';
import { type MbscCellClickEvent, type MbscEventClickEvent } from '@mobiscroll/react';
import { Alert, Divider, Grid2, Stack, Typography } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

export type CalendarResourceOverviewProps = {
  readonly isFullscreen: boolean;
  readonly toggleFullscreen: () => void;
  readonly onSave: (blockungen: RessourcenBlockungen, properties?: VeranstaltungCustomProperties) => Promise<void>;
  readonly onCancel: () => void | Promise<void>;
  readonly initDate: Date;
  readonly thema: Pick<ThemaFromThemaRouter, 'id' | 'planungsinformation' | 'manuelleTerminanfrage' | 'isOnline'>;
  readonly veranstaltung: Pick<Veranstaltung, 'akaVeranstaltungId' | 'ablauf'> | null;
};

const rowHeaderCellResourceIds: Array<string | number> = [...Object.values(calendarResourceTypes), ...Object.values(clusterzuordnung)];

export const CalendarResourceOverview: React.FC<CalendarResourceOverviewProps> = ({
  onSave,
  onCancel,
  initDate,
  isFullscreen,
  toggleFullscreen,
  thema,
  veranstaltung,
}: CalendarResourceOverviewProps) => {
  const needsGruppenraum = Boolean(thema.planungsinformation?.gruppenraumNotwendig?.length);

  const [filterParams, setFilterParams] = useState<FilterParameters>({
    region: [],
    standort: [],
    orte: [],
    raumgroesse: thema.planungsinformation?.raumgroesse ?? null,
    ortHatGruppenraum: needsGruppenraum || null,
  });

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [collapseInfos, setCollapseInfos] = useState<CollapseInfo>({ standardraum: false, gruppenraum: true, standort: true, experte: false });
  const [timeRange, setTimeRange] = useState<Zeitraum>({
    start: startOfMonth(initDate),
    end: endOfMonth(initDate),
  });

  const [veranstaltungCustomProperties, setVeranstaltungCustomProperties] = useState<VeranstaltungCustomProperties>({
    terminart: terminart.STANDARDTERMIN,
    manuelleTerminanfrage: thema.manuelleTerminanfrage,
    withoutResources: false,
    beginDate: undefined,
  });

  const { enqueueSnackbar } = useSnackbar();

  const standorte = trpc.planung.resources.getStandorte.useQuery(
    {
      ...timeRange,
      standortSapIds: filterParams.standort,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const expertePool = trpc.planung.resources.getBookableExpertenByThema.useQuery(
    {
      themaId: thema.id,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const raeume = trpc.planung.resources.getRaeume.useQuery(
    {
      regionSapIds: filterParams.region,
      standortSapIds: filterParams.standort,
      ortSapIds: filterParams.orte,
      raumgroesse: filterParams.raumgroesse,
      ortHatGruppenraum: filterParams.ortHatGruppenraum,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const isOnlineThema = getIsOnlineThema(thema);

  const calendarEventHelper = useMemo(() => {
    if (veranstaltung !== null) {
      return new CalendarEventHelper(veranstaltung.ablauf.length);
    }

    const ablaufTageTemplate = thema.planungsinformation?.ablaufTageTemplate;
    if (!ablaufTageTemplate) {
      // todo wenn ablaufTagTemplate nicht vorhanden dann wollen wir dem User einen Fehler anzeigen: keine Planungsinformation vorhanden
      return new CalendarEventHelper();
    }

    const ablauf = ablaufTageTemplate.map((ablaufTagTemplate) => ({
      start: convertTimeStringToDate(ablaufTagTemplate.startZeit),
      end: convertTimeStringToDate(ablaufTagTemplate.endZeit),
    }));

    return new CalendarEventHelper(ablauf.length);
  }, [thema, veranstaltung]);

  useEffect(() => {
    if (expertePool.data && raeume.data && standorte.data && thema.planungsinformation) {
      const resourcesInitialized = createCalendarResources(expertePool.data, raeume.data, standorte.data, timeRange.start, thema.planungsinformation);
      setResources(resourcesInitialized);

      const eventsInitialized = calendarEventHelper.initEvents(resourcesInitialized, veranstaltung?.akaVeranstaltungId ?? null);
      setEvents(eventsInitialized);
    }
  }, [expertePool.data, raeume.data, standorte.data, thema.planungsinformation, veranstaltung, calendarEventHelper, timeRange.start]);

  const onCalendarCellClicked = (args: MbscCellClickEvent): void => {
    const clickedResourceId = args.resource;

    // Ignore clicks on row header cells
    if (rowHeaderCellResourceIds.includes(clickedResourceId)) {
      return;
    }

    const clickedResource = resources.find((res) => res.id === clickedResourceId);
    if (!clickedResource) {
      enqueueSnackbar('Ressource nicht gefunden.', { variant: 'error' });
      return;
    }

    setEvents((previousEvents) => {
      try {
        return calendarEventHelper.addEvent(clickedResource, events, args.date);
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
        return previousEvents;
      }
    });
  };

  const onCalendarEventClicked = (mbscEvent: MbscEventClickEvent): void => {
    // the eventId is, for our calendar events, always a string (see AbstractCalendarEvent)
    // however, Mobiscroll does not know this -> this function serves as a type safety wrapper
    const eventId = mbscEvent.event.id;
    if (typeof eventId === 'undefined' || typeof eventId === 'number') {
      // this implies that someone played around with the calendar events and did not update this code
      console.warn('Unknown calendar event id type');
      return;
    }

    setEvents(calendarEventHelper.removeEvent(eventId, events));
  };

  const onCalendarTimeRangeChanged = (newTimeRange: Zeitraum): void => {
    setTimeRange(newTimeRange);
  };

  const handleSave = async (): Promise<void> => {
    const ressourcenBlockungen = convertCurrentlySelectedCalendarEventsToBlockungen(events);

    setEvents([]);
    await onSave(ressourcenBlockungen, veranstaltungCustomProperties);
  };

  const handleCancel = async (): Promise<void> => {
    setEvents([]);
    await onCancel();
  };

  const handleExpansionChange = (resourceType: CalendarResourceType, collapsed: boolean): void => {
    setCollapseInfos((prev): CollapseInfo => {
      switch (resourceType) {
        case calendarResourceTypes.EXPERTE:
          return { ...prev, experte: collapsed };
        case calendarResourceTypes.STANDARDRAUM:
          return { ...prev, standardraum: collapsed };
        case calendarResourceTypes.GRUPPENRAUM:
          return { ...prev, gruppenraum: collapsed };
        case calendarResourceTypes.STANDORT:
          return { ...prev, standort: collapsed };
        default:
          return prev;
      }
    });
  };

  const renderSuchfilter = (): ReactElement => (
    <Grid2 size={{ xs: 12 }}>
      <ResourceCalendarFilters
        filterParameters={filterParams}
        interval={timeRange}
        onFilterChange={(filter) => {
          setFilterParams(filter);
        }}
      />
    </Grid2>
  );

  const renderWarning = (warningMessage: string): ReactElement => (
    <Grid2 size={{ xs: 12 }}>
      <Alert severity="warning">{warningMessage}</Alert>
    </Grid2>
  );

  if (!thema.planungsinformation) {
    throw new Error('Thema without planungsinformation');
  }

  if (expertePool.isError) {
    throw new Error('Experte query failure', { cause: expertePool.error });
  }

  if (standorte.isError) {
    throw new Error('Standort query failure', { cause: standorte.error });
  }

  if (raeume.isError) {
    throw new Error('Raum query failure', { cause: raeume.error });
  }

  const selectedCalendarEvents = events.filter((calendarEvent) => calendarEvent.isCurrentSelection);

  return (
    <FullScreen isFullscreen={isFullscreen} withoutCloseButton>
      <Grid2 container padding={2} spacing={2}>
        {!isFullscreen && (
          <ActionButtons
            isFullscreen={isFullscreen}
            isOnlineThema={isOnlineThema}
            selectedCalendarEvents={selectedCalendarEvents}
            veranstaltungCustomProperties={veranstaltungCustomProperties}
            onCancel={handleCancel}
            onFullscreenToggle={toggleFullscreen}
            onSave={handleSave}
          />
        )}
        {!isFullscreen && (
          <>
            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
              <Divider />
            </Grid2>

            <VeranstaltungPropertiesChanger
              themaManuelleTerminanfrage={thema.manuelleTerminanfrage}
              handleAttributeChange={(properties: VeranstaltungCustomProperties) => {
                setVeranstaltungCustomProperties(properties);
              }}
              ablaufTageCount={calendarEventHelper.ablaufTageCount}
              isOnlineThema={isOnlineThema}
            />

            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
              <Divider />
            </Grid2>
          </>
        )}
        {!veranstaltungCustomProperties.withoutResources && (
          <>
            <Grid2 size={{ xs: 6 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Ressourcenübersicht</Typography>
            </Grid2>
            {isFullscreen && (
              <ActionButtons
                isFullscreen={isFullscreen}
                isOnlineThema={isOnlineThema}
                selectedCalendarEvents={selectedCalendarEvents}
                veranstaltungCustomProperties={veranstaltungCustomProperties}
                onCancel={handleCancel}
                onFullscreenToggle={toggleFullscreen}
                onSave={handleSave}
              />
            )}
            {!isOnlineThema && renderSuchfilter()}
            <Grid2 size={{ xs: 12 }}>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <ResourceCalendarLegend />
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              {isOnlineThema && renderWarning(content.warnungen.onlineVeranstaltung)}
              {thema.planungsinformation.cservWorkflowStatus === cservWorkflowStatus.PlanungInaktiv && renderWarning(content.warnungen.planungInaktiv)}
              <ResourceCalendar
                resources={groupCalendarResources(resources, collapseInfos, thema.planungsinformation, isOnlineThema, needsGruppenraum)}
                events={events}
                colors={createCalendarColorsWithSelectionBounds(resources, events, calendarEventHelper.ablaufTageCount)}
                isFullscreen={isFullscreen}
                year={timeRange.start.getFullYear()}
                toggleExpansion={handleExpansionChange}
                initialDate={veranstaltungCustomProperties.beginDate ? veranstaltungCustomProperties.beginDate : initDate}
                timeRangeChangeHandler={onCalendarTimeRangeChanged}
                cellClickHandler={onCalendarCellClicked}
                eventClickHandler={onCalendarEventClicked}
              />
            </Grid2>
          </>
        )}
      </Grid2>
    </FullScreen>
  );
};
