import { type AkaVeranstaltungId, type Raum, type Zeitraum } from '../../../../../dtos';
import { CalendarRaumEvent } from '../CalendarEvent';
import { calendarResourceTypes } from '../calendarResourceTypes';
import { AbstractRaumResource } from './AbstractRaumResource';
import { endOfDay, isWithinInterval, startOfDay, subDays } from 'date-fns';

type GruppenRaumBlockungWithEventZeitraum = Omit<Raum['raumBlockungen'][number], 'zeitraeume'> & {
  blockungId: number;
  eventZeitraum: {
    start: Date;
    end: Date;
  };
};

/**
 * Represents one Gruppen-Raum, and one row in the calendar.
 */
export class CalendarGruppenraumResource extends AbstractRaumResource {
  public readonly type = calendarResourceTypes.GRUPPENRAUM;

  public readonly id: `gr-${string}`;

  public readonly name: string;

  public constructor(raum: Raum) {
    super(raum);
    this.id = `gr-${raum.raumSapId}`;
    this.name = `${raum.kuerzel} / ${raum.name}`;
  }

  // this is necessary because gruppenraeume do not have to be blocked for consecutive days
  #getBlockungenWithConsecutiveDays(): GruppenRaumBlockungWithEventZeitraum[] {
    const raumBlockungenWithEventZeitraum: GruppenRaumBlockungWithEventZeitraum[] = [];

    for (let blockungsIndex = 0; blockungsIndex < this.raum.raumBlockungen.length; blockungsIndex++) {
      const blockung = this.raum.raumBlockungen[blockungsIndex];
      const sortedBlockungsTage = blockung.zeitraeume.sort((tag1, tag2) => tag1.start.getTime() - tag2.start.getTime());

      for (const blockungsTag of sortedBlockungsTage) {
        const preExistingRaumBlockungenWithEventZeitraum = raumBlockungenWithEventZeitraum.filter(
          (raumBlockungWithEventZeitraum) => raumBlockungWithEventZeitraum.blockungId === blockungsIndex,
        );

        if (preExistingRaumBlockungenWithEventZeitraum.length !== 0) {
          const previousDay = subDays(blockungsTag.start, 1);
          const preExistingRaumBlockungWithEventZeitraum = preExistingRaumBlockungenWithEventZeitraum.find((preExistingRaumBlockung) =>
            isWithinInterval(previousDay, preExistingRaumBlockung.eventZeitraum),
          );

          if (typeof preExistingRaumBlockungWithEventZeitraum !== 'undefined') {
            preExistingRaumBlockungWithEventZeitraum.eventZeitraum.end = endOfDay(blockungsTag.end);
            continue;
          }
        }

        raumBlockungenWithEventZeitraum.push({
          ...blockung,
          blockungId: blockungsIndex,
          eventZeitraum: {
            start: startOfDay(blockungsTag.start),
            end: endOfDay(blockungsTag.end),
          },
        });
      }
    }

    return raumBlockungenWithEventZeitraum;
  }

  public convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarRaumEvent[] {
    const events: CalendarRaumEvent[] = [];

    const raumBlockungenWithEventZeitraum = this.#getBlockungenWithConsecutiveDays();

    for (const blockung of raumBlockungenWithEventZeitraum) {
      const newEvent = new CalendarRaumEvent(blockung.eventZeitraum.start, blockung.eventZeitraum.end, this, {
        ortKuerzel: this.raum.ort.kuerzel,
        quelleTerminId: blockung.akaVeranstaltungId,
        veranstaltung: blockung.veranstaltung,
        zeitraeume: [blockung.eventZeitraum],
      });
      newEvent.isOldSelection = blockung.akaVeranstaltungId === akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  protected computeNewCalendarEventBounds(date: Date): Zeitraum {
    const start = startOfDay(date);
    const end = endOfDay(date);

    return { start, end };
  }

  public isEventCompatibleWithResource(): boolean {
    return true;
  }
}
