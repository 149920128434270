import colorPalette from '../../../theme/colorPalette';
import { taskCardContent } from './ThemaTaskCard.content';
import { OpenInNewRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router';

type ThemaTaskCardHeadingProps = {
  readonly uri: string;
};

export const ThemaTaskCardHeading: React.FC<ThemaTaskCardHeadingProps> = (props: ThemaTaskCardHeadingProps) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Typography variant="h6">{taskCardContent.heading}</Typography>
    <Link target="_blank" rel="noopener" to={props.uri} onClick={(e) => e.stopPropagation()}>
      <OpenInNewRounded sx={{ ':hover': { color: colorPalette.primary.main } }} />
    </Link>
  </Stack>
);
