import {
  AccordionWithHeading,
  ContentLoading,
  Droppable,
  EasyBreadcrumbs,
  type EasyBreadcrumbsItem,
  NoContentInfo,
  TerminGenerellerKommentarCard,
  TerminPlanungsinformationenCard,
  ThemaHeader,
} from '../../components/general';
import { type GetPlanungsinformationOutput } from '../../dtos';
import { useNormalizedParams } from '../../hooks/useNormalizedParams';
import { trpc } from '../../trpc';
import { TerminAnsprechpartnerCard } from './TerminAnsprechpartnerCard';
import { TerminEigenschaftenCard } from './TerminEigenschaftenCard';
import { TerminExpertepoolCard } from './TerminExpertepoolCard';
import { TerminPlanungsstatusCard } from './TerminPlanungsstatusCard';
import { TerminZeitlicherAblaufCard } from './TerminZeitlicherAblaufCard';
import { ThemaTasksCard } from './ThemaTasksCard/ThemaTasksCard';
import { VeranstaltungListing } from './VeranstaltungListing';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid2, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

export const ThemaDetailsPage: React.FC = () => {
  const navigate = useNavigate();

  const { akaProduktId } = useNormalizedParams();

  if (akaProduktId === undefined) {
    throw new TypeError('Die Aka-Produkt-Id fehlt in der URL.');
  }

  const themaQuery = trpc.thema.getThemaById.useQuery({ akaProduktId });
  const planungsinformationQuery = trpc.planung.getPlanungsinformation.useQuery<GetPlanungsinformationOutput>({ akaProduktId });

  if (themaQuery.error && themaQuery.error.data?.code !== 'NOT_FOUND') {
    throw new Error('Fehler beim Laden des Themas.', { cause: themaQuery.error });
  }

  if (planungsinformationQuery.error) {
    throw new Error('Fehler beim Laden der Planungsinformationen für das Thema.', { cause: themaQuery.error });
  }

  if (themaQuery.isLoading || planungsinformationQuery.isLoading) {
    return <ContentLoading />;
  }

  const thema = themaQuery.data?.thema;

  if (!thema) {
    return <NoContentInfo text="Kein Thema gefunden" />;
  }

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      label: 'Thema',
      href: `/thema/${akaProduktId}`,
    },
  ];

  return (
    <Stack sx={{ paddingTop: 2 }} spacing={2}>
      <EasyBreadcrumbs items={breadcrumbs} />
      <ThemaHeader thema={thema} />
      <Box>
        <Button onClick={async () => await navigate(`/thema/${akaProduktId}/veranstaltung/neu/planung`)} variant="contained">
          <Add /> Veranstaltung anlegen
        </Button>
      </Box>
      <Grid2>
        <ThemaTasksCard akaProduktId={thema.akaProduktId} />
      </Grid2>
      <Grid2 container spacing={2} sx={{ paddingTop: -2 }}>
        <Grid2 size={{ xl: 4, md: 4 }} sx={{ paddingRight: 2, paddingTop: 2 }}>
          <Droppable id="left">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminPlanungsinformationenCard planungsinformationQuery={planungsinformationQuery} />
              <TerminGenerellerKommentarCard planungsinformationQuery={planungsinformationQuery} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xl: 4, md: 4 }} sx={{ paddingRight: 2, paddingTop: 2 }}>
          <Droppable id="mid">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminPlanungsstatusCard planungsinformationQuery={planungsinformationQuery} themaId={thema.id} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xl: 4, md: 4 }} sx={{ paddingTop: 2 }}>
          <Droppable id="right">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminAnsprechpartnerCard planungsinformationQuery={planungsinformationQuery} />
              <TerminZeitlicherAblaufCard planungsinformationQuery={planungsinformationQuery} />
              <TerminEigenschaftenCard thema={thema} />
            </Stack>
          </Droppable>
        </Grid2>
      </Grid2>
      <Grid2>
        <TerminExpertepoolCard planungsinformationQuery={planungsinformationQuery} />
      </Grid2>
      <AccordionWithHeading heading="Veranstaltungsübersicht">
        <VeranstaltungListing themaId={thema.produktSapId} />
      </AccordionWithHeading>
    </Stack>
  );
};
