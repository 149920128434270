import { content } from './StartButton.content';
import { Button, Tooltip } from '@mui/material';
import { useMemo } from 'react';

type StartButtonProps = {
  readonly buchungsnummer: string | null;
  readonly produktSapId: number | null;
  readonly startDate: Date | null;
  readonly endDate: Date | null;
  readonly isProcessing: boolean;
};

const useSaveButtonState = (
  buchungsnummer: string | null,
  produktSapId: number | null,
  startDate: Date | null,
  endDate: Date | null,
  isProcessing: boolean,
): {
  isSaveButtonDisabled: boolean;
  displaySaveTooltip: string | null;
} =>
  useMemo(() => {
    const buchungsnummerExists = typeof buchungsnummer === 'string' && buchungsnummer.length > 0;
    const produktSapIdExists = typeof produktSapId === 'number';
    const buchungsnummerAndProduktSapIdExist = buchungsnummerExists && produktSapIdExists;

    const startDateExists = startDate !== null;
    const endDateExists = endDate !== null;
    const endDateBeforeStartDate = startDateExists && endDateExists && startDate > endDate;

    if (buchungsnummerAndProduktSapIdExist) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.buchungsnummerAndProduktSapIdExist };
    }

    if (!startDateExists) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.missingStartDate };
    }

    if (!endDateExists) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.missingEndDate };
    }

    if (endDateBeforeStartDate) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.endDateBeforeStartDate };
    }

    if (isProcessing) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.processing };
    }

    return { isSaveButtonDisabled: false, displaySaveTooltip: null };
  }, [buchungsnummer, endDate, isProcessing, produktSapId, startDate]);

export const StartButton: React.FC<StartButtonProps> = ({ buchungsnummer, produktSapId, startDate, endDate, isProcessing }: StartButtonProps) => {
  const { isSaveButtonDisabled, displaySaveTooltip } = useSaveButtonState(buchungsnummer, produktSapId, startDate, endDate, isProcessing);

  return (
    <Tooltip sx={{ width: '20%' }} title={displaySaveTooltip} data-testid="saveButtonTooltip">
      <div>
        <Button sx={{ height: '100%' }} variant="outlined" disabled={isSaveButtonDisabled} type="submit">
          {content.start}
        </Button>
      </div>
    </Tooltip>
  );
};
