import { type GetPlanungsinformationOutput } from '../../../dtos';
import { type TerminProps } from '../../../utils';
import { AccordionWithHeading } from '../AccordionWithHeading';
import { content } from './TerminGenerellerKommentar.content';
import { Draggable } from '@mobiscroll/react';

export const TerminGenerellerKommentarCard: React.FC<TerminProps> = (props: TerminProps) => {
  const { planungsinformationQuery } = props;

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    // don't show anything while loading, because the whole accordion will not be shown if there is no genereller Kommentar
    return null;
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  if (planungsinformation.generellerKommentar === null) {
    return null;
  }

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>{planungsinformation.generellerKommentar}</AccordionWithHeading>
    </Draggable>
  );
};
