/**
 * Creates a function that filters an array of objects by ensuring each object is unique
 * based on a specific key. The resulting function can be used as a predicate for `Array.prototype.filter`.
 *
 * @example
 * const data = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 3, name: 'Alice' }
 * ];
 *
 * const uniqueData = data.filter(uniqueBy('name'));
 * // uniqueData is now:
 * // [
 * //   { id: 1, name: 'Alice' },
 * //   { id: 2, name: 'Bob' },
 * // ]
 */
export const uniqueBy =
  <TObj extends object, TKey extends keyof TObj>(key: TKey) =>
  (value: TObj, index: number, array: TObj[]) =>
    array.map((element) => element[key]).indexOf(value[key]) === index;
