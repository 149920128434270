export const content = {
  columnLabelsOuter: {
    verknuepfungBlopUndPraesenzseminar: 'Verknüpfung (B)LOP',
    akaProduktId: 'AKA-Produkt-ID',
  },
  columnLabelsInner: {
    trainer: 'Trainer:in',
    blockungsablauf: 'Blockungsablauf',
    terminanfrageStatus: 'Terminanfrage-Status',
    terminanfrageAnfrageDatum: 'Terminanfrage-Anfragedatum',
  },
  tooltips: {
    zuHiveWechseln: 'Zu Hive wechseln',
  },
} as const;
