import { type z, type ZodSchema } from 'zod';

type Storage = {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
};

type EnsureString<K> = K extends string ? K : never;

export const initStorageAPI = <S extends { [K in keyof S]: ZodSchema<{ key: K; value: unknown }> }>(storage: Storage, storageSchema: S) => {
  const setItem = <K extends keyof S>(key: EnsureString<K>, value: z.input<S[K]>['value']) => {
    storageSchema[key].parse({ key, value });

    storage.setItem(key, JSON.stringify(value));
  };

  const getItem = <K extends keyof S>(key: EnsureString<K>): z.output<S[K]>['value'] | null => {
    const valueFromStorage = storage.getItem(key);

    if (valueFromStorage === null) {
      return null;
    }

    try {
      return storageSchema[key].parse({ key, value: JSON.parse(valueFromStorage) }).value;
    } catch (error) {
      console.error(`Error parsing value for key "${key}":`, error);
      return null;
    }
  };

  const removeItem = <K extends keyof S>(key: EnsureString<K>): void => {
    storage.removeItem(key);
  };

  return {
    set: setItem,
    get: getItem,
    remove: removeItem,
  };
};
