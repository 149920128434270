import { type Ablauf, type AkaVeranstaltungId, type SapStatus, type VivaStatus } from '../../dtos';
import {
  content,
  type DataGridColDef,
  determineStatusText,
  formatDate,
  getExperteNames,
  getOrtKuerzel,
  getOrtName,
  getRegionName,
  getStandortName,
  getVeranstaltungEnd,
  getVeranstaltungStart,
  printWithNoInfo,
} from '../../utils';
import { LinkWithCopyButton } from './LinkWithCopyButton';
import { VaStatusChip } from './VaStatusChip';

export const akaVeranstaltungIdColumn = <T extends { akaVeranstaltungId: AkaVeranstaltungId }>(): DataGridColDef<T>[number] => ({
  field: 'akaVeranstaltungId',
  flex: 0.7,
  headerName: content.columnLabels.akaVeranstaltungId,
  renderCell: (params): JSX.Element => (
    <LinkWithCopyButton text={params.row.akaVeranstaltungId} to={`/veranstaltung/${params.row.akaVeranstaltungId}`} tooltip={content.tooltips.zurVeranstaltungWechseln} />
  ),
});

export const veranstaltungSapIdColumn = <T extends { veranstaltungSapId: number | null }>(): DataGridColDef<T>[number] => ({
  field: 'veranstaltungSapId',
  flex: 0.5,
  headerName: content.columnLabels.veranstaltungSapId,
  renderCell: (params): string => printWithNoInfo(params.row.veranstaltungSapId),
});

export const titelColumn = <T extends { titel: string } | { veranstaltungTitel: string }>(): DataGridColDef<T>[number] => ({
  field: 'titel',
  flex: 1,
  headerName: content.columnLabels.titel,
  valueGetter: (_value, row) => printWithNoInfo('titel' in row ? row.titel : row.veranstaltungTitel),
});

export const startColumn = <T extends { ablauf: Ablauf }>(): DataGridColDef<T>[number] => ({
  field: 'start',
  flex: 0.5,
  headerName: content.columnLabels.start,
  renderCell: (params) => formatDate(getVeranstaltungStart(params.row)),
  valueGetter: (_value, row) => getVeranstaltungStart(row),
});

export const endColumn = <T extends { ablauf: Ablauf }>(): DataGridColDef<T>[number] => ({
  field: 'end',
  flex: 0.5,
  headerName: content.columnLabels.end,
  renderCell: (params): string => formatDate(getVeranstaltungEnd(params.row)),
  valueGetter: (_value, row): Date => getVeranstaltungEnd(row),
});

export const statusColumn = <T extends { vivaStatus: VivaStatus; sapStatus: SapStatus | null }>(): DataGridColDef<T>[number] => ({
  field: 'status',
  flex: 0.5,
  headerName: content.columnLabels.status,
  renderCell: (params): JSX.Element => <VaStatusChip sapStatus={params.row.sapStatus} vivaStatus={params.row.vivaStatus} />,
  valueGetter: (_value, row): string => determineStatusText(row.sapStatus, row.vivaStatus),
});

export const ortKuerzelColumn = <T extends Parameters<typeof getOrtKuerzel>[0]>(): DataGridColDef<T>[number] => ({
  field: 'ortKuerzel',
  flex: 0.5,
  headerName: content.columnLabels.ortKuerzel,
  valueGetter: (_vlaue, row): string => printWithNoInfo(getOrtKuerzel(row)),
});

export const standortNameColumn = <T extends Parameters<typeof getStandortName>[0]>(): DataGridColDef<T>[number] => ({
  field: 'standortName',
  flex: 0.5,
  headerName: content.columnLabels.standortName,
  valueGetter: (_rawValue, row) => printWithNoInfo(getStandortName(row)),
});

export const ortNameColumn = <T extends Parameters<typeof getOrtName>[0]>(): DataGridColDef<T>[number] => ({
  field: 'ortName',
  flex: 0.7,
  headerName: content.columnLabels.ortName,
  valueGetter: (_value, row) => printWithNoInfo(getOrtName(row)),
});

export const regionNameColumn = <T extends Parameters<typeof getRegionName>[0]>(): DataGridColDef<T>[number] => ({
  field: 'regionName',
  flex: 1,
  headerName: content.columnLabels.regionName,
  valueGetter: (_value, row): string => printWithNoInfo(getRegionName(row)),
});

export const expertsColumn = <T extends Parameters<typeof getExperteNames>[0]>(): DataGridColDef<T>[number] => ({
  field: 'experts',
  flex: 1,
  headerName: content.columnLabels.experten,
  valueGetter: (_value, row): string => printWithNoInfo(getExperteNames(row)),
});

export const buchungsnummerColumn = <T extends { buchungsnummer: string; akaProduktId: string }>(): DataGridColDef<T>[number] => ({
  field: 'buchungsnummer',
  flex: 0.5,
  headerName: content.columnLabels.buchungsnummer,
  renderCell: (params) => <LinkWithCopyButton text={params.value} to={`/thema/${params.row.akaProduktId}`} tooltip={content.tooltips.zumThemaWechseln} />,
});
