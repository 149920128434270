import { ContentLoading, Droppable, EasyBreadcrumbs, NoContentInfo, ThemaHeader } from '../../components/general';
import { type ThemaFromThemaRouter, type Veranstaltung } from '../../dtos';
import { useNormalizedParams } from '../../hooks/useNormalizedParams';
import { trpc } from '../../trpc';
import { TerminAblaufCard } from './TerminAblaufCard';
import { TerminExperteCard } from './TerminExperteCard';
import { TerminInfoCard } from './TerminInfoCard';
import { TerminOrtCard } from './TerminOrtCard';
import { TerminWarningsCard } from './TerminWarningsCard';
import { VeranstaltungEigenschaftenCard } from './VeranstaltungEigenschaftenCard';
import { Grid2, Stack } from '@mui/material';

export const VeranstaltungDetailsPage: React.FC = () => {
  const { akaVeranstaltungId } = useNormalizedParams();

  if (typeof akaVeranstaltungId === 'undefined') {
    throw new TypeError('Die Veranstaltungs-Id fehlt in der URL.');
  }

  const isAkaVeranstaltungVivaId = akaVeranstaltungId.startsWith('V') || akaVeranstaltungId.startsWith('E');

  const veranstaltungQuery = trpc.veranstaltung.getVeranstaltungByAkaVeranstaltungId.useQuery({ akaVeranstaltungId }, { enabled: isAkaVeranstaltungVivaId });

  const akaProduktId = veranstaltungQuery.data?.thema.akaProduktId;
  const themaQuery = trpc.thema.getThemaById.useQuery({ akaProduktId: akaProduktId as string }, { enabled: typeof akaProduktId === 'string' && isAkaVeranstaltungVivaId });

  if (veranstaltungQuery.error && veranstaltungQuery.error.data?.code === 'UNPROCESSABLE_CONTENT') {
    return <NoContentInfo text="Invalide Aka Veranstaltung Id" />;
  }

  if (veranstaltungQuery.error && veranstaltungQuery.error.data?.code !== 'NOT_FOUND') {
    throw new Error('Fehler beim Laden der Veranstaltung.', { cause: veranstaltungQuery.error });
  }

  if (themaQuery.error && themaQuery.error.data?.code !== 'NOT_FOUND') {
    throw new Error('Fehler beim Laden des Themas.', { cause: themaQuery.error });
  }

  if (themaQuery.isLoading || veranstaltungQuery.isLoading) {
    return <ContentLoading />;
  }

  const veranstaltung: Veranstaltung | undefined = veranstaltungQuery.data;
  if (!veranstaltung) {
    return <NoContentInfo text="Keine Veranstaltung gefunden." />;
  }

  const thema: ThemaFromThemaRouter | undefined = themaQuery.data?.thema;

  if (!thema) {
    return <NoContentInfo text="Kein Thema gefunden." />;
  }

  return (
    <Stack padding={2} spacing={2}>
      <EasyBreadcrumbs
        items={[
          { label: 'Dashboard', href: '/' },
          { label: 'Suche', href: '/' },
          { label: 'Thema', href: `/thema/${thema.akaProduktId}` },
          { label: 'Veranstaltung', href: '' },
        ]}
      />
      <ThemaHeader thema={thema} />
      <TerminInfoCard veranstaltung={veranstaltung} />
      <TerminWarningsCard veranstaltungId={veranstaltung.id} />
      <Grid2 container spacing={2} sx={{ minHeight: '50vh' }}>
        <Grid2 size={{ xs: 12, xl: 4, md: 6 }}>
          <Droppable id="left">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminExperteCard key="experte" veranstaltung={veranstaltung} thema={thema} />
              <TerminAblaufCard key="ablauf" veranstaltung={veranstaltung} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xs: 12, xl: 4, md: 6 }}>
          <Droppable id="mid">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminOrtCard key="ort" thema={thema} veranstaltung={veranstaltung} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xs: 12, xl: 4, md: 6 }}>
          <Droppable id="right">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <VeranstaltungEigenschaftenCard key="eigenschaften" veranstaltung={veranstaltung} />
            </Stack>
          </Droppable>
        </Grid2>
      </Grid2>
    </Stack>
  );
};
