import { useDebounce } from '../../hooks/useDebounce';
import { type SearchOption, searchOption } from './SearchOption.types';
import { SearchOptionSelector } from './SearchOptionSelector';
import { ThemaSearch } from './ThemaSearch';
import { VeranstaltungSearch } from './VeranstaltungSearch';
import { Stack, TextField } from '@mui/material';
import { type KeyboardEvent, useState } from 'react';

export const SearchPage: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const searchTerm = useDebounce(inputValue).trim();
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOption>(searchOption.THEMA);

  const handleSelectedSearchOptionChange = (changedSearchOption: SearchOption): void => {
    setSelectedSearchOption(changedSearchOption);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      if (selectedSearchOption === searchOption.VERANSTALTUNG) {
        setSelectedSearchOption(searchOption.THEMA);
      } else {
        setSelectedSearchOption(searchOption.VERANSTALTUNG);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <Stack alignItems="center" direction="column" sx={{ marginTop: 10, height: '20vh' }}>
        <SearchOptionSelector selectedSearchOption={selectedSearchOption} onChange={handleSelectedSearchOptionChange} />
        <TextField
          label="Suche"
          autoFocus
          value={inputValue}
          onChange={handleChange}
          slotProps={{ input: { onKeyDown: handleKeyDown } }}
          sx={{ width: '50vw' }}
          variant="outlined"
        />
      </Stack>

      {selectedSearchOption === searchOption.VERANSTALTUNG && <VeranstaltungSearch searchTerm={searchTerm} />}
      {selectedSearchOption === searchOption.THEMA && <ThemaSearch searchTerm={searchTerm} />}
    </div>
  );
};
