import { type StatusChangeResult } from '../../dtos';
import { generateFailureReasonList, type SnackbarGenerator } from './utils';
import { Box } from '@mui/material';

export const generateVeranstaltungEigenschaftenCardSuccessSnackbar: SnackbarGenerator<StatusChangeResult> = (veranstaltung) => ({
  snackbarNode: (
    <Box>
      <p>Update von Veranstaltung {veranstaltung.akaVeranstaltungId} erfolgreich. </p>
      <ul>
        {veranstaltung.successMessages.map((msg) => (
          <li key={veranstaltung.akaVeranstaltungId}>{msg}</li>
        ))}
      </ul>
    </Box>
  ),
  options: { variant: 'success' },
});

export const generateVeranstaltungEigenschaftenCardErrorSnackbar: SnackbarGenerator<StatusChangeResult> = (veranstaltung) => ({
  snackbarNode: (
    <Box>
      <p>Die Status für Veranstaltung {veranstaltung.akaVeranstaltungId} konnte nicht geändert werden. </p>
      <ul>{generateFailureReasonList(veranstaltung)}</ul>
    </Box>
  ),
  options: { variant: 'error' },
});

export { backendErrorMessage as veranstaltungEigenschaftenCardBackendErrorMessage } from './utils';
