import { copyToClipboard } from '../../utils';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { type MouseEvent as ReactMouseEvent, useState } from 'react';
import { z } from 'zod';

const tooltips = {
  COPY: 'Klicken zum Kopieren',
  SUCCESS: 'Kopiert',
};

const tooltipSchema = z.nativeEnum(tooltips);
type TooltipState = z.infer<typeof tooltipSchema>;

const styles = {
  button: {
    marginTop: '-8px',
    marginBottom: '-8px',
    marginLeft: '-4px',
  },
  buttonIcon: {
    fontSize: '1rem',
  },
};

type CopyProps = {
  readonly text: string;
};

export const CopyButton: React.FC<CopyProps> = ({ text }: CopyProps) => {
  const [tooltipTitle, setTooltipTitle] = useState<TooltipState>(tooltips.COPY);

  const clickHandler = (event: ReactMouseEvent): void => {
    event.stopPropagation();
    void copyToClipboard(text);
    setTooltipTitle(tooltips.SUCCESS);
  };

  const openHandler = (): void => {
    setTooltipTitle(tooltips.COPY);
  };

  return (
    <Tooltip title={tooltipTitle} onOpen={openHandler}>
      <IconButton sx={styles.button} onClick={clickHandler} color="primary">
        <ContentCopy sx={styles.buttonIcon} />
      </IconButton>
    </Tooltip>
  );
};
