import { addDays, eachDayOfInterval, isWeekend } from 'date-fns';
import { z } from 'zod';

export const isBeforeMinDate = (passedDate: Date | null, minDate?: Date): boolean => {
  if (passedDate === null || typeof minDate === 'undefined') {
    return false;
  }

  return passedDate < minDate;
};

export type ExcludeWeekendConfig =
  | {
      ablaufTageCount: number;
    }
  | undefined;

export const isForbiddenWeekendDate = (passedDate: Date | null, excludeWeekendConfig: ExcludeWeekendConfig): boolean => {
  if (passedDate === null || typeof excludeWeekendConfig === 'undefined') {
    return false;
  }

  return eachDayOfInterval({ start: passedDate, end: addDays(passedDate, excludeWeekendConfig.ablaufTageCount - 1) }).some((day) => isWeekend(day));
};

/**
 * I know what you're thinking.
 * "Why not just check if that date isn't null? I am going to change this back, this is so dumb."
 *
 * Because I looked like an idiot in a sprint review with that thinking, that's why.
 *
 * Apparently, an "Invalid Date" object, as returned by MUI Datepickers, IS OF TYPE DATE, so typescript doesn't catch it.
 * But of course, the very first time zod is getting involved (at the latest when it's sent to the BFF), it's error-throws galore.
 *
 * So, yeah. Do NOT replace this by "date === null".
 *
 * @param potentiallyInvalidDatePickerDate
 * @returns the goddamn truth
 */
export const isNull = (potentiallyInvalidDatePickerDate: Date | null): potentiallyInvalidDatePickerDate is null => !z.date().safeParse(potentiallyInvalidDatePickerDate).success;
