import { StatusTerminanfrageHive } from '../../../components/general';
import { type ExperteBlockung } from '../../../dtos';
import { formatDate } from '../../../utils';
import { Box, Tooltip } from '@mui/material';
import { type ReactElement } from 'react';

type TerminanfrageCellProps = {
  readonly type: 'status' | 'anfragedatum';
  readonly blockung: ExperteBlockung | undefined;
};

export const TerminanfrageCell: React.FC<TerminanfrageCellProps> = ({ type, blockung }: TerminanfrageCellProps) => {
  let display: ReactElement | string = '-';
  let tooltip = 'Es gibt keine eindeutige Terminanfrage für diese Veranstaltung. Bitte prüfe auf der Veranstaltungsdetailseite die Blockungs und Terminanfragedetails.';
  if (blockung) {
    tooltip = '';
    if (type === 'status') {
      display = <StatusTerminanfrageHive experteBlockung={blockung} />;
    } else {
      display = formatDate(blockung.hiveExperteAnfragedatum);
    }
  }

  return (
    <Tooltip title={tooltip}>
      <Box>{display}</Box>
    </Tooltip>
  );
};
