import { type CalendarResource, isExperteResource, isGruppenraumResource, isStandardRaumResource, isStandortResource } from '../helperClasses';
import { content } from './ResourceTooltip.content';
import { Stack, Typography } from '@mui/material';

type ResourceTooltipProps = {
  readonly resource: CalendarResource;
  readonly year: number;
};

export const ResourceTooltip: React.FC<ResourceTooltipProps> = ({ resource, year }: ResourceTooltipProps) => {
  if (isStandardRaumResource(resource)) {
    let printGruppenraumAmOrt;

    if (resource.raum.ort.hatGruppenraum === null) {
      printGruppenraumAmOrt = content.NO_INFO_AVAILABLE;
    } else {
      printGruppenraumAmOrt = resource.raum.ort.hatGruppenraum ? content.VORHANDEN : content.NICHT_VORHANDEN;
    }

    return (
      <Stack>
        <Typography variant="caption">
          {content.REGION} {resource.raum.ort.region?.name ?? content.NO_INFO_AVAILABLE}
        </Typography>
        <Typography variant="caption">
          {content.STANDORT} {resource.raum.ort.standort?.name ?? content.NO_INFO_AVAILABLE}
        </Typography>
        <Typography variant="caption">
          {content.ORT} {resource.raum.ort.name}
        </Typography>
        <Typography variant="caption">
          {content.GRUPPENRAUM_AM_ORT} {printGruppenraumAmOrt}
        </Typography>
        <Typography variant="caption">{content.KAPAZITAET}</Typography>
        <Typography variant="caption">
          {content.U_FORM} {resource.raum.kapazitaetUForm ?? content.NO_INFO_AVAILABLE}
        </Typography>
        <Typography variant="caption">
          {content.PARLAMENTARISCH} {resource.raum.kapazitaetParlamentarisch ?? content.NO_INFO_AVAILABLE}
        </Typography>
        <Typography variant="caption">
          {content.OHNE_TISCH} {resource.raum.kapazitaetOhneTische ?? content.NO_INFO_AVAILABLE}
        </Typography>
      </Stack>
    );
  }

  if (isGruppenraumResource(resource)) {
    return (
      <Stack>
        <Typography variant="caption">
          {content.REGION} {resource.raum.ort.region?.name ?? content.NO_INFO_AVAILABLE}
        </Typography>
        <Typography variant="caption">
          {content.STANDORT} {resource.raum.ort.standort?.name ?? content.NO_INFO_AVAILABLE}
        </Typography>
        <Typography variant="caption">
          {content.ORT} {resource.raum.ort.name}
        </Typography>
      </Stack>
    );
  }

  if (isStandortResource(resource)) {
    return (
      <Typography variant="caption">
        {content.STANDORT} {resource.standort.name}
        <br />
      </Typography>
    );
  }

  if (isExperteResource(resource)) {
    const workloadSelectedYear = resource.experte.experteAuslastungen.find((wl) => wl.jahr === year);
    return (
      <Stack>
        {workloadSelectedYear ? (
          <>
            <Typography variant="caption">
              {content.JAHR} {workloadSelectedYear.jahr}
            </Typography>
            <Typography variant="caption">
              {content.GEBUCHTE_TAGE} {Math.round(workloadSelectedYear.gebuchteTage)}
            </Typography>
            <Typography variant="caption">
              {content.VORGEMERKTE_TAGE} {Math.round(workloadSelectedYear.vorgemerkteTage)}
            </Typography>
            <Typography variant="caption">
              {content.SUMME_TAGE} {Math.round(workloadSelectedYear.summeTage)}
            </Typography>
            <Typography variant="caption">
              {content.SCHWELLE_GELB} {workloadSelectedYear.schwelleGelb}
            </Typography>
            <Typography variant="caption">
              {content.SCHWELLE_ROT} {workloadSelectedYear.schwelleRot}
            </Typography>
          </>
        ) : (
          <Typography variant="caption">{content.KEINE_AUSLASTUNGSDATEN}</Typography>
        )}
      </Stack>
    );
  }

  return undefined;
};
