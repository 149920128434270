import { AccordionWithHeading, ContentLoading, Tablelike, type TablelikeRow } from '../../../components/general';
import { type GetPlanungsinformationOutput } from '../../../dtos';
import { type TerminProps } from '../../../utils';
import { content } from './TerminZeitlicherAblaufCard.content';
import { printZusatzinfo } from './TerminZeitlicherAblaufCard.utils';
import { Draggable } from '@mobiscroll/react';
import { Stack, Typography } from '@mui/material';

type PrintAblaufProps = {
  readonly planungsinformation: GetPlanungsinformationOutput;
};

const PrintAblauf: React.FC<PrintAblaufProps> = ({ planungsinformation }: PrintAblaufProps) => {
  if (planungsinformation.ablaufTageTemplate === null) {
    return content.noInfoAvailable;
  }

  return (
    <>
      {planungsinformation.ablaufTageTemplate.map((ablaufTagTemplate) => (
        <Typography key={ablaufTagTemplate.tagNr + ablaufTagTemplate.startZeit}>
          {content.units.tag + ' ' + ablaufTagTemplate.tagNr + ': ' + ablaufTagTemplate.startZeit + ' - ' + ablaufTagTemplate.endZeit}
        </Typography>
      ))}
    </>
  );
};

export const TerminZeitlicherAblaufCard: React.FC<TerminProps> = (props: TerminProps) => {
  const { planungsinformationQuery } = props;

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    return (
      <Draggable id={content.draggableId}>
        <AccordionWithHeading heading={content.heading}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  const printTable: TablelikeRow[] = [[content.fields.ablauf, <PrintAblauf key="ablauf" planungsinformation={planungsinformation} />]];

  if (planungsinformation.zusatzinfoAblauf !== null) {
    printTable.push([content.fields.zusatzinfo, printZusatzinfo(planungsinformation)]);
  }

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack sx={{ paddingTop: 2 }}>
          <Tablelike xsLeft={6} rows={printTable} />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
