import { LinkWithCopyButton } from '../../../components/general';
import { type TaskListItem, type TaskStatus, taskStatus, taskTypes } from '../../../dtos';
import { type DataGridColDef } from '../../../utils';
import { printWithNoInfo } from '../../../utils/content';
import { taskCardContent } from './ThemaTaskCard.content';
import { Chip } from '@mui/material';
import { v4 as uuid4 } from 'uuid';

const taskStatusColors = {
  [taskStatus.ANGELEGT]: 'primary',
  [taskStatus.IN_ARBEIT]: 'primary',
  [taskStatus.ABGESCHLOSSEN]: 'success',
  [taskStatus.WARTEND]: 'primary',
} as const satisfies Record<TaskStatus, 'warning' | 'primary' | 'success'>;

const statusValue = {
  [taskStatus.ANGELEGT]: 0,
  [taskStatus.IN_ARBEIT]: 1,
  [taskStatus.WARTEND]: 2,
  [taskStatus.ABGESCHLOSSEN]: 3,
} as const satisfies Record<TaskStatus, number>;

export const taskColumns = [
  {
    field: 'titel',
    headerName: taskCardContent.columnLabels.titel,
    flex: 1,
    valueGetter: (_value, row): string => (row.taskType === taskTypes.MANUELLER_TASK ? `Manuell: ${row.titel}` : row.taskType),
    renderCell: ({ row, value }): JSX.Element => <LinkWithCopyButton text={value} to={row.uri} tooltip="zum Ticket" />,
  },
  {
    field: 'taskStatus',
    headerName: taskCardContent.columnLabels.taskStatus,
    flex: 1,
    valueGetter: (_value, row): string => row.taskStatus,
    renderCell: ({ row }): JSX.Element => <Chip label={row.taskStatus} color={taskStatusColors[row.taskStatus]} />,
    sortComparator: (a: TaskStatus, b: TaskStatus): number => statusValue[a] - statusValue[b],
  },
  {
    field: 'labels',
    headerName: taskCardContent.columnLabels.labels,
    flex: 1,
    renderCell: ({ row }): JSX.Element[] => row.labels.map((label) => <Chip key={uuid4()} label={label} sx={{ margin: '0.1rem' }} />),
  },
  { field: 'bearbeiter', headerName: taskCardContent.columnLabels.bearbeiter, flex: 1, valueGetter: (_value, row): string => printWithNoInfo(row.bearbeiter) },
] as const satisfies DataGridColDef<TaskListItem>;

export type TableFields = (typeof taskColumns)[number]['field'];
