import { type VivaUserScope, vivaUserScopes } from '../../dtos';
import { sessionStorageApi, sessionStorageKeys } from '../../utils';
import { type AccountInfo } from '@azure/msal-browser';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, type SelectChangeEvent } from '@mui/material';

type AdminScopeSelectProps = {
  readonly selectedScopes: VivaUserScope[];
  readonly setSelectedScopes: React.Dispatch<React.SetStateAction<VivaUserScope[]>>;
  readonly account: AccountInfo | null;
};

export const AdminScopeSelect: React.FC<AdminScopeSelectProps> = ({ selectedScopes, setSelectedScopes, account }: AdminScopeSelectProps) => {
  const permissionScopeToTextMap: { [key: string]: string } = {
    'planung:edit': 'Planung bearbeiten',
    'thema:edit': 'Thema bearbeiten',
    'system:admin': 'System administrieren',
  };

  const handleScopeChange = (event: SelectChangeEvent<VivaUserScope[]>): void => {
    const scopes = event.target.value;

    if (typeof scopes === 'string') {
      throw new TypeError('[Header] Habe Array erwartet, aber string bekommen.');
    }

    setSelectedScopes(scopes);
    sessionStorageApi.set(sessionStorageKeys.X_VIVA_ADMIN_SCOPES, scopes);
  };

  if (account?.idTokenClaims?.roles?.includes('Admin')) {
    return (
      <FormControl size="small" sx={{ width: '130px', pb: 2 }} variant="standard">
        <InputLabel id="admin-role-scope-select-label">Admin-Rechte</InputLabel>
        <Select
          id="admin-role-scope-select"
          labelId="admin-role-scope-select-label"
          value={selectedScopes}
          onChange={handleScopeChange}
          renderValue={(selected) => `${selected.length} ausgewählt`}
          multiple
        >
          {Object.values(vivaUserScopes).map((scope) => (
            <MenuItem key={scope} value={scope} sx={{ height: '30px', lineHeight: '30px' }}>
              <Checkbox checked={selectedScopes.includes(scope)} />
              <ListItemText>{permissionScopeToTextMap[scope]}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return null;
};
