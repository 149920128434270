import { content as utilsContent } from '../../../utils';

export const content = {
  tooltips: {
    buchungsnummerAndProduktSapIdExist: 'Bitte geben Sie entweder eine Thema SAP-ID oder BNR ein.',
    missingStartDate: 'Bitte wählen Sie ein Startdatum aus.',
    missingEndDate: 'Bitte wählen Sie ein Enddatum aus.',
    endDateBeforeStartDate: 'Bitte wählen Sie ein Startdatum, das vor dem ausgewählten Enddatum liegt.',
    processing: utilsContent.tooltips.processing,
  },
  start: 'Start',
} as const;
