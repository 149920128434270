import { type ExperteBlockungFromReportingRouter, type ManuelleTerminanfrageThema } from '../../../dtos';
import { formatDateMultiRange, getExperteName, getExperteNames, getOrtName, getStandortName } from '../../../utils';

const createExperteRowFromExperteBlockung = (experteBlockung: ExperteBlockungFromReportingRouter): HTMLTableRowElement => {
  const experteRow = document.createElement('tr');
  experteRow.append(document.createElement('td'));
  experteRow.append(document.createElement('td'));
  const experteDataCol = document.createElement('td');
  experteDataCol.textContent = `${getExperteName(experteBlockung.experte)}: ${formatDateMultiRange(experteBlockung.zeitraeume)}`;

  experteRow.append(experteDataCol);

  return experteRow;
};

export const generateClipboardTextForThema = (thema: ManuelleTerminanfrageThema): string => {
  const contentDiv = document.createElement('div');
  const header = document.createElement('h3');
  header.textContent = `${thema.titel} (${thema.buchungsnummer})`;
  contentDiv.append(header);

  const table = document.createElement('table');
  for (const veranstaltung of thema.veranstaltungen) {
    const tableRow = document.createElement('tr');
    const ablauf = document.createElement('td');
    ablauf.textContent = formatDateMultiRange(veranstaltung.ablauf);
    tableRow.append(ablauf);
    const location = document.createElement('td');
    location.textContent = getStandortName(veranstaltung) ?? getOrtName(veranstaltung) ?? 'Ortname unbekannt';
    tableRow.append(location);
    table.append(tableRow);

    const isSomeExperteDifferentInAblauf = veranstaltung.experteBlockungen.some((experteBlockung) => experteBlockung.zeitraeume.length !== veranstaltung.ablauf.length);
    if (isSomeExperteDifferentInAblauf) {
      for (const experteBlockung of veranstaltung.experteBlockungen) {
        const experteRow = createExperteRowFromExperteBlockung(experteBlockung);
        table.append(experteRow);
      }
    } else {
      const experteCol = document.createElement('td');
      experteCol.textContent = getExperteNames(veranstaltung) ?? '';
      tableRow.append(experteCol);
    }
  }

  contentDiv.append(table);
  const copyString = contentDiv.innerHTML;
  contentDiv.remove();

  return copyString;
};
