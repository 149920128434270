import { useDebounce } from '../../../hooks/useDebounce';
import { content } from './ThemaFilter.content';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';

type ThemaFilterProps = {
  readonly handleBuchungsnummerChange: (buchungsnummer: string | null) => void;
  readonly handleProduktSapIdChange: (themaSapId: number | null) => void;
};

export const ThemaFilter: React.FC<ThemaFilterProps> = (props: ThemaFilterProps) => {
  const { handleBuchungsnummerChange, handleProduktSapIdChange } = props;

  const [buchungsnummer, setBuchungsnummer] = useState<string | null>(null);
  const debouncedBuchungsnummer = useDebounce(buchungsnummer);
  const [produktSapId, setProduktSapId] = useState<number | null>(null);
  const debouncedProduktSapId = useDebounce(produktSapId);

  useEffect(() => {
    handleBuchungsnummerChange(debouncedBuchungsnummer);
  }, [debouncedBuchungsnummer, handleBuchungsnummerChange]);

  useEffect(() => {
    handleProduktSapIdChange(debouncedProduktSapId);
  }, [debouncedProduktSapId, handleProduktSapIdChange]);

  const onBuchungsnummerChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const trimmedValue = event.target.value.trim();

    if (trimmedValue.length === 0) {
      setBuchungsnummer(null);
      return;
    }

    setBuchungsnummer(trimmedValue);
    setProduktSapId(null);
  };

  const onProduktSapIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const trimmedValue = event.target.value.trim();

    if (trimmedValue.length === 0) {
      setProduktSapId(null);
      return;
    }

    const value = Number.parseInt(trimmedValue, 10);
    if (!Number.isNaN(value)) {
      setProduktSapId(value);
      setBuchungsnummer(null);
    }
  };

  const textFieldError = buchungsnummer !== null && produktSapId !== null;

  return (
    <>
      <TextField fullWidth label={content.produktSapId} value={produktSapId ?? ''} onChange={onProduktSapIdChange} error={textFieldError} />
      <TextField fullWidth label={content.buchungsnummer} value={buchungsnummer ?? ''} onChange={onBuchungsnummerChange} error={textFieldError} />
    </>
  );
};
