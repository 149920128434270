import { CopyButton } from './CopyButton';
import { Box, Stack, Tooltip } from '@mui/material';
import { Link } from 'react-router';

type LinkWithCopyButtonProps = {
  readonly text: string;
  readonly to: string;
  readonly tooltip: string;
};

const styles = {
  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
};

export const LinkWithCopyButton: React.FC<LinkWithCopyButtonProps> = ({ text, to, tooltip }: LinkWithCopyButtonProps) => (
  <Stack direction="row" alignItems="center">
    <Box sx={styles.link}>
      <Tooltip title={tooltip} arrow>
        <Link to={to} target="_blank">
          {text}
        </Link>
      </Tooltip>
    </Box>
    <CopyButton text={text} />
  </Stack>
);
