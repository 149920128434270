import { calendarResourceTypes } from '../calendarResourceTypes';
import { type AbstractCalendarResource } from './AbstractCalendarResource';
import { type CalendarExperteResource } from './CalendarExperteResource';
import { type CalendarGruppenraumResource } from './CalendarGruppenraumResource';
import { type CalendarStandardRaumResource } from './CalendarStandardRaumResource';
import { type CalendarStandortResource } from './CalendarStandortResource';

export type CalendarResource = CalendarExperteResource | CalendarStandardRaumResource | CalendarGruppenraumResource | CalendarStandortResource;

export const isExperteResource = (resourceData: AbstractCalendarResource): resourceData is CalendarExperteResource => resourceData.type === calendarResourceTypes.EXPERTE;
export const isStandardRaumResource = (resourceData: AbstractCalendarResource): resourceData is CalendarStandardRaumResource =>
  resourceData.type === calendarResourceTypes.STANDARDRAUM;
export const isGruppenraumResource = (resourceData: AbstractCalendarResource): resourceData is CalendarGruppenraumResource =>
  resourceData.type === calendarResourceTypes.GRUPPENRAUM;
export const isStandortResource = (resourceData: AbstractCalendarResource): resourceData is CalendarStandortResource => resourceData.type === calendarResourceTypes.STANDORT;
