import { z } from 'zod';

export const backendConfigJsonSchema = z
  .object({
    AppBackendUrl: z.string(),
    AzureAdTenantId: z.string(),
    AzureAdClientId: z.string(),
    AzureAdRedirectUri: z.string(),
    VivaLegacyAppUrl: z.string(),
    HiveAppUrl: z.string(),
    TaskManagementBoardUrl: z.string(),
  })
  .strict();

export type BackendConfigJson = z.infer<typeof backendConfigJsonSchema>;

// this line exists for the type test in backendConfigJsonSchema.test-d.ts -> can't directly import from other modules there
export { type BackendConfigJson as BackendConfigJsonFromBackend } from '@hg-akademie-ban/vamos-viva-main';
