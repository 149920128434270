import { AccordionWithHeading, EditButtonGroup, Tablelike } from '../../../components/general';
import {
  generateVeranstaltungEigenschaftenCardErrorSnackbar,
  generateVeranstaltungEigenschaftenCardSuccessSnackbar,
  veranstaltungEigenschaftenCardBackendErrorMessage,
} from '../../../components/snackbars';
import {
  type Geschaeftsbereich,
  geschaeftsbereiche,
  type UpdateStatusOfVeranstaltungenOutput,
  type Veranstaltung,
  type VivaStatus,
  vivaStatus as vivaStatusEnum,
} from '../../../dtos';
import { trpc } from '../../../trpc';
import { printTerminart } from '../../../utils';
import { TextOrSelect } from './TextOrSelect';
import { content } from './VeranstaltungEigenschaftenCard.content';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

type VeranstaltungEigenschaftenCardProps = {
  readonly veranstaltung: Veranstaltung;
};

export const VeranstaltungEigenschaftenCard: React.FC<VeranstaltungEigenschaftenCardProps> = ({ veranstaltung }: VeranstaltungEigenschaftenCardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode, setIsEditMode] = useState(false);
  const [vivaStatus, setVivaStatus] = useState<VivaStatus>(veranstaltung.vivaStatus);
  const [geschaeftsbereich, setGeschaeftsbereich] = useState<Geschaeftsbereich>(veranstaltung.geschaeftsbereich);

  const veranstaltungUtils = trpc.useUtils().veranstaltung;
  const changeStatusMutation = trpc.planung.updateStatusOfVeranstaltungen.useMutation({
    onError: () => {
      enqueueSnackbar(veranstaltungEigenschaftenCardBackendErrorMessage, { variant: 'error' });
    },
    onSuccess: (mutateResult: UpdateStatusOfVeranstaltungenOutput) => {
      void veranstaltungUtils.invalidate();

      const successVeranstaltungen = mutateResult.filter((result) => result.successMessages.length > 0);
      if (successVeranstaltungen.length === 1) {
        const snackbar = generateVeranstaltungEigenschaftenCardSuccessSnackbar(successVeranstaltungen[0]);
        enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
      }

      const failedVeranstaltungen = mutateResult.filter((result) => result.errorMessages.length > 0);
      if (failedVeranstaltungen.length === 1) {
        const snackbar = generateVeranstaltungEigenschaftenCardErrorSnackbar(failedVeranstaltungen[0]);
        enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
      }
    },
    onSettled: () => {
      setVivaStatus(veranstaltung.vivaStatus);
    },
  });

  const changeGeschaeftsbereichMutation = trpc.veranstaltung.updateGeschaeftsbereich.useMutation({
    onSuccess: () => {
      void veranstaltungUtils.invalidate();
      enqueueSnackbar(content.geschaeftsbereich.muationSuccess, { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(content.geschaeftsbereich.mutationError, { variant: 'error' });
      setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
    },
  });

  useEffect(() => {
    setVivaStatus(veranstaltung.vivaStatus);
    setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
  }, [veranstaltung]);

  const onSave = async (): Promise<void> => {
    if (vivaStatus !== veranstaltung.vivaStatus) {
      await changeStatusMutation.mutateAsync({
        newStatus: vivaStatus,
        akaVeranstaltungIds: [veranstaltung.akaVeranstaltungId],
      });
    }

    if (geschaeftsbereich !== veranstaltung.geschaeftsbereich) {
      await changeGeschaeftsbereichMutation.mutateAsync({
        veranstaltungId: veranstaltung.id,
        geschaeftsbereich,
      });
    }
  };

  const onCancel = (): void => {
    setVivaStatus(veranstaltung.vivaStatus);
    setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
  };

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack paddingLeft={2} spacing={1}>
          {veranstaltung.vivaStatus !== vivaStatusEnum.FREIGEGEBEN && <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={onSave} onCancel={onCancel} />}
          <Tablelike
            rows={[
              [
                content.fields.vivaStatus,
                <TextOrSelect
                  key={v4()}
                  isEdit={isEditMode}
                  valueState={[vivaStatus, setVivaStatus]}
                  selections={[
                    { value: vivaStatusEnum.INPLANUNG, name: content.vivaStatus.INPLANUNG },
                    { value: vivaStatusEnum.ABGESCHLOSSEN, name: content.vivaStatus.ABGESCHLOSSEN },
                    { value: vivaStatusEnum.FREIGEGEBEN, name: content.vivaStatus.FREIGEGEBEN },
                  ]}
                />,
              ],
              [
                content.fields.geschaeftsbereich,
                <TextOrSelect
                  key={v4()}
                  isEdit={false}
                  valueState={[geschaeftsbereich, setGeschaeftsbereich]}
                  selections={[
                    { value: geschaeftsbereiche.IHC, name: geschaeftsbereiche.IHC },
                    { value: geschaeftsbereiche.ILS, name: geschaeftsbereiche.ILS },
                    { value: geschaeftsbereiche.KFF, name: geschaeftsbereiche.KFF },
                    { value: geschaeftsbereiche.CAS, name: geschaeftsbereiche.CAS },
                    { value: geschaeftsbereiche.DLS, name: geschaeftsbereiche.DLS },
                    { value: geschaeftsbereiche.UNKNOWN, name: content.noInfoAvailable },
                  ]}
                />,
              ],
              [content.fields.terminart, printTerminart(veranstaltung.terminart)],
            ]}
          />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
