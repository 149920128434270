import { AccordionWithHeading, DatePickerWrapper, EditButtonGroup } from '../../../components/general';
import { type Veranstaltung, vivaStatus, type Zeitraum } from '../../../dtos';
import { trpc } from '../../../trpc';
import { formatTimeRangeForDate } from '../../../utils';
import { content } from './TerminAblaufCard.content';
import { TimeRangeEdit } from './TimeRangeEdit';
import { Draggable } from '@mobiscroll/react';
import { Add, Remove } from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { addDays, set } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type TerminAblaufCardProps = {
  readonly veranstaltung: Veranstaltung;
};

const defaultZeitraum = {
  start: set(new Date(), { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }),
  end: set(new Date(), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }),
};

const isMinimalRequieredAblaufLength = (ablauf: Zeitraum[]): boolean => ablauf.length <= 1;

export const TerminAblaufCard: React.FC<TerminAblaufCardProps> = ({ veranstaltung }: TerminAblaufCardProps) => {
  const [ablauf, setAblauf] = useState<Zeitraum[]>(veranstaltung.ablauf);
  const [isEditMode, setIsEditMode] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(veranstaltung.ablauf[0].start);

  const { enqueueSnackbar } = useSnackbar();

  const veranstaltungUtils = trpc.useUtils().veranstaltung;
  const ablaufMutation = trpc.veranstaltung.updateAblauf.useMutation({
    onSuccess: () => {
      enqueueSnackbar(content.ABLAUF_GEAENDERT, { variant: 'success' });
      void veranstaltungUtils.invalidate();
    },
    onError(error) {
      enqueueSnackbar(content.errorMessages.updateFailed(error.message), { variant: 'error' });
      void veranstaltungUtils.invalidate();
      setAblauf(veranstaltung.ablauf);
    },
  });

  const addAblauf = (): void =>
    setAblauf((prev) => {
      const currentLastDay = prev.at(-1) ?? defaultZeitraum;
      const newStart = addDays(currentLastDay.start, 1);
      const newEnd = addDays(currentLastDay.end, 1);
      return [...prev, { start: newStart, end: newEnd }];
    });

  const removeDay = (): void => {
    setAblauf((prevAblauf) => (isMinimalRequieredAblaufLength(prevAblauf) ? prevAblauf : prevAblauf.slice(0, -1)));
  };

  const onEditCancel = (): void => {
    setAblauf(veranstaltung.ablauf);
  };

  const onSave = async (): Promise<void> => {
    if (startDate) {
      for (const [idx, ablaufTag] of ablauf.entries()) {
        ablaufTag.start.setMonth(startDate.getMonth());
        ablaufTag.start.setDate(startDate.getDate() + idx);
        ablaufTag.end.setMonth(startDate.getMonth());
        ablaufTag.end.setDate(startDate.getDate() + idx);
      }
    }

    await ablaufMutation.mutateAsync({ akaVeranstaltungId: veranstaltung.akaVeranstaltungId, ablauf });
  };

  const onTimeChange = (indexTimeChange: number, start: Date | null, end: Date | null): void => {
    setAblauf((prev) => {
      const newAblaufArray = [...prev];
      newAblaufArray[indexTimeChange] = { start: start ?? prev[indexTimeChange].start, end: end ?? prev[indexTimeChange].end };
      return newAblaufArray;
    });
  };

  return (
    <Draggable id="ablauf">
      <AccordionWithHeading heading={content.HEADING}>
        <Stack>
          {veranstaltung.vivaStatus === vivaStatus.INPLANUNG && (
            <Box display="flex">
              {isEditMode && veranstaltung.onlineTool !== null && veranstaltung.experteBlockungen.length === 0 && (
                <DatePickerWrapper
                  minDate={new Date()}
                  excludeWeekendConfig={{
                    ablaufTageCount: ablauf.length,
                  }}
                  initialDate={veranstaltung.ablauf[0].start}
                  label="Start-Datum"
                  onChange={setStartDate}
                />
              )}
              <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={onSave} onCancel={onEditCancel} disabledOn={() => startDate === null} />
            </Box>
          )}
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {ablauf.map((range, idx) => (
              <TimelineItem key={range.start.getTime()}>
                <TimelineOppositeContent align="right">{idx + 1}. Tag</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  {idx + 1 < ablauf.length ? <TimelineConnector /> : null}
                </TimelineSeparator>
                <TimelineContent>
                  {isEditMode ? (
                    <TimeRangeEdit idx={idx} start={range.start} end={range.end} onChange={onTimeChange} autoArrange />
                  ) : (
                    <Typography>{formatTimeRangeForDate(range.start, range.end)}</Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          {isEditMode && (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Tooltip title={isMinimalRequieredAblaufLength(ablauf) ? content.MINDESTENS_EIN_TAG : null}>
                <span>
                  <Button startIcon={<Remove />} color="error" onClick={removeDay} disabled={ablauf.length <= 1}>
                    {content.TAG_ENTFERNEN}
                  </Button>
                </span>
              </Tooltip>
              <Button onClick={addAblauf} startIcon={<Add />} sx={{ borderRadius: 1 }}>
                {content.TAG_HINZUFUEGEN}
              </Button>
            </Stack>
          )}
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
