import { type DataGridColDef } from '../../../utils';
import { VeranstaltungenTable } from '../VeranstaltungenTable';
import { CardContent, Paper, Stack, Typography } from '@mui/material';
import { type GridRowIdGetter, type GridRowModel } from '@mui/x-data-grid-pro';
import { type ReactNode } from 'react';

type ThemaCardProps<T extends GridRowModel> = {
  readonly thema: {
    titel: string;
    buchungsnummer: string;
    produktSapId: number;
    veranstaltungen: T[];
  };
  readonly columns: DataGridColDef<T>;
  readonly children: ReactNode;
  /**
   * This component is displaying a table of Veranstaltungen. Each row must have a unique identifier.
   *
   * @see https://mui.com/x/react-data-grid/row-definition/?srsltid=AfmBOoq7ohBDGz4Eskn9zWiGFHCDQT1E3IR-KopXXfYDeIwf5cBfE09U#row-identifier
   */
  readonly getRowId: GridRowIdGetter<T>;
};

export const ThemaCard = <T extends GridRowModel>({ thema, columns, children, getRowId }: ThemaCardProps<T>): JSX.Element => (
  <Paper>
    <CardContent>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Typography variant="h5">
            {thema.titel} ({thema.buchungsnummer})
          </Typography>
          <Typography variant="body2">SAP-ID: {thema.produktSapId}</Typography>
        </Stack>
        {children}
      </Stack>
      <VeranstaltungenTable veranstaltungen={thema.veranstaltungen} columns={columns} getRowId={getRowId} />
    </CardContent>
  </Paper>
);
