import { type GetAutomatisierteTerminanfragenByThemaInput } from '../../dtos';
import { type Palette, type SxProps, Typography } from '@mui/material';
import { type ReactElement } from 'react';

export const buttonStyle = (palette: Palette): SxProps => ({
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  borderColor: palette.primary.main,
  border: '1px solid',
  height: '35px',
  textTransform: 'unset',
  color: palette.primary.main,
  '&:hover': {
    backgroundColor: 'unset',
    opacity: 0.8,
  },
  '&[aria-pressed="true"]': {
    '&:hover': {
      backgroundColor: palette.primary.main,
      opacity: 0.8,
    },
    color: 'white',
    backgroundColor: palette.primary.main,
  },
});

export type AutomatisierteTerminanfragenFilterConfiguration = {
  readonly key: string;
  readonly label: string;
  readonly filter: GetAutomatisierteTerminanfragenByThemaInput;
  readonly tooltip: ReactElement;
};

export const quickfilterConfigurations: AutomatisierteTerminanfragenFilterConfiguration[] = [
  {
    key: 'answered',
    label: 'Produkt vollständig beantwortet',
    filter: {
      quickFilterName: 'fullyAnswered',
    },
    tooltip: (
      <Typography variant="caption" component="span">
        Dieser Quickfilter filtert nach folgenden Kriterien:
        <ul>
          <li>Keine Terminanfrage im Produkt ist im Status "angefragt"</li>
          <li>Mindestens eine Terminanfrage im Produkt ist im Status "abgelehnt" oder "Konflikt"</li>
          <li>Alle Terminanfragen des Produkts sind entweder im Status "angenommen", "abgelehnt" oder "Konflikt"</li>
        </ul>
        Bei diesen Produkten müssen Veranstaltungen umgeplant werden. Sobald alle Veranstaltungen mit abgelehnter Terminanfrage oder Konflikten umgeplant wurden, wird der Status
        dieser Terminanfragen auf "angefragt" zurückgesetzt und das Produkt verschwindet aus diesem Filter.
      </Typography>
    ),
  },
  {
    key: 'accepted',
    label: 'Produkt vollständig angenommen',
    filter: {
      quickFilterName: 'fullyAccepted',
    },
    tooltip: (
      <Typography variant="caption" component="span">
        Dieser Quickfilter filtert nach folgenden Kriterien:
        <ul>
          <li>Alle Terminanfragen im Produkt sind in im Status: "angenommen"</li>
          <li>Mindestens eine Veranstaltung im Produkt ist noch im Status "In Planung", "Planung abgeschlossen"</li>
        </ul>
        Bei diesen Produkten müssen Veranstaltungen ggf. noch freigegeben und damit nach SAP übertragen werden. Sobald alle Veranstaltungen freigegeben wurden, verschwindet das
        Produkt aus dem Quickfilter.
      </Typography>
    ),
  },
  {
    key: 'unanswered',
    label: 'Terminanfragen seit 7 Tagen unbeantwortet',
    filter: {
      quickFilterName: 'moreThan7DaysAgo',
    },
    tooltip: (
      <Typography variant="caption" component="span">
        Dieser Quickfilter filtert nach folgenden Kriterien:
        <ul>
          <li>mindestens eine Terminanfrage im Produkt ist seit 7 Tagen unbeantwortet</li>
        </ul>
        Diese Terminanfragen müssen manuell angemahnt werden. Sobald der Trainer die Terminanfragen beantwortet hat, verschwindet das Produkt aus diesem Quickfilter.
      </Typography>
    ),
  },
];
