import { type GetPlanungsinformationOutput } from '../../../dtos';
import { content } from './TerminAnsprechpartnerCard.content';

export const printPM = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.verantwortlicherPM === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.verantwortlicherPM;
  }

  return print;
};

export const printFachkraft = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.verantwortlicherFachkraft === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.verantwortlicherFachkraft;
  }

  return print;
};

export const printTeamPlanning = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.verantwortlicherTeamPlanning === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.verantwortlicherTeamPlanning;
  }

  return print;
};
