import { CalendarNav, CalendarNext, CalendarPrev, CalendarToday } from '@mobiscroll/react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

type ResourceCalendarHeaderProps = {
  readonly onViewSizeChange: (viewSize: number) => void;
};

export const ResourceCalendarHeader: React.FC<ResourceCalendarHeaderProps> = ({ onViewSizeChange }: ResourceCalendarHeaderProps) => {
  const defaultWeekCount = 4;
  const maxWeekCount = 12;

  const [viewSize, setViewSize] = useState<number>(defaultWeekCount);

  useEffect(() => {
    onViewSizeChange(viewSize);
  }, [onViewSizeChange, viewSize]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      <Box>
        <CalendarToday />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <CalendarPrev />
        <CalendarNav />
        <CalendarNext />
      </Box>

      <FormControl sx={{ width: '110px' }}>
        <InputLabel id="weekdays-select-label">Wochenanzahl</InputLabel>
        <Select
          labelId="weekdays-select-label"
          value={viewSize}
          label="Wochenanzahl"
          onChange={(event) => setViewSize(event.target.value as number)}
          size="small"
          variant="outlined"
        >
          {Array.from({ length: maxWeekCount - 1 }).map((_, i) => (
            <MenuItem key={`weekdays-select-item-${i + 1}`} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
