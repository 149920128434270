import { type SapStatus, type VivaStatus } from '../../../dtos';
import { determineStatusText } from '../../../utils';
import { Chip } from '@mui/material';

type VaStatusChipProps = {
  readonly sapStatus: SapStatus | null;
  readonly vivaStatus: VivaStatus | null;
};

export const VaStatusChip: React.FC<VaStatusChipProps> = ({ sapStatus, vivaStatus }: VaStatusChipProps) => {
  const status = determineStatusText(sapStatus, vivaStatus);
  return <Chip color="primary" label={status} />;
};
