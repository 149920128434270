import { type GetPlanungsinformationOutput } from '../../../dtos';
import { type TablelikeRow } from '../Tablelike';
import { Musskopplung } from './Musskopplung';
import { content } from './TerminPlanungsinformationenCard.content';
import { Link } from 'react-router';

export const getThirdSectionTableRows = (planungsinformation: GetPlanungsinformationOutput): TablelikeRow[] => {
  const rows: TablelikeRow[] = [];

  if (planungsinformation.musskopplungReihenfolgeAkaProduktIds.length > 0) {
    rows.push([content.fields.musskopplung, <Musskopplung musskopplungReihenfolgeAkaProduktIds={planungsinformation.musskopplungReihenfolgeAkaProduktIds} key="musskopplung" />]);
  }

  if (planungsinformation.istTerminreihe === true) {
    rows.push([content.fields.terminreihe, content.ja]);
  }

  if (planungsinformation.verknuepfungBLOPundPraesenzseminar !== null) {
    const akaProduktId = planungsinformation.verknuepfungBLOPundPraesenzseminar;
    const link = <Link to={`/thema/${akaProduktId}`}>{akaProduktId}</Link>;
    rows.push([content.fields.verknuepfung, link]);
  }

  return rows;
};
