import { AuthLogoutPage } from '../pages/AuthLogoutPage';
import { AuthRedirectPage } from '../pages/AuthRedirectPage';
import { FehlerPage } from '../pages/FehlerPage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { RaumRessourceOverviewPage } from '../pages/RaumRessourceOverviewPage';
import { SearchPage } from '../pages/SearchPage';
import { TerminanfragenAutomatisiertPage } from '../pages/TerminanfragenAutomatisiertPage';
import { TerminanfragenManuellPage } from '../pages/TerminanfragenManuellPage';
import { ThemaDetailsPage } from '../pages/ThemaDetailsPage';
import { ThemaVeranstaltungPlanungPage } from '../pages/ThemaVeranstaltungPlanungPage';
import { VeranstaltungDetailsPage } from '../pages/VeranstaltungDetailsPage';
import { LayoutWithAuth } from './LayoutWithAuth';
import { RouterError } from './RouterError';
import { createBrowserRouter } from 'react-router';

const PATH = {
  AUTH: {
    URL: 'auth',
    LOGOUT: 'logout',
    REDIRECT: 'redirect',
  },
  FEHLER: 'fehler',
  RAUM_RESSOURCE_OVERVIEW: 'raumressourcenuebersicht',
  TERMINANFRAGEN: {
    URL: 'terminanfragen',
    AUTOMATISIERT: 'automatisiert',
    MANUELL: 'manuell',
  },
  THEMA: {
    URL: 'thema',
    DETAILS: ':akaProduktId',
    VERANSTALTUNG_PLANUNG: ':akaProduktId/veranstaltung/neu/planung',
  },
  VERANSTALTUNG_DETAILS: 'veranstaltung/:akaVeranstaltungId',
  NOT_FOUND: '*',
} as const;

export const appRouter = createBrowserRouter([
  {
    path: PATH.AUTH.URL,
    errorElement: <RouterError />,
    children: [
      {
        path: PATH.AUTH.LOGOUT,
        Component: AuthLogoutPage,
      },
      {
        path: PATH.AUTH.REDIRECT,
        Component: AuthRedirectPage,
      },
    ],
  },
  {
    path: '/',
    element: <LayoutWithAuth />,
    errorElement: <RouterError />,
    children: [
      {
        Component: FehlerPage,
        path: PATH.FEHLER,
      },
      {
        Component: RaumRessourceOverviewPage,
        path: PATH.RAUM_RESSOURCE_OVERVIEW,
      },
      {
        Component: SearchPage,
        index: true,
      },
      {
        path: PATH.TERMINANFRAGEN.URL,
        children: [
          {
            Component: TerminanfragenAutomatisiertPage,
            path: PATH.TERMINANFRAGEN.AUTOMATISIERT,
          },
          {
            Component: TerminanfragenManuellPage,
            path: PATH.TERMINANFRAGEN.MANUELL,
          },
        ],
      },
      {
        path: PATH.THEMA.URL,
        children: [
          {
            Component: ThemaDetailsPage,
            path: PATH.THEMA.DETAILS,
          },
          {
            Component: ThemaVeranstaltungPlanungPage,
            path: PATH.THEMA.VERANSTALTUNG_PLANUNG,
          },
        ],
      },
      {
        Component: VeranstaltungDetailsPage,
        path: PATH.VERANSTALTUNG_DETAILS,
      },
      {
        Component: NotFoundPage,
        path: PATH.NOT_FOUND,
      },
    ],
  },
]);
