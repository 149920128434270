import { type AbstractRaumResource } from '../CalendarResource';
import { type CalendarRaumResourceType } from '../calendarResourceTypes';
import { AbstractCalendarEvent, type BlockungData } from './AbstractCalendarEvent';

/**
 * Represents one Raum- or Gruppen-Raumblockung, and one event in the calendar
 */
export class CalendarRaumEvent extends AbstractCalendarEvent {
  public readonly type: CalendarRaumResourceType;

  public readonly blockungData: BlockungData;

  public readonly ortKuerzel: string;

  public constructor(start: Date, end: Date, calendarResource: AbstractRaumResource, raumBlockungData: BlockungData) {
    super(start, end, calendarResource);
    this.type = calendarResource.type;
    this.ortKuerzel = calendarResource.raum.ort.kuerzel;
    this.blockungData = raumBlockungData;
  }
}
