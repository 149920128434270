export const content = {
  TAG_ENTFERNEN: 'Tag entfernen',
  TAG_HINZUFUEGEN: 'Tag hinzufügen',
  ABLAUF_GEAENDERT: 'Ablauf geändert',
  HEADING: 'Zeitlicher Ablauf',
  MINDESTENS_EIN_TAG: 'Es muss mindestens ein Ablauftag vorhanden sein.',
  errorMessages: {
    updateFailed: (msg: string) => `Ablauf konnte nicht geändert werden: ${msg}`,
    updateNotAllowed: 'Nur in Viva erstellte Veranstaltungen, die noch nicht freigegebn sind, dürfen geändert werden',
  },
} as const;
