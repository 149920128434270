export const taskCardContent = {
  draggableId: 'tasks',
  heading: 'Aufgaben',
  columnLabels: {
    titel: 'Titel',
    taskStatus: 'Taskstatus',
    bearbeiter: 'Bearbeiter',
    labels: 'Labels',
  },
} as const;
