import {
  AccordionWithHeading,
  expertsColumn,
  ortKuerzelColumn,
  startColumn,
  statusColumn,
  TerminGenerellerKommentarCard,
  TerminPlanungsinformationenCard,
  TrpcLoadingInfo,
  VeranstaltungenTable,
} from '../../components/general';
import { type GetPlanungsinformationOutput, type GetVeranstaltungenByThemaIdOutput, type ThemaFromThemaRouter } from '../../dtos';
import { trpc } from '../../trpc';
import { type DataGridColDef } from '../../utils';
import { CreateVeranstaltung } from './CreateVeranstaltung';
import { Grid2, Paper, Stack } from '@mui/material';
import { type TRPCClientErrorBase } from '@trpc/client';
import { type UseTRPCQueryResult } from '@trpc/react-query/shared';
// no risk, no fun ;)
// TODO: change this import path as soon as the new TRPC is declared stable
import { type DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import';

type VeranstaltungPlanungProps = {
  readonly thema: ThemaFromThemaRouter;
  readonly planungsinformationQuery: UseTRPCQueryResult<GetPlanungsinformationOutput, TRPCClientErrorBase<DefaultErrorShape>>;
};

const columns: DataGridColDef<GetVeranstaltungenByThemaIdOutput[number]> = [startColumn(), ortKuerzelColumn(), expertsColumn(), statusColumn()];

export const VeranstaltungPlanung: React.FC<VeranstaltungPlanungProps> = ({ thema, planungsinformationQuery }: VeranstaltungPlanungProps) => {
  const veranstaltungen = trpc.veranstaltung.getVeranstaltungenByThemaId.useQuery({ themaId: thema.id });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 12 }} container>
        <Grid2 size={{ xs: 3 }}>
          <Stack spacing={2}>
            <TerminPlanungsinformationenCard planungsinformationQuery={planungsinformationQuery} />
            <TerminGenerellerKommentarCard planungsinformationQuery={planungsinformationQuery} />
            <AccordionWithHeading heading="Veranstaltungen">
              <TrpcLoadingInfo trpcQuery={veranstaltungen} entity="Veranstaltungen">
                {veranstaltungen.data && (
                  <VeranstaltungenTable veranstaltungen={veranstaltungen.data} columns={columns} disableColumnMenu getRowId={(veranstaltung) => veranstaltung.akaVeranstaltungId} />
                )}
              </TrpcLoadingInfo>
            </AccordionWithHeading>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 9 }}>
          <Paper sx={{ padding: '10px' }}>
            <CreateVeranstaltung thema={thema} />
          </Paper>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
