import { type Ablauf, type AkaVeranstaltungId } from '../../../../../dtos';
import { type AbstractCalendarEvent, type CalendarEvent } from '../CalendarEvent';
import { type CalendarResourceType } from '../calendarResourceTypes';
import { type MbscResource } from '@mobiscroll/react';
import { addDays, endOfDay, startOfDay } from 'date-fns';

/**
 * Represents one blockable Ressource in our system (either Raum or Experte or Standort), and one row in the calendar.
 */
export abstract class AbstractCalendarResource implements MbscResource {
  /**
   * Type of the Ressource. Used to group resources in the calendar.
   */
  public abstract readonly type: CalendarResourceType;

  /**
   * Unique row identifier. Consists of prefix plus SapId of the underlying Ressource.
   * Needed by Mobiscroll -> Don't rename.
   *
   * @example For a Raum, this would be 'r-60000503'.
   */
  public abstract readonly id: string;

  /**
   * Specifies the name of the calendar row.
   * Needed by Mobiscroll -> Don't rename.
   */
  public abstract readonly name: string;

  /**
   * Turns pre-existing Blockungen of the underlying Ressource into CalendarEvents.
   * See AbstractCalendarEvent for more info.
   */
  public abstract convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarEvent[];

  /**
   * Used when putting in new Blockungen for the underlying Ressource.
   */
  public abstract createNewCalendarEvent(date: Date, ablaufTageCount: number): CalendarEvent;

  public abstract getRessourceSapId(): number;

  /**
   * Used to determine whether the newly selected event may coexist with this resource.
   */
  public abstract isEventCompatibleWithResource(otherEvent: AbstractCalendarEvent): boolean;

  protected getBlockungStart(zeitraeume: Ablauf): Date {
    return zeitraeume.map((tag) => tag.start).reduce((previous, current) => (current < previous ? current : previous));
  }

  protected getBlockungEnd(zeitraeume: Ablauf): Date {
    return zeitraeume.map((tag) => tag.end).reduce((previous, current) => (current > previous ? current : previous));
  }

  protected createZeitraeumeByDateAndDayCount(date: Date, dayCount: number): Ablauf {
    const zeitraeume: Ablauf = [];
    for (let index = 0; index < dayCount; index++) {
      zeitraeume.push({
        start: startOfDay(addDays(date, index)),
        end: endOfDay(addDays(date, index)),
      });
    }

    return zeitraeume;
  }
}
