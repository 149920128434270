import { type SapStatus, type VivaStatus, vivaStatus as vivaStatusOptions } from '../dtos';

export const content = {
  loadingMessage: 'Lädt ...',
  noInfoAvailable: '-',
  units: {
    tage: 'Tage',
    tag: 'Tag',
    qm: 'qm',
    percent: '%',
  },
  ja: 'ja',
  nein: 'nein',
  missingThemaLink: 'Link konnte nicht geladen werden, weil verknüpftes Produkt nicht in Viva gefunden werden konnte.',
  vivaStatus: {
    inPlanung: 'In Planung',
    abgeschlossen: 'Planung abgeschlossen',
    freigegeben: 'Planung freigegeben',
  },
  columnLabels: {
    akaVeranstaltungId: 'AKA-Veranstaltung-ID',
    veranstaltungSapId: 'SAP-ID',
    titel: 'Titel',
    start: 'Startdatum',
    end: 'Enddatum',
    status: 'Status',
    ortKuerzel: 'Ortkürzel',
    ortName: 'Ort',
    standortName: 'Standort',
    regionName: 'Region',
    experten: 'Trainer',
    buchungsnummer: 'Buchungsnummer',
  },
  tooltips: {
    zurVeranstaltungWechseln: 'Zur Veranstaltung wechseln',
    zumThemaWechseln: 'Zum Thema wechseln',
    processing: 'Wir verarbeiten Ihre Daten ...',
  },
} as const;

export const determineStatusText = (sapStatus: SapStatus | null, vivaStatus: VivaStatus | null): string => {
  if (sapStatus !== null) {
    return sapStatus;
  }

  switch (vivaStatus) {
    case vivaStatusOptions.INPLANUNG:
      return content.vivaStatus.inPlanung;
    case vivaStatusOptions.ABGESCHLOSSEN:
      return content.vivaStatus.abgeschlossen;
    case vivaStatusOptions.FREIGEGEBEN:
      return content.vivaStatus.freigegeben;
    default:
      return content.noInfoAvailable;
  }
};

export const printWithNoInfo = (input: string | undefined | null | number): string => {
  if (typeof input === 'number') {
    return input.toString();
  }

  if (typeof input === 'string' && input.length > 0) {
    return input;
  }

  return content.noInfoAvailable;
};
