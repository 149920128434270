import { type AkaVeranstaltungId } from '../../../../dtos';
import { getOverallBoundsOfIntervals } from '../../../../utils';
import { type CalendarEvent, isGruppenRaumEvent, isStandardRaumEvent, isStandortEvent } from './CalendarEvent';
import { type CalendarResource, isGruppenraumResource, isStandardRaumResource, isStandortResource } from './CalendarResource';
import {
  addDays,
  areIntervalsOverlapping,
  differenceInDays,
  eachDayOfInterval,
  endOfDay,
  type Interval,
  isAfter,
  isBefore,
  isSameDay,
  isWeekend,
  max,
  min,
  startOfDay,
  subDays,
} from 'date-fns';
import { v4 } from 'uuid';

/**
 * Computes the "best" distance in days to shift the "dateInterval" to the "targetDate"
 *
 * From the targetDate both, the distance to the start and end of the interval is computed and the absolute (see Math.abs()) shorter distance
 * is returned IF the new bounds do not overlap with weekend days. ELSE, the longer distance is returned.
 *
 * Example (without considering weekends):
 *
 * - lower bound: 2024-04-05
 * - upper bound: 2024-04-07
 * - target: 2024-04-09
 *
 * - Distance between target and lower bound: 4
 * - Distance between target and upper bound: 2
 *
 * Result: "2" is returned
 */
function computeBestShiftDistanceToTargetDate(bounds: Interval, targetDate: Date): number {
  const targetDay = startOfDay(targetDate);

  const daysToResourceSelectionStart = differenceInDays(targetDay, startOfDay(bounds.start));
  const daysToResourceSelectionEnd = differenceInDays(targetDay, startOfDay(bounds.end));
  const [shortDistance, longDistance] = [daysToResourceSelectionStart, daysToResourceSelectionEnd].sort((a, b) => Math.abs(a) - Math.abs(b));

  const newUpperBound = addDays(bounds.start, shortDistance);
  const newLowerBound = addDays(bounds.end, shortDistance);
  const newRange = eachDayOfInterval({ start: newLowerBound, end: newUpperBound });
  const newRangeIncludesWeekend = newRange.some((date) => isWeekend(date));

  return newRangeIncludesWeekend ? longDistance : shortDistance;
}

export class CalendarEventHelper {
  public readonly ablaufTageCount: number;

  public constructor(ablaufTageCount: number = 1) {
    this.ablaufTageCount = ablaufTageCount;
  }

  public initEvents(resources: CalendarResource[], akaVeranstaltungId: AkaVeranstaltungId | null): CalendarEvent[] {
    const newEvents = [];

    for (const resource of resources) {
      newEvents.push(...resource.convertBlockungenToCalendarEvents(akaVeranstaltungId));
    }

    this.#copyOldSelectionAsCurrentSelection(newEvents);

    return newEvents;
  }

  /**
   * Regeln für das Verhalten bei Zell-Klicks:
   * - "creates" finden ausschließlich INNERHALB der aktuellen Auswahlgrenzen statt bzw. wenn noch keine Auswahl besteht
   * - "shifts" werden AUSSCHLIESSLICH durch Klicks AUSSERHALB der aktuellen Auswahlgrenzen ausgelöst
   */
  public addEvent(resource: CalendarResource, events: CalendarEvent[], date: Date): CalendarEvent[] {
    let currentSelection = events.filter((event) => event.isCurrentSelection);
    const fixedEvents = events.filter((event) => !event.isCurrentSelection);

    if (this.#needsShift(currentSelection, date)) {
      this.#shiftSelection(currentSelection, date);
    }

    this.#addEventToSelectionIfNotExists(resource, date, currentSelection);

    const currentSelectionSplitByCompatibility = this.#filterOutIncompatibleEvents(resource, currentSelection);

    currentSelection = currentSelectionSplitByCompatibility.compatible;
    const allDaysInSelectionBounds = this.#getAllDaysInSelectionBounds(currentSelection);

    this.#checkIncompatibleEventsThatNeedInteraction(resource, currentSelectionSplitByCompatibility.incompatible);
    this.#checkIncludesWeekend(allDaysInSelectionBounds);
    this.#checkAblaufOverflow(allDaysInSelectionBounds);
    this.#checkStandardRaumAvailability(resource, currentSelection);
    this.#checkGruppenraumMatchesRaumort(resource, currentSelection);
    this.#checkEventCollisions(currentSelection, fixedEvents);

    return [...currentSelection, ...fixedEvents];
  }

  public removeEvent(eventId: string, events: CalendarEvent[]): CalendarEvent[] {
    const eventToRemove = events.find((event) => event.id === eventId);

    if (typeof eventToRemove === 'undefined') {
      throw new TypeError('Programmer error');
    }

    let eventsClean = events.filter((event) => (event.isCurrentSelection ? event.id !== eventId : true));
    if (isStandardRaumEvent(eventToRemove)) {
      eventsClean = eventsClean.filter((event) => !isGruppenRaumEvent(event));
    }

    return eventsClean;
  }

  #needsShift(currentSelection: CalendarEvent[], date: Date): boolean {
    const currentSelectionIsEmpty = currentSelection.length === 0;
    const dateIsOutsideCurrentSelection = this.#isOutsideSelection(date, currentSelection);

    if (currentSelectionIsEmpty) {
      return false;
    } else return dateIsOutsideCurrentSelection;
  }

  #shiftSelection(currentSelection: CalendarEvent[], targetDate: Date): void {
    if (currentSelection.length) {
      const daysToShift = computeBestShiftDistanceToTargetDate(getOverallBoundsOfIntervals(currentSelection, this.ablaufTageCount), targetDate);
      if (daysToShift === 0) {
        return;
      }

      for (const [index, event] of currentSelection.entries()) {
        const eventClone = {
          ...event,
          start: addDays(event.start, daysToShift),
          end: addDays(event.end, daysToShift),
        };
        currentSelection.splice(index, 1, eventClone);
      }
    }
  }

  #isOutsideSelection(date: Date, selectedEvents: readonly CalendarEvent[]): boolean {
    if (selectedEvents.length === 0) {
      // Date is always considered "outside" if there is no selection
      return true;
    } else {
      const selectedDays = getOverallBoundsOfIntervals(selectedEvents, this.ablaufTageCount);
      return isBefore(date, selectedDays.start) || isAfter(date, selectedDays.end);
    }
  }

  #getAllDaysInSelectionBounds(selection: readonly CalendarEvent[]): Date[] {
    return eachDayOfInterval(getOverallBoundsOfIntervals(selection, this.ablaufTageCount));
  }

  #addEventToSelectionIfNotExists(resource: CalendarResource, date: Date, selection: CalendarEvent[]): void {
    if (this.#isResourceSelectedAtDate(resource, date, selection)) {
      return;
    }

    const start = startOfDay(date);
    const end = endOfDay(date);
    const adjacentEvents = selection.filter((event) => this.#eventRefersToResource(event, resource) && this.#isAdjacentEvent(event, date));
    let newEvent;
    if (adjacentEvents.length > 0) {
      const adjacentEventZeitraeume = adjacentEvents.flatMap((item) => item.blockungData.zeitraeume);
      newEvent = { ...adjacentEvents[0] };
      newEvent.start = min([...adjacentEvents.map((item) => item.start), start]);
      newEvent.end = max([...adjacentEvents.map((item) => item.end), end]);
      newEvent.blockungData.zeitraeume = [...adjacentEventZeitraeume, { start, end }];
      for (const eventToDelete of adjacentEvents) {
        const eventIndexToDelete = selection.indexOf(eventToDelete);
        selection.splice(eventIndexToDelete, 1);
      }
    } else {
      newEvent = resource.createNewCalendarEvent(date, this.ablaufTageCount);
      newEvent.isCurrentSelection = true;

      if (selection.length) {
        const bounds = getOverallBoundsOfIntervals(selection, this.ablaufTageCount);
        const exceedingDays = differenceInDays(newEvent.end, bounds.end);
        if (exceedingDays > 0) {
          newEvent.start = subDays(newEvent.start, exceedingDays);
          newEvent.end = subDays(newEvent.end, exceedingDays);
        }
      }
    }

    selection.push(newEvent);
  }

  #isResourceSelectedAtDate(resource: CalendarResource, date: Date, selection: CalendarEvent[]): boolean {
    return selection.some((event) => this.#eventRefersToResource(event, resource) && eachDayOfInterval(event).some((eventDay) => isSameDay(eventDay, date)));
  }

  #isAdjacentEvent(event: CalendarEvent, date: Date): boolean {
    return isSameDay(date, subDays(event.start, 1)) || isSameDay(date, addDays(event.end, 1));
  }

  #checkIncludesWeekend(allDaysInSelectionBound: Date[]): void {
    const includesWeekend = allDaysInSelectionBound.some((day) => isWeekend(day));
    if (includesWeekend) {
      throw new Error('Planung enthält ein Wochenende');
    }
  }

  #checkAblaufOverflow(allDaysInSelectionBounds: readonly Date[]): void {
    const exceedsDayCnt = allDaysInSelectionBounds.length > this.ablaufTageCount;

    if (exceedsDayCnt) {
      throw new Error('Auswahl würde die Anzahl an Tagen im Ablauf übersteigen.');
    }
  }

  #checkEventCollisions(selection: readonly CalendarEvent[], fixedEvents: CalendarEvent[]): void {
    const isSelectionBlocked = selection.some((event) =>
      fixedEvents.some((fixedEvent) => {
        if (isStandortEvent(fixedEvent)) {
          return false;
        }

        if (event.resource !== fixedEvent.resource) {
          return false;
        }

        if (fixedEvent.isOldSelection) {
          return false;
        }

        return areIntervalsOverlapping(event, fixedEvent);
      }),
    );

    if (isSelectionBlocked) {
      throw new Error('Auswahl kollidiert mit bestehenden Events.');
    }
  }

  #checkStandardRaumAvailability(calendarResource: CalendarResource, selection: readonly CalendarEvent[]): void {
    if (!isStandardRaumResource(calendarResource)) {
      return;
    }

    for (const event of selection) {
      if (isStandardRaumEvent(event) && !calendarResource.isAvailable(event)) {
        throw new Error('Raum ist für die gewählte Zeit nicht eingekauft.');
      }
    }
  }

  #checkGruppenraumMatchesRaumort(calendarResource: CalendarResource, selection: readonly CalendarEvent[]): void {
    if (!isGruppenraumResource(calendarResource)) {
      return;
    }

    const selectedRaumEvent = selection.find((event) => isStandardRaumEvent(event));
    if (!selectedRaumEvent) {
      throw new Error('Es muss zuerst ein Standardraum ausgewählt werden.');
    }

    if (selectedRaumEvent.ortKuerzel !== calendarResource.raum.ort.kuerzel) {
      throw new Error(`Der Gruppenraum muss am selben Ort wie der Standardraum liegen: "${selectedRaumEvent.ortKuerzel}".`);
    }
  }

  #copyOldSelectionAsCurrentSelection(events: CalendarEvent[]): void {
    const hasSelections = events.some((event) => event.isCurrentSelection);
    if (hasSelections) {
      return;
    }

    for (const event of events) {
      if (event.isOldSelection) {
        events.push({ ...event, id: v4(), isOldSelection: false, isCurrentSelection: true });
      }
    }
  }

  #checkIncompatibleEventsThatNeedInteraction(resource: CalendarResource, currentIncompatibleSelection: CalendarEvent[]): void {
    const hasIncompatibleGruppenraeume = currentIncompatibleSelection.some((event) => isGruppenRaumEvent(event));

    if (hasIncompatibleGruppenraeume) {
      if (isStandortResource(resource)) {
        throw new Error('Es kann nicht auf Standortblockung gewechselt werden, da noch Gruppenräume ausgewählt sind. Bitte diese zuerst abwählen.');
      }

      if (isStandardRaumResource(resource)) {
        throw new Error('Der Standardraum kann nicht zu einem anderen Ort gewechselt werden, da noch Gruppenräume ausgewählt sind. Bitte diese zuerst abwählen.');
      }
    }
  }

  #filterOutIncompatibleEvents(resource: CalendarResource, currentSelection: readonly CalendarEvent[]) {
    const compatible: CalendarEvent[] = [];
    const incompatible: CalendarEvent[] = [];

    for (const eventToCheck of currentSelection) {
      if (this.#eventRefersToResource(eventToCheck, resource) || resource.isEventCompatibleWithResource(eventToCheck)) {
        compatible.push(eventToCheck);
      } else {
        incompatible.push(eventToCheck);
      }
    }

    return { compatible, incompatible };
  }

  #eventRefersToResource(event: CalendarEvent, resource: CalendarResource): boolean {
    return event.resource === resource.id;
  }
}
