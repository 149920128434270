import {
  akaVeranstaltungIdColumn,
  buchungsnummerColumn,
  endColumn,
  expertsColumn,
  startColumn,
  statusColumn,
  titelColumn,
  TrpcLoadingInfo,
  VeranstaltungenTable,
  veranstaltungSapIdColumn,
} from '../../../components/general';
import { type SearchVeranstaltungOutputItem } from '../../../dtos';
import { trpc } from '../../../trpc';
import { type DataGridColDef } from '../../../utils';
import { Box, Paper } from '@mui/material';

type VeranstaltungSearchProps = {
  readonly searchTerm: string;
};

export const VeranstaltungSearch: React.FC<VeranstaltungSearchProps> = ({ searchTerm }: VeranstaltungSearchProps) => {
  const veranstaltungQuery = trpc.search.searchVeranstaltungen.useQuery({ searchTerm }, { enabled: searchTerm.length > 0 });

  const columns: DataGridColDef<SearchVeranstaltungOutputItem> = [
    veranstaltungSapIdColumn(),
    akaVeranstaltungIdColumn(),
    buchungsnummerColumn(),
    titelColumn(),
    startColumn(),
    endColumn(),
    statusColumn(),
    expertsColumn(),
  ];

  return (
    <Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 2,
          boxSizing: 'border-box',
        }}
      >
        <Box>
          <TrpcLoadingInfo trpcQuery={veranstaltungQuery} entity="Veranstaltungen">
            {veranstaltungQuery.data && (
              <VeranstaltungenTable columns={columns} veranstaltungen={veranstaltungQuery.data} getRowId={(veranstaltung) => veranstaltung.akaVeranstaltungId} />
            )}
          </TrpcLoadingInfo>
        </Box>
      </Box>
    </Paper>
  );
};
