import { ContentLoading, EasyBreadcrumbs, type EasyBreadcrumbsItem, NoContentInfo } from '../../components/general';
import { type GetPlanungsinformationOutput, type GetThemaByIdOutput } from '../../dtos';
import { useNormalizedParams } from '../../hooks/useNormalizedParams';
import { trpc } from '../../trpc';
import { formatDate } from '../../utils';
import { VeranstaltungPlanung } from './VeranstaltungPlanung';
import { Grid2, Paper, Stack, Typography } from '@mui/material';

export const ThemaVeranstaltungPlanungPage: React.FC = () => {
  const { akaProduktId } = useNormalizedParams();

  if (akaProduktId === undefined) {
    return <NoContentInfo text="Termin konnte nicht gefunden werden" />;
  }

  const themaQuery = trpc.thema.getThemaById.useQuery<GetThemaByIdOutput>({ akaProduktId });
  const planungsinformationQuery = trpc.planung.getPlanungsinformation.useQuery<GetPlanungsinformationOutput>({ akaProduktId });

  if (themaQuery.error || planungsinformationQuery.error) {
    return <NoContentInfo text="Termin konnte nicht gefunden werden" />;
  }

  if (themaQuery.isLoading || planungsinformationQuery.isLoading || !themaQuery.data) {
    return <ContentLoading />;
  }

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      label: 'Thema',
      href: `/thema/${akaProduktId}`,
    },
  ];

  return (
    <Stack sx={{ paddingTop: 2 }} spacing={2}>
      <EasyBreadcrumbs items={breadcrumbs} />
      <Paper sx={{ padding: 2 }}>
        <Grid2 container>
          <Grid2 size={{ xs: 8 }}>
            <Stack>
              <Typography variant="h4">{themaQuery.data.thema.titel}</Typography>
            </Stack>
          </Grid2>
          <Grid2 display="flex" justifyContent="end" size={{ xs: 4 }}>
            <Typography>
              Produkt-Id: {themaQuery.data.thema.produktSapId}
              <br />
              Buchungsnummer: {themaQuery.data.thema.buchungsnummer}
              <br />
              letzte Änderung: {formatDate(themaQuery.data.thema.updatedAt)}
            </Typography>
          </Grid2>
        </Grid2>
      </Paper>
      <VeranstaltungPlanung thema={themaQuery.data.thema} planungsinformationQuery={planungsinformationQuery} />
    </Stack>
  );
};
