import { Alert, AlertTitle, Backdrop, Typography } from '@mui/material';

type ErrorBoundaryProps = {
  readonly error: unknown;
};

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = (props: ErrorBoundaryProps) => {
  const { error } = props;

  let message = 'unknown Error';
  if (error instanceof Error) {
    message = error.message;
  }

  console.error(message, { cause: error });

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <Alert severity="error" variant="filled" sx={{ width: 'fit-content', margin: 'auto' }}>
        <AlertTitle>Ups!</AlertTitle>
        <p>Ein unerwarteter Fehler ist aufgetreten.</p>
        <Typography color="black" fontStyle="italic">
          {message}
        </Typography>
      </Alert>
    </Backdrop>
  );
};
