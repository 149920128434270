import { type AkaVeranstaltungId, type Raum, type Zeitraum } from '../../../../../dtos';
import { type CalendarEvent, CalendarRaumEvent, isGruppenRaumEvent, isStandardRaumEvent, isStandortEvent } from '../CalendarEvent';
import { calendarResourceTypes } from '../calendarResourceTypes';
import { AbstractRaumResource } from './AbstractRaumResource';
import { addDays, eachDayOfInterval, endOfDay, isWithinInterval, startOfDay } from 'date-fns';

/**
 * Represents one Standard-Raum, and one row in the calendar.
 */
export class CalendarStandardRaumResource extends AbstractRaumResource {
  public readonly type = calendarResourceTypes.STANDARDRAUM;

  public readonly id: `r-${string}`;

  public readonly name: string;

  public constructor(raum: Raum) {
    super(raum);
    this.id = `r-${raum.raumSapId}`;
    this.name = `${raum.kuerzel} / ${raum.name} / ${raum.groesse} m² / ${raum.kapazitaetUForm}-${raum.kapazitaetParlamentarisch}-${raum.kapazitaetOhneTische}`;
  }

  public convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarRaumEvent[] {
    const events: CalendarRaumEvent[] = [];

    for (const blockung of this.raum.raumBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const newEvent = new CalendarRaumEvent(start, end, this, {
        ortKuerzel: this.raum.ort.kuerzel,
        quelleTerminId: blockung.akaVeranstaltungId,
        veranstaltung: blockung.veranstaltung,
        zeitraeume: blockung.zeitraeume,
      });

      newEvent.isOldSelection = blockung.akaVeranstaltungId === akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  protected computeNewCalendarEventBounds(date: Date, ablaufTageCount: number): Zeitraum {
    const start = startOfDay(date);
    const end = endOfDay(addDays(date, ablaufTageCount - 1));

    return { start, end };
  }

  public isAvailable(interval: Zeitraum) {
    const days = eachDayOfInterval(interval);
    return days.every((date) =>
      // remove a minute off the end because of non-exclusitivity of mobiscroll events, otherwise the last day would be in interval although it should not
      this.raum.gekaufterZeitraum.some((tag) => isWithinInterval(date, { start: startOfDay(tag.start), end: endOfDay(tag.end.valueOf() - 60_000) })),
    );
  }

  public isEventCompatibleWithResource(otherEvent: CalendarEvent): boolean {
    if (isGruppenRaumEvent(otherEvent)) {
      return this.raum.ort.kuerzel === otherEvent.ortKuerzel;
    }

    if (isStandardRaumEvent(otherEvent)) {
      return false;
    }

    return !isStandortEvent(otherEvent);
  }
}
