import { type CservOnlineTools, cservOnlineTools, type HiveExperteStatus, hiveExperteStatus, onlineToolOrtKuerzel } from '../../dtos';
import { uniqueBy } from '../unique';

type GetStandortVa<T> = {
  standortBlockungen: Array<{
    standort: T;
  }>;
};

function getStandort<T>(veranstaltung: GetStandortVa<T>): T | undefined {
  return veranstaltung.standortBlockungen.at(0)?.standort;
}

type GetOrtVa<R> = {
  raumBlockungen: Array<{
    raum: {
      ort: R;
    };
  }>;
};

function getOrt<T>(veranstaltung: GetOrtVa<T>): T | undefined {
  return veranstaltung.raumBlockungen.at(0)?.raum.ort;
}

type GetOrtNameVa = {
  onlineTool: CservOnlineTools | null;
  standortBlockungen: Array<{
    standort: {
      name: string;
    };
  }>;
  raumBlockungen: Array<{
    raum: {
      ort: {
        name: string;
      };
    };
  }>;
};

export const getOrtName = (veranstaltung: GetOrtNameVa): string | undefined => {
  if (veranstaltung.onlineTool !== null) {
    return 'Online';
  }

  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.name ? ort.name : (standort?.name ?? undefined);
};

type GetOrtKuerzelVa = {
  onlineTool: CservOnlineTools | null;
  standortBlockungen: Array<{
    standort: {
      kuerzel: string;
    };
  }>;
  raumBlockungen: Array<{
    raum: {
      ort: {
        kuerzel: string;
      };
    };
  }>;
};

export const getOrtKuerzel = (veranstaltung: GetOrtKuerzelVa): string | undefined => {
  if (veranstaltung.onlineTool === cservOnlineTools.ZOOM) {
    return onlineToolOrtKuerzel.zoom;
  }

  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.kuerzel ?? standort?.kuerzel ?? undefined;
};

type GetStandortNameVa = {
  standortBlockungen: Array<{
    standort: {
      name: string;
    };
  }>;
  raumBlockungen: Array<{
    raum: {
      ort: {
        standort: {
          name: string;
        } | null;
      };
    };
  }>;
};

export const getStandortName = (veranstaltung: GetStandortNameVa): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.standort?.name ?? standort?.name ?? undefined;
};

type GetStandortKuerzelVa = {
  standortBlockungen: Array<{
    standort: {
      kuerzel: string;
    };
  }>;
  raumBlockungen: Array<{
    raum: {
      ort: {
        standort: {
          kuerzel: string;
        } | null;
      };
    };
  }>;
};

export const getStandortKuerzel = (veranstaltung: GetStandortKuerzelVa): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.standort?.kuerzel ?? standort?.kuerzel ?? undefined;
};

type GetRegionNameVa = {
  onlineTool: CservOnlineTools | null;
  standortBlockungen: Array<{
    standort: {
      region: {
        name: string;
      } | null;
    };
  }>;
  raumBlockungen: Array<{
    raum: {
      ort: {
        region: {
          name: string;
        } | null;
      };
    };
  }>;
};

export const getRegionName = (veranstaltung: GetRegionNameVa): string | undefined => {
  if (veranstaltung.onlineTool !== null) {
    return 'Online';
  }

  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.region?.name ?? standort?.region?.name ?? undefined;
};

type GetExperteNamesVa = {
  experteBlockungen: Array<{
    experteSapId: number;
    experte: {
      vorname: string;
      nachname: string;
      hiveExperteStatus: HiveExperteStatus;
    };
  }>;
};

type GetExperteName = { vorname: string; nachname: string; hiveExperteStatus: HiveExperteStatus };

export const getExperteName = (experte: GetExperteName): string => {
  if (experte.hiveExperteStatus === hiveExperteStatus.BLOCKED) {
    return `${experte.vorname} ${experte.nachname} (gesperrt)`;
  }

  return `${experte.vorname} ${experte.nachname}`;
};

export const getExperteNames = (veranstaltung: GetExperteNamesVa): string | undefined => {
  const uniqueExperteBlockungen = veranstaltung.experteBlockungen.filter(uniqueBy('experteSapId'));
  const uniqueAndAlphabeticallySortedExperteBlockungen = uniqueExperteBlockungen.sort((a, b) => a.experte.vorname.localeCompare(b.experte.vorname));
  const returnString = uniqueAndAlphabeticallySortedExperteBlockungen.map((experteBlockung) => getExperteName(experteBlockung.experte)).join(', ');

  return returnString.length === 0 ? undefined : returnString;
};
