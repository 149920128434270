import { content } from '../../utils';
import { CopyButton } from './CopyButton';
import { Box, Stack } from '@mui/material';

type EmailCellProps = {
  readonly email: string;
};

export const EmailCell: React.FC<EmailCellProps> = ({ email }: EmailCellProps) => {
  if (email === content.noInfoAvailable) {
    return content.noInfoAvailable;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: 0,
        }}
      >
        {email}
      </Box>
      <CopyButton text={email} />
    </Stack>
  );
};
