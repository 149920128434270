import { type ExperteBlockung, hiveTerminanfrageStatus } from '../../../dtos';
import { getLabelAndTooltip, printConflicts } from './StatusTerminanfrageHive.utils';
import { Tooltip } from '@mui/material';

type StatusTerminanfrageHiveProps = {
  readonly experteBlockung: Pick<ExperteBlockung, 'hiveTerminanfrageRejectedReason' | 'hiveTerminanfrageConflicts' | 'hiveTerminanfrageStatus'>;
};

export const StatusTerminanfrageHive: React.FC<StatusTerminanfrageHiveProps> = ({ experteBlockung }: StatusTerminanfrageHiveProps) => {
  const { hiveTerminanfrageRejectedReason: rejectedReason, hiveTerminanfrageConflicts: conflicts, hiveTerminanfrageStatus: status } = experteBlockung;
  let labelAndTooltip: { label: string; tooltip: string } | null = null;

  /*
   * REJECTED
   * In status REJECTED, we are provided with a rejection reason.
   */

  if (status === hiveTerminanfrageStatus.REJECTED) {
    if (rejectedReason === null) {
      throw new Error(`[TerminExperteCard] HiveTerminanfrageStatus was REJECTED, but no rejection reason was given.`);
    }

    labelAndTooltip = getLabelAndTooltip(status, rejectedReason);
  }

  /*
   * CONFLICT
   * In status conflict, we are provided with a list of conflicting Veranstaltung, and additional information about the exact conflicting Zeitraum.
   */

  if (status === hiveTerminanfrageStatus.CONFLICT) {
    if (conflicts === null) {
      throw new Error(`[TerminExperteCard] HiveTerminanfrageStatus was CONFLICT, but no list of conflicts was given.`);
    }

    labelAndTooltip = getLabelAndTooltip(status, printConflicts(conflicts));
  }

  /*
   * OTHER
   * If the status is not rejected or conflict, we don't need any additional information to fill labelAndTooltip
   */

  if (labelAndTooltip === null) {
    labelAndTooltip = getLabelAndTooltip(status, null);
  }

  return (
    <Tooltip title={labelAndTooltip.tooltip} disableInteractive>
      <div>{labelAndTooltip.label}</div>
    </Tooltip>
  );
};
