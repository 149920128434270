import { type SelectedExperte } from '../../../dtos';
import { useDebounce } from '../../../hooks/useDebounce';
import { trpc } from '../../../trpc';
import { getExperteName } from '../../../utils';
import { content } from './ExperteFilter.content';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

type ExperteFilterProps = {
  readonly handleExperteSapIdChange: (experteSapId: number | null) => void;
};

export const ExperteFilter: React.FC<ExperteFilterProps> = ({ handleExperteSapIdChange }: ExperteFilterProps) => {
  const [searchUserInput, setSearchUserInput] = useState<string>('');
  const [selectedExperte, setSelectedExperte] = useState<SelectedExperte | null>(null);
  const debouncedSearchUserInput = useDebounce(searchUserInput);
  const experteQuery = trpc.planung.resources.getExperteByNachnameOrSapId.useQuery(debouncedSearchUserInput, { staleTime: 1_000, enabled: debouncedSearchUserInput.length > 0 });

  const onUserInputChange = (_: React.SyntheticEvent, value: string): void => {
    setSearchUserInput(value.trim());
  };

  const onSelectedExperteChange = useCallback(
    (_: React.SyntheticEvent, value: SelectedExperte | null): void => {
      setSelectedExperte(value);
      handleExperteSapIdChange(value?.experteSapId ?? null);
    },
    [handleExperteSapIdChange],
  );

  if (experteQuery.isError) {
    throw new Error('Fehler beim Suchen nach Experten aufgetreten.', { cause: experteQuery.error });
  }

  return (
    <Autocomplete
      disablePortal
      onInputChange={onUserInputChange}
      sx={{ minWidth: 400 }}
      value={selectedExperte}
      options={experteQuery.data ?? []}
      renderInput={(params) => <TextField {...params} label={content.label} />}
      getOptionLabel={(experte) => `${experte.nachname}, ${experte.vorname}`}
      renderOption={(props, experte) => (
        <li {...props} key={experte.experteSapId}>
          <Stack sx={{ marginLeft: 1, marginBottom: 1 }}>
            <Typography variant="body1">{getExperteName(experte)}</Typography>
            <Typography variant="caption">{`${content.id}${experte.experteSapId}`}</Typography>
          </Stack>
        </li>
      )}
      filterOptions={(x) => x}
      onChange={onSelectedExperteChange}
    />
  );
};
