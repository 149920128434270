import { type ExperteBlockungQuelle, type Geschaeftsbereich } from '../../../../../dtos';
import { type CalendarExperteResource } from '../CalendarResource';
import { calendarResourceTypes } from '../calendarResourceTypes';
import { AbstractCalendarEvent, type BlockungData } from './AbstractCalendarEvent';

type CalendarExperteEventData = BlockungData & {
  /**
   * Experteblockungen have an extra field 'geschaeftsbereich' in their database entries, which is always filled,
   * even when it is not related to a Veranstaltung in our database. This is why there is an extra property here.
   */
  geschaeftsbereich: Geschaeftsbereich;
  quelle: ExperteBlockungQuelle;
};

/**
 * Represents one Experteblockung, and one event in the calendar
 */
export class CalendarExperteEvent extends AbstractCalendarEvent {
  public readonly type = calendarResourceTypes.EXPERTE;

  public readonly blockungData: CalendarExperteEventData;

  public constructor(start: Date, end: Date, calendarResource: CalendarExperteResource, experteBlockungData: CalendarExperteEventData) {
    super(start, end, calendarResource);
    this.blockungData = experteBlockungData;
  }
}
