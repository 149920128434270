import { useDebounce } from '../../../hooks/useDebounce';
import { content } from './DatePickerWrapper.content';
import { type ExcludeWeekendConfig, isBeforeMinDate, isForbiddenWeekendDate, isNull } from './DatePickerWrapper.utils';
import { type SxProps, type Theme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';

type DatePickerWrapperProps = {
  readonly excludeWeekendConfig?: ExcludeWeekendConfig;
  readonly initialDate?: Date | null;
  readonly label: string;
  readonly minDate?: Date;
  readonly onChange: (date: Date | null) => void;
  readonly sx?: SxProps<Theme>;
};

/**
 * The better DatePicker.
 *
 * Features:
 * - debouncing
 * - styles the DatePicker to make it obvious to the user that it's required
 * - keeps the user from violating any rules via mouse input
 * - gives helpful information to the user if they violate any rules with keyboard input
 * - enforces a label
 * - is styleable
 * - can take an initialDate
 *
 * Rules:
 * - calls onChange with null if the user empties out the date via keyboard/backspace
 * - calls onChange with null if the chosen date is before the minDate (only if minDate is defined)
 *   - for disabling past dates, just put in `minDate={new Date()}`
 * - calls onChange with null if the chosen date range would reach into the weekend (only if excludeWeekendConfig is defined)
 * - calls onChange with chosen date (set to start of the day) if everything's fine and dandy
 */
export const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({ excludeWeekendConfig, initialDate, label, minDate, onChange, sx }: DatePickerWrapperProps) => {
  const [date, setDate] = useState<Date | null>(initialDate ?? null);
  const debouncedDate = useDebounce(date);

  const shouldDisableDate = useCallback(
    (passedDate: Date): boolean => isBeforeMinDate(passedDate, minDate) || isForbiddenWeekendDate(passedDate, excludeWeekendConfig),
    [excludeWeekendConfig, minDate],
  );

  const isNullError = isNull(debouncedDate);
  const isBeforeMinDateError = useMemo((): boolean => isBeforeMinDate(debouncedDate, minDate), [debouncedDate, minDate]);
  const isForbiddenWeekendDateError = useMemo((): boolean => isForbiddenWeekendDate(debouncedDate, excludeWeekendConfig), [debouncedDate, excludeWeekendConfig]);
  const error = isNullError || isBeforeMinDateError || isForbiddenWeekendDateError;

  useEffect(() => {
    if (error) {
      onChange(null);
      return;
    }

    const startOfDayDate = new Date(debouncedDate);
    startOfDayDate.setHours(0, 0, 0, 0);
    onChange(startOfDayDate);
  }, [debouncedDate, error, onChange]);

  const helperText = useMemo(() => {
    if (isNullError) {
      return content.isNullError;
    } else if (isBeforeMinDateError) {
      return content.isBeforeMinDateError(minDate);
    } else if (isForbiddenWeekendDateError) {
      return content.isForbiddenWeekendDateError;
    }

    return undefined;
  }, [isBeforeMinDateError, isForbiddenWeekendDateError, isNullError, minDate]);

  return (
    <DatePicker
      label={label}
      minDate={minDate}
      onChange={setDate}
      shouldDisableDate={shouldDisableDate}
      slotProps={{
        textField: {
          sx,
          required: true,
          error,
          helperText,
        },
      }}
      value={date}
      views={['year', 'month', 'day']}
    />
  );
};
