import { VivaPageLoading } from '../../components/general';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Alert, Stack, Typography } from '@mui/material';

export const AuthLogoutPage: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  if (isAuthenticated) {
    void instance.logoutRedirect();
  }

  return isAuthenticated ? (
    <VivaPageLoading msg="logging out ..." />
  ) : (
    <Stack justifyContent="center" alignContent="center" alignItems="center" padding={2} spacing={2} height="100vh" maxWidth="500px" margin="auto">
      <Alert severity="success" sx={{ width: '100%' }}>
        <Typography variant="h6" textAlign="center" width="100%">
          Logout successful
        </Typography>
      </Alert>
      <Typography fontSize={18}>
        {' '}
        You can close the tab or <a href="/">login</a> again
      </Typography>
    </Stack>
  );
};
