import { VaStatusChip } from '../../components/general';
import { type Veranstaltung } from '../../dtos';
import { formatDate, getExperteName, getIsOnlineVeranstaltung, getOrtKuerzel, getOrtName, getRegionName } from '../../utils';
import { Apartment, CalendarToday, Groups2, Person, PersonalVideo, Place } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';
import { type ReactElement } from 'react';

type TerminInfoCardProps = {
  readonly veranstaltung: Veranstaltung;
};

export const TerminInfoCard: React.FC<TerminInfoCardProps> = ({ veranstaltung }: TerminInfoCardProps) => {
  const firstExperteBlockung = veranstaltung.experteBlockungen.at(0);

  const isOnlineVeranstaltung = getIsOnlineVeranstaltung(veranstaltung);

  const renderLocation = (): ReactElement => {
    if (isOnlineVeranstaltung) {
      return (
        <Stack direction="row" spacing={1}>
          <PersonalVideo sx={{ margin: 'auto !important' }} />
          <Stack>
            <Typography>Zoom</Typography>
          </Stack>
        </Stack>
      );
    }

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Place sx={{ margin: 'auto !important' }} />
          <Stack>
            <Typography>{getRegionName(veranstaltung) ?? '-'}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Apartment sx={{ margin: 'auto !important' }} />
          <Stack>
            <Typography>{getOrtKuerzel(veranstaltung) ?? '-'}</Typography>
            <Typography>{getOrtName(veranstaltung) ?? '-'}</Typography>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <Paper elevation={1} sx={{ bgcolor: '#D7E2F5' }}>
      <Stack>
        <Typography color="primary" paddingLeft={2} paddingTop={2}>
          Veranstaltung
        </Typography>
        <Stack direction="row" spacing={2} padding={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <CalendarToday sx={{ margin: 'auto !important' }} />
            <Stack>
              <Typography>Start: {formatDate(veranstaltung.ablauf.at(0)?.start)}</Typography>
              <Typography>Ende: {formatDate(veranstaltung.ablauf.at(-1)?.end)}</Typography>
            </Stack>
          </Stack>
          <VaStatusChip sapStatus={veranstaltung.sapStatus} vivaStatus={veranstaltung.vivaStatus} />
          <Stack>
            <Typography>SAP-ID (E): {veranstaltung.veranstaltungSapId ?? '-'}</Typography>
            <Typography>AKA-Veranstaltung-ID: {veranstaltung.akaVeranstaltungId}</Typography>
          </Stack>
          {renderLocation()}
          <Stack direction="row" spacing={1}>
            <Person sx={{ margin: 'auto !important' }} />
            <Typography>{firstExperteBlockung ? getExperteName(firstExperteBlockung.experte) : '-'}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Groups2 sx={{ margin: 'auto !important' }} />
            <Stack>
              <Typography>- gebucht</Typography>
              <Typography>- verfügbar</Typography>
              <Typography>- Warteliste</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
