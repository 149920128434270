import { type VeranstaltungCustomProperties } from '../../../../../dtos';
import { type CalendarEvent, isExperteEvent, isStandardRaumEvent, isStandortEvent } from '../../../ResourceCalendar';
import { content } from './ActionButtons.content';
import { Cancel, Fullscreen, Save } from '@mui/icons-material';
import { Button, Grid2, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';

type ActionButtonsProps = {
  readonly isFullscreen: boolean;
  readonly isOnlineThema: boolean;
  readonly selectedCalendarEvents: CalendarEvent[];
  readonly veranstaltungCustomProperties: Pick<VeranstaltungCustomProperties, 'beginDate' | 'withoutResources'>;
  readonly onCancel: () => Promise<void>;
  readonly onFullscreenToggle: () => void;
  readonly onSave: () => Promise<void>;
};

const useSaveButtonState = (
  isOnlineThema: boolean,
  isSaving: boolean,
  selectedCalendarEvents: CalendarEvent[],
  veranstaltungCustomProperties: Pick<VeranstaltungCustomProperties, 'beginDate' | 'withoutResources'>,
): {
  isSaveButtonDisabled: boolean;
  displaySaveTooltip: string | null;
} =>
  useMemo(() => {
    const raumSelectionExists = selectedCalendarEvents.some(isStandardRaumEvent);
    const standortSelectionExists = selectedCalendarEvents.some(isStandortEvent);
    const experteSelectionExists = selectedCalendarEvents.some(isExperteEvent);

    if (isOnlineThema && veranstaltungCustomProperties.withoutResources && !veranstaltungCustomProperties.beginDate) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.missingStartDate };
    }

    if (isOnlineThema && !veranstaltungCustomProperties.withoutResources && !experteSelectionExists) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.missingExpert };
    }

    if (!isOnlineThema && !raumSelectionExists && !standortSelectionExists) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.missingRaumOrStandort };
    }

    if (isSaving) {
      return { isSaveButtonDisabled: true, displaySaveTooltip: content.tooltips.processing };
    }

    return { isSaveButtonDisabled: false, displaySaveTooltip: null };
  }, [isOnlineThema, isSaving, selectedCalendarEvents, veranstaltungCustomProperties]);

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  selectedCalendarEvents,
  isFullscreen,
  isOnlineThema,
  veranstaltungCustomProperties,
  onCancel,
  onFullscreenToggle,
  onSave,
}: ActionButtonsProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { isSaveButtonDisabled, displaySaveTooltip } = useSaveButtonState(isOnlineThema, isSaving, selectedCalendarEvents, veranstaltungCustomProperties);

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
  };

  return (
    <Grid2 size={{ xs: isFullscreen ? 6 : 12 }} display="flex" justifyContent="right" gap={5}>
      <Button onClick={onCancel} size="small" startIcon={<Cancel />} color="error" sx={{ borderRadius: 1 }} variant="outlined">
        {content.buttons.cancel}
      </Button>
      <Button size="small" startIcon={<Fullscreen />} sx={{ borderRadius: 1 }} variant="outlined" onClick={onFullscreenToggle}>
        {isFullscreen ? content.buttons.min : content.buttons.max}
      </Button>
      <Tooltip arrow placement="top" title={displaySaveTooltip} data-testid="actionButtonsTooltip">
        <div>
          <Button startIcon={<Save />} size="small" onClick={handleSave} color="success" variant="contained" disabled={isSaveButtonDisabled} sx={{ borderRadius: 1, boxShadow: 1 }}>
            {content.buttons.save}
          </Button>
        </div>
      </Tooltip>
    </Grid2>
  );
};
