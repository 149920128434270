import { type CalendarStandortResource } from '../CalendarResource';
import { calendarResourceTypes } from '../calendarResourceTypes';
import { AbstractCalendarEvent, type BlockungData } from './AbstractCalendarEvent';

type CalendarStandortEventData = BlockungData & {
  gruppenraumNotwendig: number[] | null;
  raumgroesse: number | null;
};

/**
 * Represents one Standortblockung, and one event in the calendar
 */
export class CalendarStandortEvent extends AbstractCalendarEvent {
  public readonly type = calendarResourceTypes.STANDORT;

  public readonly blockungData: CalendarStandortEventData;

  public constructor(start: Date, end: Date, calendarResource: CalendarStandortResource, standortBlockungData: CalendarStandortEventData) {
    super(start, end, calendarResource);
    this.blockungData = standortBlockungData;
  }
}
