export const content = {
  REGION: 'Region:',
  STANDORT: 'Standort:',
  ORT: 'Ort:',
  GRUPPENRAUM_AM_ORT: 'Gruppenraum am Ort:',
  VORHANDEN: 'vorhanden',
  NICHT_VORHANDEN: 'nicht vorhanden',
  KAPAZITAET: 'Kapazität:',
  U_FORM: '• U-Form:',
  PARLAMENTARISCH: '• Parlamentarisch:',
  OHNE_TISCH: '• ohne Tisch:',
  JAHR: 'Jahr:',
  GEBUCHTE_TAGE: 'Gebuchte Tage:',
  VORGEMERKTE_TAGE: 'Vorgemerkte Tage:',
  SUMME_TAGE: 'Summe Tage:',
  SCHWELLE_GELB: 'Schwellwert gelb:',
  SCHWELLE_ROT: 'Schwellwert rot:',
  KEINE_AUSLASTUNGSDATEN: 'Keine Auslastungsdaten für das ausgewählte Kalenderjahr verfügbar. Bitte prüfe HIVE für Details!',
  NO_INFO_AVAILABLE: '-',
} as const;
