import { type AkaVeranstaltungId } from '../../../../dtos';
import { Button, type ButtonProps } from '@mui/material';
import { Link } from 'react-router';

type VeranstaltungEventButtonProps = Pick<ButtonProps, 'children'> & {
  readonly eventColor: string;
  readonly akaVeranstaltungId: AkaVeranstaltungId;
};

export const VeranstaltungEventButton: React.FC<VeranstaltungEventButtonProps> = ({ akaVeranstaltungId, eventColor, children }: VeranstaltungEventButtonProps) => (
  <Button
    component={Link}
    to={`/veranstaltung/${akaVeranstaltungId}`}
    target="_blank"
    variant="contained"
    size="small"
    sx={{ borderRadius: 1, width: '100%', minHeight: '1.5rem', color: 'white !important', backgroundColor: eventColor }}
  >
    {children}
  </Button>
);
