import { type CheckHealthResult, healthLevel, type Veranstaltung } from '../../../dtos';
import { trpc } from '../../../trpc';
import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material';
import { Box } from '@mui/material';
import { type GridRenderCellParams } from '@mui/x-data-grid-pro';
import { type ReactElement } from 'react';

export const FehlerCell: React.FC<GridRenderCellParams<Veranstaltung>> = (params: GridRenderCellParams<Veranstaltung>) => {
  const healthQuery = trpc.veranstaltung.getHealthByVeranstaltung.useQuery({ veranstaltungId: params.row.id });

  const getIcon = (health: CheckHealthResult[]): ReactElement => {
    if (health.some(({ type }) => type === healthLevel.ERROR)) {
      return <Cancel color="error" />;
    }

    if (health.some(({ type }) => type === healthLevel.WARNING)) {
      return <Warning color="warning" />;
    }

    if (health.some(({ type }) => type === healthLevel.INFO)) {
      return <Info color="info" />;
    }

    return <CheckCircle color="success" />;
  };

  // TODO: Error Handling (needs some Absprache with Laura)
  if (healthQuery.isLoading || !healthQuery.data) {
    return <p>...loading...</p>;
  }

  const icon = getIcon(healthQuery.data);

  return (
    <Box display="flex" justifyContent="center" height="100%" alignItems="center">
      {icon}
    </Box>
  );
};
