import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, debounceTime: number = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, debounceTime);
    return (): void => clearTimeout(handler);
  }, [debounceTime, value]);

  return debouncedValue;
};
