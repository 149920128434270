import { AccordionWithHeading, ContentLoading, Tablelike } from '../../../components/general';
import { type GetPlanungsinformationOutput } from '../../../dtos';
import { type TerminProps } from '../../../utils';
import { content } from './TerminAnsprechpartnerCard.content';
import { printFachkraft, printPM, printTeamPlanning } from './TerminAnsprechpartnerCard.utils';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';

export const TerminAnsprechpartnerCard: React.FC<TerminProps> = (props: TerminProps) => {
  const { planungsinformationQuery } = props;

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    return (
      <Draggable id={content.draggableId}>
        <AccordionWithHeading heading={content.heading}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack sx={{ paddingTop: 2 }}>
          <Tablelike
            xsLeft={6}
            rows={[
              [content.fields.pm, printPM(planungsinformation)],
              [content.fields.fachkraft, printFachkraft(planungsinformation)],
              [content.fields.teamPlanning, printTeamPlanning(planungsinformation)],
            ]}
          />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
