import { content as utilsContent } from '../../../../../utils';

export const content = {
  tooltips: {
    missingStartDate: 'Sie haben noch kein Startdatum für die Veranstaltung ausgewählt.',
    missingExpert: 'Sie haben noch keine Trainer:innen ausgewählt.',
    missingRaumOrStandort: 'Bitte wählen Sie einen Raum oder Standort aus.',
    processing: utilsContent.tooltips.processing,
  },
  buttons: {
    cancel: 'Abbrechen',
    min: 'Ansicht min',
    max: 'Ansicht max',
    save: 'Speichern',
  },
} as const;
