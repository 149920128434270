import { AccordionWithHeading, ContentLoading, EmailCell, GesellschaftCell } from '../../../components/general';
import { type GetPlanungsinformationOutput } from '../../../dtos';
import { type DataGridColDef, printWithNoInfo, type TerminProps } from '../../../utils';
import { ExperteNameCell } from './ExperteNameCell';
import { ExpertePoolCell } from './ExpertePoolCell';
import { content } from './TerminExpertepoolCard.content';
import {
  clusterField,
  nextYear,
  printAuslastungAktuellesJahrLabel,
  printAuslastungForJahr,
  printCluster,
  printIndividuelleProzentverteilung,
  printName,
  thisYear,
} from './TerminExpertepoolCard.utils';
import { Draggable } from '@mobiscroll/react';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

const columns: DataGridColDef<GetPlanungsinformationOutput['expertePool'][number]> = [
  {
    field: 'name',
    headerName: content.columnLabels.name,
    flex: 1,
    valueGetter: (_value, row) => printName(row),
    renderCell: (params) => <ExperteNameCell expertePlanung={params.row} />,
  },
  { field: 'anrede', headerName: content.columnLabels.anrede, flex: 1, valueGetter: (_value, row) => printWithNoInfo(row.experte?.anrede) },
  { field: 'titel', headerName: content.columnLabels.titel, flex: 1, valueGetter: (_value, row) => printWithNoInfo(row.experte?.titel) },
  {
    field: clusterField,
    headerName: content.columnLabels.cluster,
    flex: 1,
    valueGetter: (_value, row) => printCluster(row.clusterzuordnung),
    renderCell: (params) => <ExpertePoolCell clusterzuordnung={params.row.clusterzuordnung} />,
  },
  {
    field: 'individuelleProzentverteilung',
    headerName: content.columnLabels.individuelleProzentverteilung,
    flex: 1,
    valueGetter: (_value, row) => printIndividuelleProzentverteilung(row),
  },
  { field: 'auslastungDiesesJahr', headerName: printAuslastungAktuellesJahrLabel(thisYear), flex: 1, valueGetter: (_value, row) => printAuslastungForJahr(thisYear, row) },
  { field: 'auslastungNaechstesJahr', headerName: printAuslastungAktuellesJahrLabel(nextYear), flex: 1, valueGetter: (_value, row) => printAuslastungForJahr(nextYear, row) },
  { field: 'einsatzort', headerName: content.columnLabels.einsatzort, flex: 1, valueGetter: (_value, row) => printWithNoInfo(row.einsatzort) },
  {
    field: 'ausschlusszeiten',
    headerName: content.columnLabels.ausschlusszeiten,
    flex: 1,
    valueGetter: (_value, row) => printWithNoInfo(row.ausschlusszeiten),
  },
  {
    field: 'gesellschaftId',
    headerName: content.columnLabels.gesellschaft,
    flex: 1,
    valueGetter: (_event, row) => (typeof row.experte?.gesellschaftId === 'string' && row.experte.gesellschaftId.length > 0 ? content.ja : content.nein),
    renderCell: (params) => <GesellschaftCell gesellschaftId={params.row.experte?.gesellschaftId} />,
  },
  {
    field: 'email',
    headerName: content.columnLabels.email,
    flex: 1,
    valueGetter: (_event, row) => printWithNoInfo(row.experte?.email),
    renderCell: (params) => <EmailCell email={printWithNoInfo(params.row.experte?.email)} />,
  },
  {
    field: 'sapId',
    headerName: content.columnLabels.sapId,
    flex: 1,
    valueGetter: (_value, row) => printWithNoInfo(row.experteSapId),
  },
];

export const TerminExpertepoolCard: React.FC<TerminProps> = (props: TerminProps) => {
  const { planungsinformationQuery } = props;

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    return (
      <Draggable id={content.draggableId}>
        <AccordionWithHeading heading={content.heading}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading} subheading={content.subheading + ' ' + printWithNoInfo(planungsinformation.clusteranteil)}>
        <DataGridPro
          columns={columns}
          rows={planungsinformation.expertePool}
          pagination
          pageSizeOptions={[5, 10, 50, 100]}
          initialState={{
            sorting: { sortModel: [{ sort: 'asc', field: clusterField }] },
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          disableRowSelectionOnClick
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
          getRowId={(expertePlanung) => expertePlanung.experteSapId}
        />
      </AccordionWithHeading>
    </Draggable>
  );
};
