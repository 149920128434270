import { CreateVeranstaltungSuccessSnackbar } from '../../components/snackbars';
import { StyledMaterialDesignContent } from '../../theme/lightHouseTheme';
import { BackendReachable } from './BackendReachable';
import { Footer, FOOTER_HEIGHT } from './Footer';
import { Header, HEADER_HEIGHT } from './Header';
import { WithAuth } from './WithAuth';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { de } from 'date-fns/locale/de';
import { SnackbarProvider } from 'notistack';
import { Outlet } from 'react-router';

const CONTENT_HEIGHT = 100 - HEADER_HEIGHT - FOOTER_HEIGHT;

export const LayoutWithAuth: React.FC = () => (
  <WithAuth>
    <BackendReachable>
      <Box height="100vh" width="100vw">
        <Header />
        <SnackbarProvider
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            createVeranstaltungSuccess: CreateVeranstaltungSuccessSnackbar,
            // Custom Snackbar: hang in your custom snackbar component here!
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={5_000}
          maxSnack={3}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <Box sx={{ height: `${CONTENT_HEIGHT}vh`, overflowX: 'hidden', overflowY: 'scroll', maxWidth: '95vw', margin: 'auto' }}>
              <Outlet />
            </Box>
          </LocalizationProvider>
        </SnackbarProvider>
        <Box sx={{ position: 'absolute', bottom: '0px', width: '100%' }}>
          <Footer />
        </Box>
      </Box>
    </BackendReachable>
  </WithAuth>
);
