import { type SearchOption, searchOption } from '../SearchOption.types';
import { content } from './SearchOptionSelector.content';
import { FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';

type SearchOptionSelectorProps = {
  readonly selectedSearchOption: SearchOption;
  readonly onChange: (newSearchOption: SearchOption) => void;
};

export const SearchOptionSelector: React.FC<SearchOptionSelectorProps> = ({ selectedSearchOption, onChange }: SearchOptionSelectorProps) => (
  <RadioGroup row name="homepage-suche-radio-buttons" value={selectedSearchOption} onChange={(event) => onChange(event.target.value as SearchOption)}>
    <Tooltip disableInteractive title={<Typography variant="caption">{content.THEMA_TOOLTIP}</Typography>}>
      <FormControlLabel value={searchOption.THEMA} control={<Radio />} label="Thema" />
    </Tooltip>
    <Tooltip disableInteractive title={<Typography variant="caption">{content.VERANSTALTUNG_TOOLTIP}</Typography>}>
      <FormControlLabel value={searchOption.VERANSTALTUNG} control={<Radio />} label="Veranstaltung" />
    </Tooltip>
  </RadioGroup>
);
