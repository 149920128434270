// from the mobiscroll docs https://mobiscroll.com/docs/react/getting-started/installation#use-the-components
// necessary because "For the components to work, you will also need to import the css file
// of the installed library under the node_modules folder (at @mobiscroll/react/dist/css/mobiscroll.min.css)."
// eslint-disable-next-line no-restricted-imports
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../../../assets/styles/mobiscroll.css';
import { type Zeitraum } from '../../../dtos';
import { BackendConfigContext } from '../../../utils';
import { type CalendarEvent, type CalendarResource, type CalendarResourceType } from './helperClasses';
import { DisplayCalendarEvent, DisplayCalendarResource } from './helperComponents';
import { ResourceCalendarHeader } from './ResourceCalendarHeader';
import {
  Eventcalendar,
  localeDe,
  type MbscCalendarColor,
  type MbscCellClickEvent,
  type MbscEventClickEvent,
  type MbscPageChangeEvent,
  type MbscResource,
  setOptions,
} from '@mobiscroll/react';
import { useContext, useState } from 'react';

type ResourceCalendarProps = {
  readonly resources: MbscResource[];
  readonly events: CalendarEvent[];
  readonly colors: MbscCalendarColor[];
  readonly isFullscreen: boolean;
  readonly year: number;
  readonly toggleExpansion: (resourceType: CalendarResourceType, expand: boolean) => void;
  readonly initialDate: Date;
  readonly timeRangeChangeHandler: (timeRange: Zeitraum) => void;
  readonly cellClickHandler?: (event: MbscCellClickEvent) => void;
  readonly eventClickHandler?: (event: MbscEventClickEvent) => void;
};

setOptions({
  locale: localeDe,
  theme: 'material',
  themeVariant: 'light',
});

export const ResourceCalendar: React.FC<ResourceCalendarProps> = ({
  resources,
  events,
  colors,
  isFullscreen,
  year,
  toggleExpansion,
  initialDate,
  timeRangeChangeHandler,
  cellClickHandler,
  eventClickHandler,
}: ResourceCalendarProps) => {
  const backendConfig = useContext(BackendConfigContext);
  const [weekCount, setWeekCount] = useState<number>();

  /**
   * Mobiscroll's Eventcalender callbacks like onPageChange or onPageLoading are implemented synchronously instead of asynchronously.
   * In consequence, calling a callback function (in this case timeRangeChangeHandler) which would lead to a rerender of the parent
   * component leads to cyclic render issues.
   * To prevent those cyclic render issues, we need to turn the sync function into an async function (so that it is executed after the render has finished).
   * This is done by using a setTimout with a timeout of zero.
   * Note: Cyclic render issues are only ever visible in local execution mode when React is using strict mode.
   */
  const pageChangeHandler = ({ firstDay, lastDay }: MbscPageChangeEvent): void => {
    setTimeout(() => {
      timeRangeChangeHandler({ start: firstDay, end: lastDay });
    }, 0);
  };

  return (
    <Eventcalendar
      renderHeader={() => <ResourceCalendarHeader onViewSizeChange={(value: number) => setWeekCount(value)} />}
      colors={colors}
      data={events}
      resources={resources}
      defaultSelectedDate={initialDate}
      locale={localeDe}
      onCellClick={cellClickHandler}
      onEventClick={eventClickHandler}
      onPageChange={pageChangeHandler}
      onResourceExpand={(expandEvent): void => toggleExpansion(expandEvent.resource as CalendarResourceType, false)}
      onResourceCollapse={(expandEvent): void => toggleExpansion(expandEvent.resource as CalendarResourceType, true)}
      showEventTooltip={false}
      renderResource={(resource: CalendarResource) => <DisplayCalendarResource resource={resource} year={year} hiveUrl={backendConfig.HiveAppUrl} />}
      event
      renderScheduleEvent={(event) => <DisplayCalendarEvent event={event.original as CalendarEvent} />}
      theme="material"
      themeVariant="light"
      height={isFullscreen ? '80vh' : 'calc(86vh - 240px)'} // we like magic numbers :)
      view={{
        timeline: {
          type: 'week',
          resolutionHorizontal: 'day',
          size: weekCount,
          weekNumbers: true,
          startDay: 1,
          endDay: 5,
          columnWidth: 'medium',
        },
      }}
    />
  );
};
