import { content as terminContent } from '../../../utils';

export const content = {
  ...terminContent,
  draggableId: 'experte',
  heading: 'Trainer:innen',
  fields: {
    name: 'Name:',
    rolle: 'Rolle:',
    sapId: 'SapId:',
    zeitraum: 'Zeitraum:',
    gesellschaft: 'Gesellschaft:',
    email: 'E-Mail:',
    manuelleTerminanfrage: 'Manuelle Terminanfrage:',
    statusTerminanfrageHive: 'Status Terminanfrage (Hive):',
  },
} as const;
