import { vivaUserScopesArraySchema } from '../../dtos';
import { initStorageAPI } from './initStorageApi';
import { z, type ZodSchema } from 'zod';

/*
 * This file is a type safe wrapper for accessing the sessionStorage. It only sets and gets the right
 * type of items for their respective item keys.
 *
 * We are aware that the typing in this file might seem  a liiiittle bit overly complicated, compared
 * to the actual use case ;)
 *
 * However, it is a pretty example for introducing tyoe testing into the code, as per our
 * Tech Talk of 09.10.2024 (https://mywiki.grp.haufemg.com/pages/viewpage.action?pageId=234744664#BeschlüssederEntwickler-09.10.2024).
 *
 * See File sessionStorage.test-d.ts :)
 *
 * So we decided to let it be as complicated as it is, so we can have that pretty example.
 */

const sessionStorageKeys = {
  X_VIVA_ADMIN_SCOPES: 'X-Viva-Admin-Scopes',
  LATEST_X_VIVA_REQUEST_ID: 'latest-X-Viva-Request-Id',
  RAUMRESOURCEOVERVIEW_FILTER: 'raumResourceOverviewFilter',
} as const;

type SessionStorageKeys = (typeof sessionStorageKeys)[keyof typeof sessionStorageKeys];

const xVivaAdminScopesStorageItemSchema = z.object({
  key: z.literal(sessionStorageKeys.X_VIVA_ADMIN_SCOPES),
  value: vivaUserScopesArraySchema,
});

const latestXVivaRequestIdStorageItemSchema = z.object({
  key: z.literal(sessionStorageKeys.LATEST_X_VIVA_REQUEST_ID),
  value: z.string().uuid(),
});

const raumResourceOverviewFilterItemSchema = z.object({
  key: z.literal(sessionStorageKeys.RAUMRESOURCEOVERVIEW_FILTER),
  value: z.object({
    region: z.array(z.number()),
    standort: z.array(z.number()),
    orte: z.array(z.number()),
    raumgroesse: z.number().nullable(),
    ortHatGruppenraum: z.boolean().nullable(),
  }),
});

const sessionStorageApi = initStorageAPI(sessionStorage, {
  [sessionStorageKeys.X_VIVA_ADMIN_SCOPES]: xVivaAdminScopesStorageItemSchema,
  [sessionStorageKeys.LATEST_X_VIVA_REQUEST_ID]: latestXVivaRequestIdStorageItemSchema,
  [sessionStorageKeys.RAUMRESOURCEOVERVIEW_FILTER]: raumResourceOverviewFilterItemSchema,
} satisfies Record<SessionStorageKeys, ZodSchema>);

export { sessionStorageApi, sessionStorageKeys };
