import { EasyBreadcrumbs, type EasyBreadcrumbsItem, TrpcLoadingInfo } from '../../components/general';
import { type GetManuelleTerminanfragenInput, type GetManuelleTerminanfragenOutput, type SapStatusArray, type VivaStatusArray } from '../../dtos';
import { trpc } from '../../trpc';
import { copyToClipboard } from '../../utils';
import { DateFilter } from './DateFilter';
import { ExperteFilter } from './ExperteFilter';
import { StartButton } from './StartButton';
import { defaultSapStatus, defaultVivaStatus, StatusFilter } from './StatusFilter';
import { content } from './TerminanfragenManuellPage.content';
import { ThemaFilter } from './ThemaFilter';
import { generateClipboardTextForThema, ThemaRow } from './ThemaRow';
import { Box, Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';

const copyAllThemenToClipboard = async (themen: GetManuelleTerminanfragenOutput): Promise<void> => {
  const appointmentCopies = themen.map((thema) => generateClipboardTextForThema(thema));

  const copyAllString = appointmentCopies.join('\n<br/>\n');

  await copyToClipboard(copyAllString, 'text/html');
};

export const TerminanfragenManuellPage: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [vivaStatus, setVivaStatus] = useState<VivaStatusArray>(defaultVivaStatus);
  const [sapStatus, setSapStatus] = useState<SapStatusArray>(defaultSapStatus);

  const [experteSapId, setExperteSapId] = useState<number | null>(null);
  const [produktSapId, setProduktSapId] = useState<number | null>(null);
  const [buchungsnummer, setBuchungsnummer] = useState<string | null>(null);

  const [queryParams, setQueryParams] = useState<GetManuelleTerminanfragenInput | null>(null);

  const getManuelleTerminanfragenQuery = trpc.reporting.getManuelleTerminanfragen.useQuery(queryParams as GetManuelleTerminanfragenInput, { enabled: queryParams !== null });

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/terminanfragen/manuell',
      label: 'Manuelle Terminanfragen - Datenbereitstellung',
    },
  ];

  const onStartClick = async (): Promise<void> => {
    if (!startDate || !endDate || startDate > endDate || (produktSapId && buchungsnummer)) {
      throw new Error('The user should not have been able to do that.');
    }

    setQueryParams({
      vivaStatus,
      sapStatus,
      startDate,
      endDate,
      buchungsnummer,
      experteSapId,
      produktSapId,
    });
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <EasyBreadcrumbs items={breadcrumbs} />
      <Card>
        <CardContent>
          <Typography variant="h5">{content.pageTitel}</Typography>
          <Divider />
          <form
            onSubmit={(event) => {
              event.preventDefault();
              void onStartClick();
            }}
          >
            <Stack spacing={2} sx={{ marginTop: 2, height: 80 }} direction="row">
              <DateFilter handleStartDateChange={setStartDate} handleEndDateChange={setEndDate} />
              <StatusFilter handleVivaStatusChange={setVivaStatus} handleSapStatusChange={setSapStatus} />
            </Stack>
            <Stack sx={{ marginTop: 2 }} spacing={2} direction="row">
              <ThemaFilter handleBuchungsnummerChange={setBuchungsnummer} handleProduktSapIdChange={setProduktSapId} />
              <ExperteFilter handleExperteSapIdChange={setExperteSapId} />
              <StartButton
                buchungsnummer={buchungsnummer}
                produktSapId={produktSapId}
                startDate={startDate}
                endDate={endDate}
                isProcessing={getManuelleTerminanfragenQuery.isLoading && getManuelleTerminanfragenQuery.isFetching}
              />
            </Stack>
          </form>
        </CardContent>
      </Card>
      {getManuelleTerminanfragenQuery.data && getManuelleTerminanfragenQuery.data.length > 0 && (
        <Stack sx={{ marginTop: 1 }} direction="row" justifyContent="end">
          <Button onClick={async () => await copyAllThemenToClipboard(getManuelleTerminanfragenQuery.data)}>{content.copyButton}</Button>
        </Stack>
      )}
      <Box>
        <Stack direction="column" spacing={2} sx={{ marginTop: 2 }}>
          <TrpcLoadingInfo trpcQuery={getManuelleTerminanfragenQuery} entity="Ergebnisse">
            {getManuelleTerminanfragenQuery.data?.map((thema) => <ThemaRow key={thema.produktSapId} thema={thema} />)}
          </TrpcLoadingInfo>
        </Stack>
      </Box>
    </Box>
  );
};
