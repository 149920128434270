import { type AppRouter } from '@hg-akademie-ban/vamos-viva-main';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import { z } from 'zod';

// Some of the hard-coded stuff in here is by no means "ideal" - however, it is a step up from randomly importing things from the backend.
// So, if you have better ideas, feel free ;)

/*
 * Helpers
 */

type RouterInput = inferRouterInputs<AppRouter>;
type RouterOutput = inferRouterOutputs<AppRouter>;

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends ReadonlyArray<infer ElementType> ? ElementType : never;

type NonNullable<T> = Exclude<T, null | undefined>;

type Replace<
  SourceString extends string,
  SearchString extends string,
  ReplaceString extends string,
  AccumulatedResult extends string = '',
> = SourceString extends `${infer Left}${SearchString}${infer Right}`
  ? Replace<Right, SearchString, ReplaceString, `${AccumulatedResult}${Left}${ReplaceString}`>
  : `${AccumulatedResult}${SourceString}`;

export type Keyify<TKey extends string> = Uppercase<Replace<TKey, ' ' | '-', '_'>>;

/*
 * Router
 */

// search

export type SearchThemaInput = RouterInput['search']['searchThema'];
export type SearchThemaOutput = RouterOutput['search']['searchThema'];

export type SearchVeranstaltungOutput = RouterOutput['search']['searchVeranstaltungen'];
export type SearchVeranstaltungOutputItem = ArrayElement<SearchVeranstaltungOutput>;

export type ThemaFromSearchRouter = ArrayElement<SearchThemaOutput>;

// thema

export type GetThemaByIdInput = RouterInput['thema']['getThemaById'];
export type GetThemaByIdOutput = RouterOutput['thema']['getThemaById'];
export type GetTasksByAkaProduktIdInput = RouterInput['thema']['getTasksByAkaProduktId'];
export type GetTasksByAkaProduktIdOutput = RouterOutput['thema']['getTasksByAkaProduktId'];

export type ThemaFromThemaRouter = GetThemaByIdOutput['thema'];
export type PlanungsinformationFromThemaRouter = NonNullable<ThemaFromThemaRouter['planungsinformation']>;
export type AblaufTagTemplate = PlanungsinformationFromThemaRouter['ablaufTageTemplate'];
export type Sitzordnung = NonNullable<PlanungsinformationFromThemaRouter['sitzordnung']>;
export type Experteplanung = ArrayElement<NonNullable<PlanungsinformationFromThemaRouter['expertePool']>>;

export type TaskListItem = ArrayElement<GetTasksByAkaProduktIdOutput>;
export type TaskStatus = TaskListItem['taskStatus'];
export type TaskType = TaskListItem['taskType'];

export const taskStatus = {
  ANGELEGT: 'angelegt',
  IN_ARBEIT: 'in arbeit',
  WARTEND: 'wartend',
  ABGESCHLOSSEN: 'abgeschlossen',
} as const satisfies Record<Keyify<TaskStatus>, TaskStatus>;

export const taskTypes = {
  VERANSTALTUNGEN_UMPLANEN: 'Veranstaltungen umplanen',
  VERANSTALTUNGEN_FREIGEBEN: 'Veranstaltungen freigeben',
  TRAINER_ANMAHNEN: 'Trainer anmahnen',
  MANUELLER_TASK: 'Manueller Task',
} as const satisfies Record<Keyify<TaskType>, TaskType>;

export type Clusterzuordnung = NonNullable<Experteplanung['clusterzuordnung']>;

export const sitzordnung = {
  FISCHGRAET: 'FISCHGRAET',
  KINOBESTUHLUNG: 'KINOBESTUHLUNG',
  OHNETISCHE: 'OHNETISCHE',
  PARLAMENTARISCH: 'PARLAMENTARISCH',
  RAUMABHAENGIG: 'RAUMABHAENGIG',
  RAUMSKIZZE: 'RAUMSKIZZE',
  STUHLHALBKREIS: 'STUHLHALBKREIS',
  STUHLKREIS: 'STUHLKREIS',
  TEILNEHMERABHAENGIG: 'TEILNEHMERABHAENGIG',
  UFORM: 'UFORM',
  UFORMBREITETISCHE: 'UFORMBREITETISCHE',
  ZWEIERTISCHGRUPPE: 'ZWEIERTISCHGRUPPE',
  VIERERTISCHGRUPPE: 'VIERERTISCHGRUPPE',
  SECHSERTISCHGRUPPE: 'SECHSERTISCHGRUPPE',
  TISCHBLOCK: 'TISCHBLOCK',
  PARLAMENTARISCHMITPC: 'PARLAMENTARISCHMITPC',
  ONLINE: 'ONLINE',
} as const satisfies Record<Keyify<Sitzordnung>, Sitzordnung>;

export const clusterzuordnung = {
  HAUPTTRAINER: 'haupttrainer', // cluster A
  NEBENTRAINER: 'nebentrainer', // cluster B
  ERSATZTRAINER: 'ersatztrainer', // cluster C
} as const satisfies Record<Keyify<Clusterzuordnung>, Clusterzuordnung>;

export type ExperteFromThemaRouter = NonNullable<Experteplanung['experte']>;
export type CservFormat = ThemaFromThemaRouter['strukturellesFormat'];

export const cservFormat = {
  STANDARD_SEMINAR: 551,
  ONLINE_KURS: 569,
} as const satisfies Record<string, CservFormat>;

// veranstaltung
export type GetVeranstaltungByAkaVeranstaltungIdInput = RouterInput['veranstaltung']['getVeranstaltungByAkaVeranstaltungId'];
type GetVeranstaltungByAkaVeranstaltungIdOutput = RouterOutput['veranstaltung']['getVeranstaltungByAkaVeranstaltungId'];

export type Veranstaltung = GetVeranstaltungByAkaVeranstaltungIdOutput;

export type AkaVeranstaltungId = GetVeranstaltungByAkaVeranstaltungIdOutput['akaVeranstaltungId'];

export type ExperteBlockung = ArrayElement<Veranstaltung['experteBlockungen']>;
export type RaumBlockung = ArrayElement<Veranstaltung['raumBlockungen']>;
export type Zeitraeume = Veranstaltung['ablauf'];
export type Zeitraum = ArrayElement<Zeitraeume>;

export type HiveTerminanfrageStatus = NonNullable<ExperteBlockung['hiveTerminanfrageStatus']>;

export const hiveTerminanfrageStatus = {
  MANUAL: 'MANUAL',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CONFLICT: 'CONFLICT',
  CANCELLED: 'CANCELLED',
} as const satisfies Record<Keyify<HiveTerminanfrageStatus>, HiveTerminanfrageStatus>;

export type ExperteHiveTerminanfrageConflict = ArrayElement<NonNullable<ExperteBlockung['hiveTerminanfrageConflicts']>>;

export type Geschaeftsbereich = GetVeranstaltungByAkaVeranstaltungIdOutput['geschaeftsbereich'];

export const geschaeftsbereiche = {
  CAS: 'CAS',
  DLS: 'DLS',
  ILS: 'ILS',
  IHC: 'IHC',
  KFF: 'KFF',
  UNKNOWN: 'UNKNOWN',
} as const satisfies Record<Keyify<Geschaeftsbereich>, Geschaeftsbereich>;

export type VivaStatus = NonNullable<GetVeranstaltungByAkaVeranstaltungIdOutput['vivaStatus']>;

export const vivaStatus = {
  INPLANUNG: 'inPlanung',
  ABGESCHLOSSEN: 'abgeschlossen',
  FREIGEGEBEN: 'freigegeben',
} as const satisfies Record<Keyify<VivaStatus>, VivaStatus>;

export type SapStatus = NonNullable<GetVeranstaltungByAkaVeranstaltungIdOutput['sapStatus']>;

export const sapStatus = {
  ABGESAGT: 'abgesagt',
  FIXIERT: 'fixiert',
  GEPLANT: 'geplant',
  GESPERRT: 'gesperrt',
} as const satisfies Record<Keyify<SapStatus>, SapStatus>;

export type Ablauf = GetVeranstaltungByAkaVeranstaltungIdOutput['ablauf'];
export type AblaufTag = ArrayElement<Ablauf>;

export type Terminart = GetVeranstaltungByAkaVeranstaltungIdOutput['terminart'];

export const terminart = {
  STANDARDTERMIN: 'standardtermin',
  ZUSATZTERMIN: 'zusatztermin',
  ERSATZTERMIN: 'ersatztermin',
} as const satisfies Record<Keyify<NonNullable<Terminart>>, Terminart>;

export type GetVeranstaltungenByThemaIdInput = RouterInput['veranstaltung']['getVeranstaltungenByThemaId'];
export type GetVeranstaltungenByThemaIdOutput = RouterOutput['veranstaltung']['getVeranstaltungenByThemaId'];

export type CreateVeranstaltungInput = RouterInput['veranstaltung']['createVeranstaltung'];
export type CreateVeranstaltungOutput = RouterOutput['veranstaltung']['createVeranstaltung'];

export type UpdateGeschaeftsbereichInput = RouterInput['veranstaltung']['updateGeschaeftsbereich'];
export type UpdateGeschaeftsbereichOutput = RouterOutput['veranstaltung']['updateGeschaeftsbereich'];

export type UpdateAblaufInput = RouterInput['veranstaltung']['updateAblauf'];
export type UpdateAblaufOutput = RouterOutput['veranstaltung']['updateAblauf'];

export type UpdateRessourcenBlockungenOfVeranstaltungInput = RouterInput['veranstaltung']['updateRessourcenBlockungenOfVeranstaltung'];
export type UpdateRessourcenBlockungenOfVeranstaltungOutput = RouterOutput['veranstaltung']['updateRessourcenBlockungenOfVeranstaltung'];

export type RessourcenBlockungen = UpdateRessourcenBlockungenOfVeranstaltungInput['blockungen'];
export type RessourcenBlockung = ArrayElement<RessourcenBlockungen>;
export type RessourcenBlockungType = RessourcenBlockung['type'];

export const ressourcenBlockungTypes = {
  EXPERTE: 'experte',
  RAUM: 'raum',
  STANDORT: 'standort',
} as const satisfies Record<Keyify<RessourcenBlockungType>, RessourcenBlockungType>;

export type GetHealthByVeranstaltungInput = RouterInput['veranstaltung']['getHealthByVeranstaltung'];
export type GetHealthByVeranstaltungOutput = RouterOutput['veranstaltung']['getHealthByVeranstaltung'];

export type CheckHealthResult = ArrayElement<GetHealthByVeranstaltungOutput>;

export type HealthLevel = CheckHealthResult['type'];

export const healthLevel = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
} as const satisfies Record<Keyify<HealthLevel>, HealthLevel>;

// planung

export type GetPlanungsinformationInput = RouterInput['planung']['getPlanungsinformation'];
export type GetPlanungsinformationOutput = RouterOutput['planung']['getPlanungsinformation'];

export type ExpertePool = GetPlanungsinformationOutput['expertePool'];
export type ExpertePlanung = ArrayElement<ExpertePool>;
export type CservWorkflowStatus = NonNullable<GetPlanungsinformationOutput['cservWorkflowStatus']>;

export const cservWorkflowStatus = {
  Produktmanagement: 160,
  VAOrga: 161,
  PlanungAbgeschlossen: 162,
  PlanungInaktiv: 190,
} as const satisfies Record<string, CservWorkflowStatus>;

export type CservOnlineTools = NonNullable<GetPlanungsinformationOutput['onlineTool']>;

export const cservOnlineTools = {
  MS_TEAMS: 'ms_teams',
  LUX: 'LUX',
  TECHCAST: 'techcast',
  TRICAT: 'tricat',
  ZOOM: 'zoom',
} as const satisfies Record<Keyify<CservOnlineTools>, CservOnlineTools>;

export type UpdateStatusOfVeranstaltungenInput = RouterInput['planung']['updateStatusOfVeranstaltungen'];
export type UpdateStatusOfVeranstaltungenOutput = RouterOutput['planung']['updateStatusOfVeranstaltungen'];

export type StatusChangeResult = ArrayElement<UpdateStatusOfVeranstaltungenOutput>;

export type VeranstaltungCustomProperties = {
  readonly terminart: Terminart;
  readonly manuelleTerminanfrage: boolean;
  readonly withoutResources?: boolean;
  readonly beginDate?: Date;
};

// resources

export type GetAvailableStandorteByRegionInput = RouterInput['planung']['resources']['getAvailableStandorteByRegion'];
export type GetAvailableStandorteByRegionOutput = RouterOutput['planung']['resources']['getAvailableStandorteByRegion'];

export type GetRaeumeInput = RouterInput['planung']['resources']['getRaeume'];
export type GetRaeumeOutput = RouterOutput['planung']['resources']['getRaeume'];

export type Raum = ArrayElement<GetRaeumeOutput>;

export type GekaufterZeitraum = Raum['gekaufterZeitraum'];
export type GekaufterZeitraumTag = ArrayElement<GekaufterZeitraum>;

export type GetBookableExpertenByThemaInput = RouterInput['planung']['resources']['getBookableExpertenByThema'];
export type GetBookableExpertenByThemaOutput = RouterOutput['planung']['resources']['getBookableExpertenByThema'];

export type ExperteFromResourcesRouter = ArrayElement<GetBookableExpertenByThemaOutput>;
export type HiveExperteStatus = ExperteFromResourcesRouter['hiveExperteStatus'];

export const hiveExperteStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  BLOCKED: 'blocked',
  OTHER: 'other',
} as const satisfies Record<Keyify<HiveExperteStatus>, HiveExperteStatus>;

export type ExperteBlockungQuelle = ArrayElement<ExperteFromResourcesRouter['experteBlockungen']>['quelle'];

export const experteBlockungQuelle = {
  SAP: 'AKA.BAN.SAP',
  VAMOS: 'AKA.BAN.VMOS',
  CESAR: 'AKA.BAN.CESAR.SALESFORCE',
} as const satisfies Record<string, ExperteBlockungQuelle>;

export type GetStandorteInput = RouterInput['planung']['resources']['getStandorte'];
export type GetStandorteOutput = RouterOutput['planung']['resources']['getStandorte'];

export type Standort = ArrayElement<GetStandorteOutput>;

export type FindExperteByIdOrLastnameInput = RouterInput['planung']['resources']['getExperteByNachnameOrSapId'];
export type FindExperteByIdOrLastnameOutput = RouterOutput['planung']['resources']['getExperteByNachnameOrSapId'];

export type SelectedExperte = ArrayElement<FindExperteByIdOrLastnameOutput>;

// fehler
export type GetFachlicheFehlerOutput = RouterOutput['fehler']['getFachlicheFehler'];
export type FachlicherFehler = ArrayElement<GetFachlicheFehlerOutput>;

// reporting

export type GetManuelleTerminanfragenInput = RouterInput['reporting']['getManuelleTerminanfragen'];
export type GetManuelleTerminanfragenOutput = RouterOutput['reporting']['getManuelleTerminanfragen'];

export type VivaStatusArray = GetManuelleTerminanfragenInput['vivaStatus'];
export type SapStatusArray = GetManuelleTerminanfragenInput['sapStatus'];

export type ManuelleTerminanfrageThema = ArrayElement<GetManuelleTerminanfragenOutput>;

export type ManuelleTerminanfrageVeranstaltung = ArrayElement<ManuelleTerminanfrageThema['veranstaltungen']>;

export type ExperteBlockungenFromReportingRouter = ManuelleTerminanfrageVeranstaltung['experteBlockungen'];
export type ExperteBlockungFromReportingRouter = ArrayElement<ExperteBlockungenFromReportingRouter>;

export type GetAutomatisierteTerminanfragenByThemaInput = RouterInput['reporting']['getAutomatisierteTerminanfragenByThema'];
export type GetAutomatisierteTerminanfragenByThemaOutput = RouterOutput['reporting']['getAutomatisierteTerminanfragenByThema'];

/*
 * Scopes
 */

export const vivaUserScopes = {
  PLANUNG_EDIT: 'planung:edit',
  THEMA_EDIT: 'thema:edit',
  SYSTEM_ADMIN: 'system:admin',
} as const;

const vivaUserScopesSchema = z.nativeEnum(vivaUserScopes);
export const vivaUserScopesArraySchema = z.array(vivaUserScopesSchema);
export type VivaUserScope = z.infer<typeof vivaUserScopesSchema>;

/*
 * Zoom
 */
export const onlineToolOrtKuerzel = {
  zoom: 'xx-zoo',
} as const;

export type OnlineToolOrtKuerzel = (typeof onlineToolOrtKuerzel)[keyof typeof onlineToolOrtKuerzel];

export { type AppRouter } from '@hg-akademie-ban/vamos-viva-main';
