import { type Terminart, terminart as terminartEnum, type VeranstaltungCustomProperties } from '../../../../dtos';
import { useDebounce } from '../../../../hooks/useDebounce';
import { printTerminart } from '../../../../utils';
import { DatePickerWrapper } from '../../DatePickerWrapper';
import { printTerminanfrageTooltip, printTerminartTooltip } from './VeranstaltungPropertiesChanger.utils';
import { Box, FormControlLabel, Grid2, Radio, RadioGroup, Switch, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

type TerminartRadioButtonProps = { readonly terminart: Terminart };
const TerminartRadioButton: React.FC<TerminartRadioButtonProps> = (props: TerminartRadioButtonProps) => (
  <Tooltip disableInteractive title={<Typography variant="caption">{printTerminartTooltip(props.terminart)}</Typography>}>
    <FormControlLabel value={props.terminart} control={<Radio />} label={printTerminart(props.terminart)} />
  </Tooltip>
);

type VeranstaltungPropertiesChangerProps = {
  readonly themaManuelleTerminanfrage: boolean;
  readonly handleAttributeChange: (propertyData: VeranstaltungCustomProperties) => void;
  readonly ablaufTageCount: number;
  readonly isOnlineThema: boolean;
};

export const VeranstaltungPropertiesChanger: React.FC<VeranstaltungPropertiesChangerProps> = (props: VeranstaltungPropertiesChangerProps) => {
  const { themaManuelleTerminanfrage, handleAttributeChange, ablaufTageCount, isOnlineThema } = props;

  const [propertyData, setPropertyData] = useState<VeranstaltungCustomProperties>({
    terminart: terminartEnum.STANDARDTERMIN,
    manuelleTerminanfrage: themaManuelleTerminanfrage,
    withoutResources: false,
    beginDate: undefined,
  });
  const debouncedPropertyData = useDebounce(propertyData);

  useEffect(() => {
    handleAttributeChange(debouncedPropertyData);
  }, [debouncedPropertyData, handleAttributeChange]);

  const handleTerminartChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newTerminart = (event.target as HTMLInputElement).value as Terminart;
      let newManuelleTerminanfrage = themaManuelleTerminanfrage;
      if (newTerminart === terminartEnum.ZUSATZTERMIN) {
        newManuelleTerminanfrage = true;
      }

      if (newTerminart === terminartEnum.ERSATZTERMIN) {
        newManuelleTerminanfrage = true;
      }

      setPropertyData((oldPropertyData) => ({
        ...oldPropertyData,
        terminart: newTerminart,
        manuelleTerminanfrage: newManuelleTerminanfrage,
      }));
    },
    [themaManuelleTerminanfrage],
  );

  const handleResourceOptionChange = useCallback((_: unknown, value: string): void => {
    const booleanValue = value === 'true';

    setPropertyData((oldPropertyData) => ({
      ...oldPropertyData,
      withoutResources: booleanValue,
    }));
  }, []);

  const handleBeginDateChange = useCallback((value: Date | null): void => {
    if (value === null) {
      setPropertyData((oldPropertyData) => ({
        ...oldPropertyData,
        beginDate: undefined,
      }));
    } else {
      setPropertyData((oldPropertyData) => ({
        ...oldPropertyData,
        beginDate: value,
      }));
    }
  }, []);

  return (
    <Grid2 container size={{ xs: 12 }} gap={2}>
      <Grid2 size={{ xs: 6 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Angaben zur Veranstaltung</Typography>
      </Grid2>

      <Grid2 size={{ xs: 12 }} gap={10} display="flex">
        <Box>
          Terminart
          <Box>
            <RadioGroup value={propertyData.terminart} name="terminart-radio-buttons" onChange={handleTerminartChange}>
              <TerminartRadioButton terminart={terminartEnum.STANDARDTERMIN} />
              <TerminartRadioButton terminart={terminartEnum.ZUSATZTERMIN} />
              <TerminartRadioButton terminart={terminartEnum.ERSATZTERMIN} />
            </RadioGroup>
          </Box>
        </Box>
        <Box>
          Terminanfrage
          <Box>
            <Tooltip disableInteractive title={<Typography variant="caption">{printTerminanfrageTooltip(propertyData.terminart)}</Typography>}>
              <FormControlLabel
                control={<Switch sx={{ m: 1 }} disabled checked={propertyData.manuelleTerminanfrage} />}
                label="Manuelle Terminanfrage"
                sx={{ '& > span': { color: 'unset !important' } }}
              />
            </Tooltip>
          </Box>
        </Box>
        {isOnlineThema && (
          <>
            <Box>
              Ressourcen
              <Box>
                <RadioGroup value={propertyData.withoutResources} name="resources-radio-buttons" onChange={handleResourceOptionChange}>
                  <FormControlLabel value="false" control={<Radio />} label="Ressourcen auswählen" />
                  <FormControlLabel value="true" control={<Radio />} label="Ohne Ressourcen, nur mit Ablauf anlegen" />
                </RadioGroup>
              </Box>
            </Box>
            {propertyData.withoutResources && (
              <Box>
                Veranstaltungsbeginn
                <Box mt=".5rem" maxWidth="11rem">
                  <DatePickerWrapper
                    excludeWeekendConfig={{
                      ablaufTageCount,
                    }}
                    label="Datum"
                    onChange={handleBeginDateChange}
                    minDate={new Date()}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Grid2>
    </Grid2>
  );
};
