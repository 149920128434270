import { type GetBookableExpertenByThemaOutput, type GetRaeumeOutput, type GetStandorteOutput, type PlanungsinformationFromThemaRouter } from '../../../dtos';
import { CalendarExperteResource, CalendarGruppenraumResource, type CalendarResource, CalendarStandardRaumResource, CalendarStandortResource } from './helperClasses';

export const createCalendarResources = (
  expertepool: GetBookableExpertenByThemaOutput,
  raeume: GetRaeumeOutput,
  standorte: GetStandorteOutput,
  startDate: Date,
  planungsinformation?: PlanungsinformationFromThemaRouter,
): CalendarResource[] => {
  const resources: CalendarResource[] = [];
  for (const raum of raeume) {
    if (raum.isGruppenraum) {
      resources.push(new CalendarGruppenraumResource(raum));
    } else {
      resources.push(new CalendarStandardRaumResource(raum));
    }
  }

  for (const experte of expertepool) {
    if (typeof planungsinformation === 'undefined') {
      throw new TypeError('[ResourceCalendar.utils] Experte resources require planungsinformation');
    }

    resources.push(new CalendarExperteResource(experte, startDate.getFullYear(), planungsinformation.expertePool));
  }

  for (const standort of standorte) {
    resources.push(new CalendarStandortResource(standort));
  }

  return resources;
};
