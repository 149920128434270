import { BackendConfigContext, content } from '../../utils';
import { useContext } from 'react';
import { Link } from 'react-router';

type GesellschaftCellProps = { readonly gesellschaftId?: string | null };

export const GesellschaftCell: React.FC<GesellschaftCellProps> = ({ gesellschaftId }: GesellschaftCellProps) => {
  const backendConfig = useContext(BackendConfigContext);

  if (typeof gesellschaftId !== 'string' || gesellschaftId.length === 0) {
    return content.nein;
  }

  return (
    <Link target="_blank" rel="noopener" to={`${backendConfig.HiveAppUrl}/organizations/${gesellschaftId}`}>
      {content.ja}
    </Link>
  );
};
