import { AccordionWithHeading, ContentLoading } from '../../../components/general';
import { trpc } from '../../../trpc';
import { BackendConfigContext } from '../../../utils';
import { buildFilterItems, type CustomGridFilterModel, TaskFilter } from './TaskFilter';
import { taskCardContent } from './ThemaTaskCard.content';
import { taskColumns } from './ThemaTaskCard.utils';
import { ThemaTaskCardHeading } from './ThemaTaskCardHeading';
import { Draggable } from '@mobiscroll/react';
import { DataGridPro, gridClasses, GridLogicOperator } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales';
import { useContext, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

type ThemaTasksCardProps = {
  readonly akaProduktId: string;
};

export const ThemaTasksCard: React.FC<ThemaTasksCardProps> = (props: ThemaTasksCardProps) => {
  const { TaskManagementBoardUrl } = useContext(BackendConfigContext);
  const taskQuery = trpc.thema.getTasksByAkaProduktId.useQuery({ akaProduktId: props.akaProduktId });
  const [filterModel, setFilterModel] = useState<CustomGridFilterModel>({
    items: buildFilterItems([]),
    logicOperator: GridLogicOperator.Or,
  });

  if (taskQuery.isError) {
    throw new Error(taskQuery.error.message);
  }

  if (taskQuery.isLoading || taskQuery.data === undefined) {
    return (
      <Draggable id={taskCardContent.draggableId}>
        <AccordionWithHeading key={uuid4()} heading={<ThemaTaskCardHeading uri={TaskManagementBoardUrl} />}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  return (
    <Draggable id={taskCardContent.draggableId}>
      <AccordionWithHeading heading={<ThemaTaskCardHeading uri={TaskManagementBoardUrl} />} accordionProps={{ defaultExpanded: taskQuery.data.length > 0 }}>
        <TaskFilter filterModel={filterModel} setFilterModel={setFilterModel} />
        <DataGridPro
          columns={taskColumns}
          rows={taskQuery.data}
          pagination
          pageSizeOptions={[5, 10]}
          filterModel={filterModel}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: { sortModel: [{ sort: 'asc', field: 'taskStatus' }] },
          }}
          disableColumnMenu
          disableColumnFilter
          disableRowSelectionOnClick
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        />
      </AccordionWithHeading>
    </Draggable>
  );
};
