import { AccordionWithHeading } from '../../components/general';
import { type CheckHealthResult, healthLevel } from '../../dtos';
import { trpc } from '../../trpc';
import { Cancel, Info, Warning } from '@mui/icons-material';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { v4 } from 'uuid';

type TerminWarningsCardProps = {
  readonly veranstaltungId: number;
};

type IntegrityResultProps = {
  readonly result: CheckHealthResult;
};

const IntegrityResult: React.FC<IntegrityResultProps> = ({ result }: IntegrityResultProps) => (
  <Paper sx={{ padding: 1 }} elevation={1}>
    <Stack direction="row">
      <Box paddingX={2}>
        {result.type === healthLevel.INFO && <Info color="info" />}
        {result.type === healthLevel.WARNING && <Warning color="warning" />}
        {result.type === healthLevel.ERROR && <Cancel color="error" />}
      </Box>
      <Typography>{result.message}</Typography>
    </Stack>
  </Paper>
);

export const TerminWarningsCard: React.FC<TerminWarningsCardProps> = (props: TerminWarningsCardProps) => {
  const { veranstaltungId } = props;

  const healthCheckQuery = trpc.veranstaltung.getHealthByVeranstaltung.useQuery({ veranstaltungId });

  return healthCheckQuery.data?.length ? (
    <AccordionWithHeading heading="Warnungen | Fehler" accordionProps={{ defaultExpanded: true }}>
      <Stack spacing={1}>
        {healthCheckQuery.data.map((result) => (
          <IntegrityResult key={v4()} result={result} />
        ))}
      </Stack>
    </AccordionWithHeading>
  ) : null;
};
