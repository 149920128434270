import { AccordionWithHeading, Tablelike } from '../../../components/general';
import { type ThemaFromThemaRouter } from '../../../dtos';
import { printCservFormat } from '../../../utils';
import { content } from './TerminEigenschaftenCard.content';
import { printAkaProduktId, printletzteAenderung, printSapProduktId, printSprache } from './TerminEigenschaftenCard.utils';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';

type TerminEigenschaftenCardProps = {
  readonly thema: ThemaFromThemaRouter;
};

export const TerminEigenschaftenCard: React.FC<TerminEigenschaftenCardProps> = (props: TerminEigenschaftenCardProps) => {
  const { thema } = props;

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack sx={{ paddingTop: 2 }}>
          <Tablelike
            xsLeft={6}
            rows={[
              [content.fields.akaProduktId, printAkaProduktId(thema)],
              [content.fields.sapProduktId, printSapProduktId(thema)],
              [content.fields.sprache, printSprache(thema)],
              [content.fields.format, printCservFormat(thema)],
              [content.fields.letzteAenderung, printletzteAenderung(thema)],
            ]}
          />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
