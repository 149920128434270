import { type DataGridColDef } from '../../../utils';
import { DataGridPro, gridClasses, type GridRowIdGetter, type GridRowModel } from '@mui/x-data-grid-pro';

type VeranstaltungenTableProps<T extends GridRowModel> = {
  readonly veranstaltungen: T[];
  readonly columns: DataGridColDef<T>;
  readonly disableColumnMenu?: boolean;
  /**
   * This component is displaying a table of Veranstaltungen. Each row must have a unique identifier.
   *
   * @see https://mui.com/x/react-data-grid/row-definition/?srsltid=AfmBOoq7ohBDGz4Eskn9zWiGFHCDQT1E3IR-KopXXfYDeIwf5cBfE09U#row-identifier
   */
  readonly getRowId: GridRowIdGetter<T>;
};

export const VeranstaltungenTable = <T extends GridRowModel>({ veranstaltungen, columns, disableColumnMenu, getRowId }: VeranstaltungenTableProps<T>): JSX.Element => (
  <DataGridPro
    pagination
    pageSizeOptions={[5, 10, 50, 100, { value: -1, label: 'Alle' }]}
    getRowId={getRowId}
    initialState={{
      pagination: { paginationModel: { pageSize: 5, page: 0 } },
      sorting: {
        sortModel: [{ field: 'start', sort: 'desc' }],
      },
    }}
    sx={{
      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
        outline: 'none',
      },
      [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
        outline: 'none',
      },
    }}
    columns={columns}
    rows={veranstaltungen.map((item, idx) => ({ id: idx, ...item }))}
    disableRowSelectionOnClick
    disableColumnMenu={disableColumnMenu}
  />
);
