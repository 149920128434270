import { type CalendarGruppenraumResource, type CalendarResource, calendarResourceTypes, type CalendarStandardRaumResource } from '../../components/general';
import { type MbscResource } from '@mobiscroll/react';

type StandardRaumResourceGroup = Omit<MbscResource, 'id' | 'name' | 'children'> & {
  id: typeof calendarResourceTypes.STANDARDRAUM;
  name: typeof calenderResourceGroupLabels.STANDARDRAUM;
  children: CalendarStandardRaumResource[];
};

type GruppenraumResourceGroup = Omit<MbscResource, 'id' | 'name' | 'children'> & {
  id: typeof calendarResourceTypes.GRUPPENRAUM;
  name: typeof calenderResourceGroupLabels.GRUPPENRAUM;
  children: CalendarGruppenraumResource[];
};

type ResourceGroup = StandardRaumResourceGroup | GruppenraumResourceGroup;

export type RaumCollapseInfo = {
  standardraum: boolean;
  gruppenraum: boolean;
};

const calenderResourceGroupLabels = {
  STANDARDRAUM: 'Standardräume',
  GRUPPENRAUM: 'Gruppenräume',
} as const;

const getGroupDefaultValues = (
  collapseInfo: RaumCollapseInfo,
): {
  raeumeGroup: StandardRaumResourceGroup;
  gruppenraeumeGroup: GruppenraumResourceGroup;
} => {
  const standardRaeumeGroup: StandardRaumResourceGroup = {
    id: calendarResourceTypes.STANDARDRAUM,
    name: calenderResourceGroupLabels.STANDARDRAUM,
    children: [],
    collapsed: collapseInfo.standardraum,
  };

  const gruppenraeumeGroup: GruppenraumResourceGroup = {
    id: calendarResourceTypes.GRUPPENRAUM,
    name: calenderResourceGroupLabels.GRUPPENRAUM,
    children: [],
    collapsed: collapseInfo.gruppenraum,
  };

  return {
    raeumeGroup: standardRaeumeGroup,
    gruppenraeumeGroup,
  };
};

export const groupRaumResources = (resources: CalendarResource[], collapseInfo: RaumCollapseInfo): MbscResource[] & ResourceGroup[] => {
  const { raeumeGroup, gruppenraeumeGroup } = getGroupDefaultValues({ ...collapseInfo });

  raeumeGroup.collapsed = collapseInfo.standardraum;
  gruppenraeumeGroup.collapsed = collapseInfo.gruppenraum;

  for (const resource of resources) {
    switch (resource.type) {
      case calendarResourceTypes.STANDARDRAUM:
        raeumeGroup.children.push(resource);
        break;

      case calendarResourceTypes.GRUPPENRAUM:
        gruppenraeumeGroup.children.push(resource);
        break;

      default:
        break;
    }
  }

  return [raeumeGroup, gruppenraeumeGroup];
};
