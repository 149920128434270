import { type Keyify } from '../../../dtos';
import { type CalendarResourceType } from '../ResourceCalendar';

const calenderResourceGroupLabels = {
  EXPERTE: 'Trainer',
  STANDARDRAUM: 'Standardräume',
  GRUPPENRAUM: 'Gruppenräume',
  STANDORT: 'Standorte',
} as const satisfies Record<Keyify<CalendarResourceType>, string>;

export const content = {
  calenderResourceGroupLabels,

  warnungen: {
    onlineVeranstaltung:
      'Die Veranstaltung findet in einem Online-Format statt und aus diesem Grund können keine Räume oder Standorte gebucht werden. Es wird automatisch das Tool "Zoom" angegeben.',
    planungInaktiv: 'Die Planungsinformationen für dieses Thema sind inaktiv (Workflow-Status 190).',
  },
} as const;
