import { type Clusterzuordnung, clusterzuordnung, type ExpertePlanung, type Keyify } from '../../../dtos';
import { getExperteName } from '../../../utils';
import { type Content, content } from './TerminExpertepoolCard.content';

export const printName = (expertePlanung: ExpertePlanung): string => {
  if (expertePlanung.experte === null) {
    return content.noInfoAvailable;
  }

  return getExperteName(expertePlanung.experte);
};

type ClusterzuordnungAbbreviation = 'A' | 'B' | 'C' | '-';

const clusterzuordnungAbbreviation = {
  HAUPTTRAINER: 'A', // cluster A
  NEBENTRAINER: 'B', // cluster B
  ERSATZTRAINER: 'C', // cluster C
} as const satisfies Record<Keyify<Clusterzuordnung>, ClusterzuordnungAbbreviation>;

const getClusterzuordnungAbbreviation = (givenClusterzuordnung: Clusterzuordnung | null): ClusterzuordnungAbbreviation => {
  let clusterAbbreviation: ClusterzuordnungAbbreviation;

  switch (givenClusterzuordnung) {
    case clusterzuordnung.HAUPTTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.HAUPTTRAINER;
      break;
    case clusterzuordnung.NEBENTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.NEBENTRAINER;
      break;
    case clusterzuordnung.ERSATZTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.ERSATZTRAINER;
      break;
    case null:
      clusterAbbreviation = content.noInfoAvailable;
      break;
    default: {
      const exhaustiveCheck: never = givenClusterzuordnung;
      throw new Error(`Could not find abbreviation for clusterzuordnung ${exhaustiveCheck}`);
    }
  }

  return clusterAbbreviation;
};

export const printCluster = (passedClusterzuordnung: Clusterzuordnung | null): ClusterzuordnungAbbreviation | Content['noInfoAvailable'] => {
  if (!passedClusterzuordnung) {
    return content.noInfoAvailable;
  }

  return getClusterzuordnungAbbreviation(passedClusterzuordnung);
};

export const printIndividuelleProzentverteilung = (expertePlanung: ExpertePlanung): string => {
  if (typeof expertePlanung.terminverteilung !== 'number') {
    return content.noInfoAvailable;
  }

  if (expertePlanung.terminverteilung < 0) {
    return content.noInfoAvailable;
  }

  return expertePlanung.terminverteilung + content.units.percent;
};

const today = new Date();
export const thisYear = today.getFullYear();
export const nextYear = today.getFullYear() + 1;

export const printAuslastungAktuellesJahrLabel = (jahr: number): string => content.columnLabels.auslastung + ' ' + jahr;

export const printAuslastungForJahr = (jahr: number, expertePlanung: ExpertePlanung): string => {
  const experteAuslastungen = expertePlanung.experte?.experteAuslastungen;

  const experteAuslastung = experteAuslastungen?.find((item) => item.jahr === jahr);

  if (typeof experteAuslastung === 'undefined') {
    return content.noInfoAvailable;
  }

  return Math.round(experteAuslastung.summeTage) + '/' + Math.round(experteAuslastung.schwelleRot);
};

export const clusterField = 'cluster';
