import {
  type CalendarExperteResource,
  type CalendarGruppenraumResource,
  type CalendarResource,
  calendarResourceTypes,
  type CalendarStandardRaumResource,
  type CalendarStandortResource,
  isExperteResource,
  isGruppenraumResource,
  isStandardRaumResource,
  isStandortResource,
} from '../helperClasses';
import { ResourceTooltip } from './ResourceTooltip';
import { type MbscResource } from '@mobiscroll/react';
import { Box, Tooltip, Typography } from '@mui/material';

type ExperteResourceProps = {
  readonly resource: CalendarExperteResource;
  readonly hiveUrl: string;
};

const ExperteResource: React.FC<ExperteResourceProps> = (props: ExperteResourceProps) => (
  <a style={{ textDecoration: 'none', color: 'unset' }} href={`${props.hiveUrl}/experten/${props.resource.getRessourceSapId()}/auslastung`} target="_blank" rel="noreferrer">
    <Box>
      <Typography variant="body2">{props.resource.name}</Typography>
    </Box>
  </a>
);

type RaumOrStandortResourceProps = {
  readonly resource: CalendarStandardRaumResource | CalendarGruppenraumResource | CalendarStandortResource;
};

const RaumOrStandortResource: React.FC<RaumOrStandortResourceProps> = (props: RaumOrStandortResourceProps) => (
  <Box>
    <Typography variant="body2">{props.resource.name}</Typography>
  </Box>
);

type ResourceGroupProps = {
  readonly resource: MbscResource;
};

const ResourceGroup: React.FC<ResourceGroupProps> = (props: ResourceGroupProps) => (
  <Box>
    <Typography variant="body2">{props.resource.name}</Typography>
    <Typography variant="body2">{props.resource.subline}</Typography>
  </Box>
);

type CalendarResourceProps = {
  readonly resource: CalendarResource;
  readonly year: number;
  readonly hiveUrl: string;
};

export const DisplayCalendarResource: React.FC<CalendarResourceProps> = (props: CalendarResourceProps) => {
  const { resource, year, hiveUrl } = props;

  const isResourceGroup = !Object.values(calendarResourceTypes).includes(resource.type);

  return (
    <Tooltip title={isResourceGroup ? undefined : <ResourceTooltip resource={resource} year={year} />} placement="right" arrow>
      <Box sx={{ display: 'inline-block' }}>
        <>
          {isResourceGroup ? (
            <ResourceGroup resource={resource} />
          ) : (
            <>
              {isExperteResource(resource) && <ExperteResource hiveUrl={hiveUrl} resource={resource} />}
              {(isStandardRaumResource(resource) || isGruppenraumResource(resource) || isStandortResource(resource)) && <RaumOrStandortResource resource={resource} />}
            </>
          )}
        </>
      </Box>
    </Tooltip>
  );
};
