import { type TaskStatus, taskStatus } from '../../../dtos';
import { dataGridOperators } from '../../../utils/dataGridOperators.js';
import { type TableFields } from './ThemaTaskCard.utils';
import { Autocomplete, TextField } from '@mui/material';
import { type GridFilterModel } from '@mui/x-data-grid-pro';
import { v4 as uuid4 } from 'uuid';

type CustomGridFilterItem = { id: string; field: TableFields; operator: typeof dataGridOperators.ANY_OF; value: TaskStatus[] };
export type CustomGridFilterModel = GridFilterModel & { items: [CustomGridFilterItem] };

type TaskFilterProps = {
  readonly filterModel: CustomGridFilterModel;
  readonly setFilterModel: (setModel: (status: CustomGridFilterModel) => CustomGridFilterModel) => void;
};

export const buildFilterItems = (status: TaskStatus[]): [CustomGridFilterItem] => [
  {
    id: uuid4(),
    field: 'taskStatus',
    operator: dataGridOperators.ANY_OF,
    value: status,
  },
];

export const TaskFilter: React.FC<TaskFilterProps> = (props: TaskFilterProps) => (
  <Autocomplete
    multiple
    id="task-status-filter"
    value={props.filterModel.items[0].value}
    onChange={(_event, newValues) => {
      props.setFilterModel((model) => ({ ...model, items: buildFilterItems(newValues) }));
    }}
    size="small"
    options={Object.values(taskStatus)}
    getOptionLabel={(option) => option}
    sx={{ maxWidth: '30rem', paddingY: '0.5rem' }}
    renderInput={(params) => <TextField {...params} label="Taskstatus" />}
  />
);
