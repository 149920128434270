import { akaVeranstaltungIdColumn, endColumn, expertsColumn, ortKuerzelColumn, ortNameColumn, standortNameColumn, startColumn, ThemaCard } from '../../../components/general';
import { type ManuelleTerminanfrageThema, type ManuelleTerminanfrageVeranstaltung } from '../../../dtos';
import { copyToClipboard, type DataGridColDef } from '../../../utils';
import { content } from './ThemaRow.content';
import { generateClipboardTextForThema } from './ThemaRow.utils';
import { CopyAllSharp } from '@mui/icons-material';
import { Button } from '@mui/material';

type ThemaRowProps = {
  readonly thema: ManuelleTerminanfrageThema;
};

const appointmentsCheckColDefs: DataGridColDef<ManuelleTerminanfrageVeranstaltung> = [
  startColumn(),
  endColumn(),
  akaVeranstaltungIdColumn(),
  ortNameColumn(),
  ortKuerzelColumn(),
  standortNameColumn(),
  expertsColumn(),
];

export const ThemaRow: React.FC<ThemaRowProps> = ({ thema }: ThemaRowProps) => {
  const copySingleElementToClipboard = async (): Promise<void> => {
    await copyToClipboard(generateClipboardTextForThema(thema), 'text/html');
  };

  return (
    <ThemaCard thema={thema} columns={appointmentsCheckColDefs} getRowId={(veranstaltung) => veranstaltung.akaVeranstaltungId}>
      <Button onClick={copySingleElementToClipboard}>
        <CopyAllSharp />
        {content.copy}
      </Button>
    </ThemaCard>
  );
};
