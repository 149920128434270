import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { closeSnackbar, type SnackbarAction } from 'notistack';

export const dismissSnackbarAction: SnackbarAction = (snackbarId) => (
  <IconButton
    aria-label="close"
    onClick={() => {
      closeSnackbar(snackbarId);
    }}
  >
    <CloseIcon fontSize="small" htmlColor="white" />
  </IconButton>
);
