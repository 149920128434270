import { DatePickerWrapper } from '../../../components/general';
import { localStorageApi, localStorageKeys } from '../../../utils';
import { content } from './DateFilter.content';
import { useCallback, useState } from 'react';

type DateFilterProps = {
  readonly handleStartDateChange: (startDate: Date | null) => void;
  readonly handleEndDateChange: (endDate: Date | null) => void;
};

export const DateFilter: React.FC<DateFilterProps> = (props: DateFilterProps) => {
  const { handleStartDateChange, handleEndDateChange } = props;
  const [startDate, setStartDate] = useState<Date | null>(localStorageApi.get(localStorageKeys.TERMINCHECK_START_DATE) ?? new Date());

  const onStartDateChange = useCallback(
    (date: Date | null): void => {
      if (date === null) {
        setStartDate(null);
        handleStartDateChange(null);
        localStorageApi.remove(localStorageKeys.TERMINCHECK_START_DATE);
        return;
      }

      setStartDate(date);
      handleStartDateChange(date);
      localStorageApi.set(localStorageKeys.TERMINCHECK_START_DATE, date);
    },
    [handleStartDateChange],
  );

  const onEndDateChange = useCallback(
    (date: Date | null): void => {
      if (date === null) {
        handleEndDateChange(null);
        localStorageApi.remove(localStorageKeys.TERMINCHECK_END_DATE);
        return;
      }

      const endOfDayDate = new Date(date);
      endOfDayDate.setHours(23, 59, 59, 999);

      handleEndDateChange(endOfDayDate);
      localStorageApi.set(localStorageKeys.TERMINCHECK_END_DATE, endOfDayDate);
    },
    [handleEndDateChange],
  );

  return (
    <>
      <DatePickerWrapper initialDate={localStorageApi.get(localStorageKeys.TERMINCHECK_START_DATE)} label={content.startDate} onChange={onStartDateChange} sx={{ width: '100%' }} />
      <DatePickerWrapper
        initialDate={localStorageApi.get(localStorageKeys.TERMINCHECK_END_DATE)}
        label={content.endDate}
        minDate={startDate ?? undefined}
        onChange={onEndDateChange}
        sx={{ width: '100%' }}
      />
    </>
  );
};
