import { initStorageAPI } from './initStorageApi';
import { type GridSortItem } from '@mui/x-data-grid-pro';
import { z, type ZodSchema } from 'zod';

const localStorageKeys = {
  TERMINCHECK_START_DATE: 'appointmentCheckStartDate',
  TERMINCHECK_END_DATE: 'appointmentCheckEndDate',
  THEMA_VA_GRID_FILTERMODEL: '/thema:filter',
  THEMA_VA_GRID_SORTMODEL: '/thema:sort',
} as const;
type LocalStorageKey = (typeof localStorageKeys)[keyof typeof localStorageKeys];

const termincheckStartDateItemSchema = z.object({
  key: z.literal(localStorageKeys.TERMINCHECK_START_DATE),
  value: z.coerce.date(),
});
const termincheckEndDateItemSchema = z.object({
  key: z.literal(localStorageKeys.TERMINCHECK_END_DATE),
  value: z.coerce.date(),
});

const gridFilterModelExtendedItemSchema = z.object({
  key: z.literal(localStorageKeys.THEMA_VA_GRID_FILTERMODEL),
  value: z.object({
    items: z.array(
      z.object({
        field: z.string(),
        operator: z.string(),
        value: z.unknown().optional(),
        id: z.union([z.string(), z.number()]).optional(),
      }),
    ),
    logicOperator: z.any().optional(),
    quickFilterValues: z.array(z.unknown()).optional(),
    quickFilterLogicOperator: z.any().optional(),
    quickFilterExcludeHiddenColumns: z.boolean().optional(),
  }),
});

const gridSortModelItemSchema = z.object({
  key: z.literal(localStorageKeys.THEMA_VA_GRID_SORTMODEL),
  value: z
    .array(
      z.object({
        field: z.string(),
        sort: z
          .enum(['asc', 'desc'])
          .nullish()
          .transform((val) => val ?? null),
      } satisfies { [K in keyof GridSortItem]: ZodSchema<GridSortItem[K]> }),
    )
    .readonly(),
});

const localStorageApi = initStorageAPI(localStorage, {
  [localStorageKeys.TERMINCHECK_START_DATE]: termincheckStartDateItemSchema,
  [localStorageKeys.TERMINCHECK_END_DATE]: termincheckEndDateItemSchema,
  [localStorageKeys.THEMA_VA_GRID_FILTERMODEL]: gridFilterModelExtendedItemSchema,
  [localStorageKeys.THEMA_VA_GRID_SORTMODEL]: gridSortModelItemSchema,
} satisfies Record<LocalStorageKey, ZodSchema>);

export { localStorageApi, localStorageKeys };
